import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'src/redux';
import {
  GetInventoryAuditLogDetailData,
  getInventoryAuditLogsDetailsThunk,
  GetInventoryAuditLogsListingsData,
  GetInventoryAuditLogsListingsPayload,
  getInventoryAuditLogsListingsThunk,
  updateInventoryAuditLogsRemarksThunk,
  UpdateInventoryAuditsRemarksPayload,
  UpdateInventoryRemarksHistory
} from 'src/redux/slices/inventory-audit-logs';
import { useSnackBar } from '../use-snackbar';

export const useInventoryAuditLogs = () => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inventoryAuditListing, setInventoryAuditListing] = useState<
    GetInventoryAuditLogsListingsData[]
  >();

  const [inventoryAuditDetails, setInventoryAuditDetails] = useState<
    GetInventoryAuditLogDetailData
  >();

  const [remarksHistory, setRemarksHistory] = useState<
    UpdateInventoryRemarksHistory
  >();

  //===endpoints===
  const getInventoryAuditListing = useCallback(
    async (params: GetInventoryAuditLogsListingsPayload) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(getInventoryAuditLogsListingsThunk(params))
        );

        if (response?.success) {
          setIsLoading(false);
          setInventoryAuditListing(response?.originalData?.data);

          if (
            response?.originalData?.data &&
            response?.originalData?.data?.length <= 0
          ) {
            snackBar.show({ severity: 'warning', message: 'No result found' });
          }
          return;
        }

        if (response?.errors) {
          const errorMessages = Object.values(response?.errors)
            .flat()
            .join(' | ');
          snackBar.show({
            severity: 'error',
            message:
              errorMessages ||
              'The branch, category, and data filter are required'
          });
          setInventoryAuditListing([]); // empty current list on error
          return;
        }
      } catch (error) {
        console.error(error);
        setInventoryAuditListing([]); // empty current list on error
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, snackBar]
  );

  const getInventoryAuditDetails = useCallback(
    async (ial_no?: string) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(getInventoryAuditLogsDetailsThunk(ial_no))
        );

        if (response?.success) {
          setIsLoading(false);
          setInventoryAuditDetails(response?.originalData?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const updateInventoryAuditRemarks = useCallback(
    async (params: UpdateInventoryAuditsRemarksPayload) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(updateInventoryAuditLogsRemarksThunk(params))
        );

        if (response?.success) {
          getInventoryAuditDetails(params?.ial_no);

          snackBar.show({
            severity: 'success',
            message: response?.message || 'Successfully added remarks',
            useSound: true
          });
          setIsLoading(false);
          setRemarksHistory(response?.originalData?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, getInventoryAuditDetails, snackBar]
  );

  return {
    inventoryAuditListing,
    inventoryAuditDetails,
    remarksHistory,
    isLoading,

    getInventoryAuditListing,
    getInventoryAuditDetails,
    updateInventoryAuditRemarks
  };
};
