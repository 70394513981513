import { useCallback, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { Branch } from 'src/types/branch';
import useUserInfo from '../user/use-user-info';

const { actions: branchActions, selectors: branchSelectors } = slices.branch;

export const useBranchInfo = () => {
  const dispatch = useAppDispatch();
  const branches = useAppSelector(branchSelectors.selectBranchesList);
  const { userBranchOptions } = useUserInfo();

  const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null);
  const [selectedBranches, setSelectedBranches] = useState<Branch[]>([]);
  const [selectedBranchIDs, setSelectedBranchIDs] = useState<number[]>([]);
  const [branchInputValue, setBranchInputValue] = useState<string>('');

  const branchIDToName = useCallback(
    (val: number) => {
      const branchName =
        userBranchOptions.find((i) => i.value === val)?.name || '';
      return branchName;
    },
    [userBranchOptions]
  );

  const branchNameToID = useCallback(
    (val: string) => {
      return userBranchOptions.find((i) => i.name === val)?.value || -1;
    },
    [userBranchOptions]
  );

  const getBranches = useCallback(
    async (keyword: string = '') => {
      await dispatch(branchActions.getBranchesThunk({ keyword }));
    },
    [dispatch]
  );

  // eslint-disable-next-line no-undef
  const getBranchDetails = async (branch: Partial<Branch>) => {
    return unwrapResult(
      await dispatch(branchActions.getBranchDetailsThunk(branch))
    );
  };

  return {
    branches,
    branchIDToName,
    branchNameToID,
    selectedBranch,
    setSelectedBranch,
    selectedBranchIDs,
    setSelectedBranchIDs,
    branchInputValue,
    setBranchInputValue,
    getBranches,
    selectedBranches,
    setSelectedBranches,
    getBranchDetails
  };
};
