import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  TextField
} from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { LoadingButton, SelectImageButton } from 'src/components';
import { Game } from 'src/redux/slices/games/types';
import { convertImageFileToBase64 } from 'src/utils';

interface Props {
  open: boolean;
  loading: boolean;
  details: Game | null;
  handleClose: () => void;
  onUpdatePress: (name: string, logoUrl: string) => void;
}

export const GameDetails: FC<Props> = ({
  open,
  details,
  loading,
  handleClose,
  onUpdatePress
}) => {
  const [name, setName] = useState<string>('');
  const [errName, setErrName] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState<string>('');

  const hasChanges = useMemo(() => {
    const id = details?.id;
    if (id) {
      const ogVal = JSON.stringify(Object.values(details));
      const pVal = JSON.stringify([id, name, logoUrl]);
      return ogVal !== pVal;
    }
  }, [details, logoUrl, name]);

  const onImageSelected = async (img: File[]) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);
      setLogoUrl(imgInBase64);
    }
  };

  const resetState = useCallback(() => {
    if (details) {
      setName(details.name || '');
      setLogoUrl(details.icon || '');
    }
    setErrName('');
  }, [details]);

  const handleUpdateDetails = () => {
    onUpdatePress(name, logoUrl);
  };

  const onCancelBtnPress = () => {
    resetState();
    handleClose();
  };

  useEffect(() => {
    if (details) {
      setName(details.name || '');
      setLogoUrl(details.icon || '');
    }
  }, [details]);

  return (
    <Dialog open={open}>
      <DialogContent>
        <TextField
          error={errName === '' ? false : true}
          helperText={errName}
          autoFocus
          margin="dense"
          id="name"
          label={'Game Title'}
          fullWidth
          required
          variant="outlined"
          value={name}
          onChange={(e) => {
            setErrName('');
            setName(e.target.value);
          }}
        />
        <Paper
          elevation={1}
          style={{
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'row',
            padding: '1rem'
          }}
        >
          {logoUrl && (
            <img
              alt=""
              style={{
                width: undefined,
                height: 70,
                marginRight: '1rem'
              }}
              src={logoUrl}
            />
          )}
          <SelectImageButton
            containerStyle={{ width: '100%' }}
            title="Select/Update Game Logo"
            helperText="Size Up to 3MB and only jpeg, jpg and png is allowed"
            multiple={false}
            onImageSelected={onImageSelected}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <>
          <LoadingButton
            disabled={!hasChanges}
            title="Update"
            loading={loading}
            onClick={handleUpdateDetails}
            color="primary"
          />
          <Button onClick={onCancelBtnPress}>Cancel</Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
