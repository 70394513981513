// TODO: If this is hooks. Should only contain hooks not all function.

import { unwrapResult } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { NewTemplateDetails, TemplateDetails, TemplateList } from 'src/types';

const {
  actions: quotationActions,
  selectors: quotationSelectors
} = slices.quotations;

export default function TemplatesModule() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTemplateForm, setShowTemplateForm] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateDetails>();
  const [initialTemplate] = useState<TemplateDetails>();
  const [newTemplate, setNewTemplate] = useState<NewTemplateDetails>();
  const [isPCBuild, setIsPCBuild] = useState<number>(0);
  const [isBoost, setIsBoost] = useState<number>(0);

  const templateList = useAppSelector(quotationSelectors.selectTemplateList);

  const dispatch = useAppDispatch();

  const onGetTemplateList = debounce(
    async (keyword: string, branch_id?: number | undefined) => {
      setIsLoading(true);
      try {
        unwrapResult(
          await dispatch(
            quotationActions.getTemplateListThunk({
              keyword,
              branch_id
            })
          )
        );
      } catch (error) {
        console.log('Failed to fetch list of templates');
      }
      setIsLoading(false);
    },
    500
  );

  const onGetTemplateDetails = async (template_id: number) => {
    try {
      if (!template_id) return;
      const response = unwrapResult(
        await dispatch(
          quotationActions.getTemplateDetailsThunk({ template_id })
        )
      );
      if (response?.success) {
        setSelectedTemplate(response?.originalData?.data);
      }
    } catch (error) {
      console.log('Failed to get template details', error);
    }
  };

  const createTemplate = async (
    template: NewTemplateDetails,
    branchId?: number | undefined
  ) => {
    setIsLoading(true);
    let response;

    try {
      const createParams = { ...template, branch_id: branchId };
      response = unwrapResult(
        await dispatch(quotationActions.createTemplateThunk(createParams))
      );

      if (response?.success) {
        onGetTemplateDetails(response?.originalData?.data?.quotation_id ?? 0);
        onGetTemplateList('', branchId);
      }
    } catch (error) {
      console.log('Failed to create template');
    }
    setIsLoading(false);

    return response;
  };

  const updateTemplate = async (
    template: NewTemplateDetails,
    branchId?: number | undefined
  ) => {
    setIsLoading(true);

    try {
      const response = unwrapResult(
        await dispatch(
          quotationActions.updateTemplateThunk({
            ...template,
            branch_id: branchId
          })
        )
      );

      if (response.success && template.id) {
        onGetTemplateList('', branchId);
        onGetTemplateDetails(template.id);
      }
    } catch (error) {
      console.log('Failed to update template details', error);
    }
    setIsLoading(false);
  };

  const deleteTemplate = async (branchId: number | undefined) => {
    setIsLoading(true);

    try {
      const response = unwrapResult(
        await dispatch(
          quotationActions.onDeleteTemplateThunk(selectedTemplate?.id)
        )
      );

      if (response.success) {
        setSelectedTemplate(initialTemplate);
        onGetTemplateList('', branchId);
      }
    } catch (error) {
      console.log('Failed to update template details', error);
    }
    setIsLoading(false);
  };

  const onSelectTemplate = (template: TemplateList | null) => {
    if (!template) {
      setSelectedTemplate(initialTemplate);
      return;
    }

    try {
      onGetTemplateDetails(template.id);
    } catch (error) {
      console.error('Failed to fetch template details', error);
    }
  };

  return {
    isLoading,
    templateList,
    onGetTemplateList,
    onGetTemplateDetails,
    selectedTemplate,
    showTemplateForm,
    newTemplate,
    initialTemplate,
    setSelectedTemplate,
    setNewTemplate,
    setShowTemplateForm,
    onSelectTemplate,
    createTemplate,
    deleteTemplate,
    updateTemplate,
    isPCBuild,
    setIsPCBuild,
    isBoost,
    setIsBoost
  };
}
