/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { GetInventoryResponse, GetInventoryRequest } from 'src/types/inventory';
import {
  GetAvailableInventoryRequest,
  GetAvailableInventoryResponse,
  GetSumAvailableResponse
} from 'src/types/available-inventory';
import { CommonAxiosResponse } from 'src/types';
import { cleanSN } from 'src/utils';

export const getInventoryForCSVThunk = createAsyncThunk<
  CommonAxiosResponse<GetInventoryResponse>,
  GetInventoryRequest | undefined,
  { state: RootState }
>('sale/getInventoryForCSVThunk', async (data, api) => {
  let request = data ?? api.getState().inventory.inventoryFilter;
  let sn = null;

  if (request?.serial_no) {
    sn = cleanSN(request.serial_no);
    request = { ...request, serial_no: sn };
  }

  request = { ...request, limit: 999, page: 1 };

  //   Commented out section below due to status logic refactor requiring atleast one status value to pass payload
  //   if (isAllStatusSelected(request?.status)) {
  //     // If all is selected. Why even bother. Just delete status property
  //     request = {
  //       ...request,
  //       status: undefined
  //     };
  //   }

  //if range picker from and to is undefined delete the key
  if (!request?.sold_date?.from || !request?.sold_date?.to) {
    delete request?.sold_date;
  }
  if (!request?.purchase_date?.from || !request?.purchase_date?.to) {
    delete request?.purchase_date;
  }
  if (!request?.encoded_date?.from || !request?.encoded_date?.to) {
    delete request?.encoded_date;
  }

  const response = await Api.post('/inventory/get', request);
  return response;
});

export const getInventoryThunk = createAsyncThunk<
  CommonAxiosResponse<GetInventoryResponse>,
  GetInventoryRequest | undefined,
  { state: RootState }
>('sale/getInventoryThunk', async (data, api) => {
  let request = data ?? api.getState().inventory.inventoryFilter;

  let sn = null;

  if (request?.serial_no) {
    sn = cleanSN(request.serial_no);
    request = { ...request, serial_no: sn };
  }

  //   Commented out section below due to status logic refactor requiring atleast one status value to pass payload
  //   if (isAllStatusSelected(request?.status)) {
  //     // If all is selected. Why even bother. Just delete status property
  //     request = {
  //       ...request,
  //       status: undefined
  //     };
  //   }

  //if range picker from and to is undefined delete the key
  if (!request?.sold_date?.from || !request?.sold_date?.to) {
    delete request?.sold_date;
  }
  if (!request?.purchase_date?.from || !request?.purchase_date?.to) {
    delete request?.purchase_date;
  }
  if (!request?.encoded_date?.from || !request?.encoded_date?.to) {
    delete request?.encoded_date;
  }

  const response = await Api.post('/inventory/get', request);
  return response;
});

export const getAvailableInventoryListThunk = createAsyncThunk<
  CommonAxiosResponse<GetAvailableInventoryResponse>,
  GetAvailableInventoryRequest,
  { state: RootState }
>('inventory/getAvailableInventoryThunk', async (data) => {
  const response = await Api.get('/product/inventory/get', {
    ...data,
    to_get: 'list'
  });
  return response;
});

export const getAvailableInventorySumThunk = createAsyncThunk<
  CommonAxiosResponse<GetSumAvailableResponse>,
  GetAvailableInventoryRequest,
  { state: RootState }
>('inventory/getAvailableInventoryThunk', async (data) => {
  const response = await Api.get('/product/inventory/get', {
    ...data,
    to_get: 'sum'
  });
  return response;
});
