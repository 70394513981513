import React, { useEffect } from 'react';
import { CircularProgress, makeStyles, TextField } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import { colors } from 'src/constants';

import TemplatesModules from '../hooks/TemplatesModule';
import { TemplateDetails, TemplateList } from 'src/types';
import { isEmpty, find } from 'lodash';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1)
    },
    autoComplete: {
      borderBottomWidth: 1,
      borderBottomColor: 'pink'
    },
    input: {
      color: colors.blue[500],
      fontWeight: 500
    },
    label: {
      fontFamily: 'Roboto',
      color: '#263238',
      fontWeight: 500,
      fontSize: 14
    }
  }),
  { index: 999 }
);

interface TemplateProps {
  selectedTemplate?: TemplateDetails | null;
  branchId?: number | undefined;
  isCategoryLoading?: boolean;
  onTemplateChange: (e: TemplateList | null) => void;
}

const Templates = ({
  selectedTemplate,
  branchId,
  isCategoryLoading,
  onTemplateChange
}: TemplateProps) => {
  const classes = useStyles();

  const { isLoading, templateList, onGetTemplateList } = TemplatesModules();

  const processGetTemplates = (keyword: string) => {
    let exist = false;
    if (!isEmpty(templateList)) {
      exist = !isEmpty(find(templateList, { name: keyword }));
    }

    if (!exist) {
      onGetTemplateList(keyword, branchId);
    }
  };

  useEffect(() => {
    processGetTemplates('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <label htmlFor="template-list" className={classes.label}>
          Templates
        </label>
        <Autocomplete
          fullWidth
          size="small"
          id="template-list"
          className={classes.autoComplete}
          options={templateList}
          // value={selectedTemplate ? { ...selectedTemplate } : null}
          value={selectedTemplate}
          getOptionSelected={(option, value) => option?.id === value?.id}
          onChange={(_, newValue) => onTemplateChange(newValue)}
          getOptionLabel={(option) => option.name ?? ''}
          onInputChange={(_, newInputValue) =>
            processGetTemplates(newInputValue)
          }
          disabled={isCategoryLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              multiline
              label=""
              margin="none"
              variant="standard"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading && (
                      <CircularProgress color="primary" size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
      </div>
    </>
  );
};

export const QuotationTemplates = React.memo(Templates);
