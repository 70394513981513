import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Game } from 'src/redux/slices/games/types';
import { BundleDetailsV2 } from 'src/redux/slices/pc-bundles/types';
import GameSwiper from './GameSwiper';
import AddIconAdornment from '../components/AddIconAdornment';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { Autocomplete } from '@material-ui/lab';
import { getGamesListThunk } from 'src/redux/slices/games/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import { LoaderBar } from 'src/components';
import { isEmpty } from 'lodash';

interface Props {
  bundle?: BundleDetailsV2;
}

const {
  selectors: pcBundleSelectors,
  actions: pcBundleActions
} = slices.pcBundles;

const Games: FC<Props> = ({ bundle }) => {
  const dispatch = useAppDispatch();
  const gamesPayload = useAppSelector(pcBundleSelectors.selectGames);

  const [games, setGames] = useState<Game[]>([]);
  const [gamesSelected, setGamesSelected] = useState<Game[]>([]);
  const [gameVal, setGameVal] = useState<Game | null>(null);
  const [score, setScore] = useState<string>('');
  const [errGame, setErrGame] = useState<string>('');
  const [errScore, setErrScore] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const resetGameStates = useCallback(() => {
    setGameVal(null);
    setScore('');
    setErrGame('');
    setErrScore('');
  }, []);

  const removeGame = useCallback(
    (name: string) => {
      const filtered = gamesSelected.filter((i: Game) => i.name !== name);
      setGamesSelected(filtered);
      dispatch(pcBundleActions.setGames(filtered));
    },
    [dispatch, gamesSelected]
  );

  const addGame = useCallback(() => {
    let err = '';

    if (!gameVal) {
      err = 'Invalid Game';
      setErrGame(err);
    }

    if (!score) {
      err = 'Invalid Score';
      setErrScore(err);
    }

    if (gamesSelected.find((i: Game) => i.name === gameVal?.name)) {
      err = 'Game is already selected';
      setErrScore(err);
    }

    if (err) {
      return;
    }

    let newGame = games.find((i) => i.name === gameVal?.name);
    newGame = { ...newGame, game_id: newGame?.id };
    let updatedGames = [
      ...gamesSelected,
      { ...newGame, score: +score, game_id: newGame.id }
    ];
    setGamesSelected(updatedGames);
    dispatch(pcBundleActions.setGames(updatedGames));
    resetGameStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, gameVal, games, gamesSelected, resetGameStates, score]);

  const onChangeGame = (e: any, value: Game | null) => {
    value && setGameVal(value);
  };

  // todo make hooks for this reusable function someday
  const getGames = useCallback(async () => {
    setIsLoading(true);
    const response = unwrapResult(
      await dispatch(getGamesListThunk({ keyword: '', limit: 99 }))
    );
    setIsLoading(false);
    setGames(response?.originalData?.data);
  }, [dispatch]);

  // type any band aid for ts error
  // Need name and score for showing games selected
  // Find game in game options to find their name because api includes only game_id
  // Once game is found insert score from api response
  const gameConverter = (val: any) => {
    const res = val.map((i: Game) => {
      const current = games.find((j) => j.id === i?.game_id);
      return { ...current, score: i.score, game_id: current?.id };
    });
    return res;
  };

  useEffect(() => {
    !isEmpty(bundle?.games) &&
      dispatch(pcBundleActions.setGames(gameConverter(bundle?.games)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundle]);

  useEffect(() => {
    setGamesSelected(gamesPayload || []);
  }, [gamesPayload]);

  useEffect(() => {
    getGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card style={{ marginTop: '1em' }}>
      <CardContent>
        <Grid container>
          <Grid
            item
            container
            xs={4}
            style={{
              rowGap: '1em'
            }}
          >
            <Grid item xs={12}>
              {isLoading ? (
                <LoaderBar isLoading={isLoading} />
              ) : (
                <>
                  <Typography>Choose a Game</Typography>
                  <Autocomplete
                    options={games}
                    value={gameVal}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={onChangeGame}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    getOptionSelected={(option, value) =>
                      option.id === value?.id
                    }
                  />
                </>
              )}
              <Typography
                style={{ fontSize: '.8em', marginLeft: '1em' }}
                color="secondary"
              >
                {errGame}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Review Score</Typography>
              <TextField
                value={score}
                error={!!errScore}
                helperText={errScore}
                fullWidth
                variant="outlined"
                placeholder="Enter Review Score"
                onChange={(e) => {
                  const num = e.target.value;
                  if (
                    num === '' ||
                    (/^\d*$/.test(num) && +num > 0 && +num <= 100)
                  ) {
                    setScore(num);
                  }
                }}
                onKeyDown={(e) => e.key === 'Enter' && addGame()}
                InputProps={{
                  endAdornment: <AddIconAdornment onClick={addGame} />
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Box flex="1 1 auto" minWidth="0px" style={{ height: '100%' }}>
              {gamesSelected[0] && (
                <GameSwiper
                  gamesSelected={gamesSelected}
                  removeGame={removeGame}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default Games;
