import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  FixMeLaterApi,
  GetCategoryMainData,
  SubCategoryListData
} from 'src/types';
import { usePermissions } from 'src/hooks';

interface Props {
  mainCategories: GetCategoryMainData[];
  onUpdateToggleDialog: () => void;
  onHandleMainCategoryPayload: (item: GetCategoryMainData) => void;
  onHandleGetSubCategory: (item: SubCategoryListData[]) => void;
  onHandleShowSubCategory: () => void;
  onHandleMainCategoryName: (mainName: string) => void;
  onCategoryRefetch: () => void;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  paperStyle: {
    margin: '0.5rem',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    cursor: 'pointer',
    '&:hover': { background: '#F5F5F5' }
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    padding: 0
  }
}));

const MainCategories = ({
  className,
  mainCategories,
  onUpdateToggleDialog,
  onHandleMainCategoryPayload,
  onHandleMainCategoryName,
  onHandleGetSubCategory,
  onHandleShowSubCategory,
  ...rest
}: Props) => {
  const { canEditCategory } = usePermissions();
  const navigate = useNavigate();
  const classes = useStyles();

  const onHandleSelectMainCategorySub = (
    item: SubCategoryListData[],
    mainName?: string
  ) => {
    if (item) {
      onHandleGetSubCategory(item || []);
      onHandleShowSubCategory();
    }
    if (mainName) {
      onHandleMainCategoryName(mainName);
    }
  };

  const onHandleUpdate = (item: GetCategoryMainData) => {
    onHandleMainCategoryPayload(item);
    onUpdateToggleDialog();
  };

  //TODO: fix later for update
  // eslint-disable-next-line no-unused-vars
  const onCategoryPress = (item: FixMeLaterApi) => {
    navigate(`/app/categories/${item.id}`, { state: item });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box>
          {mainCategories.map((item: GetCategoryMainData) => (
            <Paper className={classes.paperStyle} key={item.id}>
              <Box className={classes.flexRow}>
                <Box
                  className={classes.flexRow}
                  style={{
                    justifyContent: 'flex-start',
                    width: '100%',
                    padding: '0.5rem'
                  }}
                  onClick={() =>
                    onHandleSelectMainCategorySub(
                      item?.target_data || [],
                      item?.name
                    )
                  }
                >
                  <img
                    alt="main-category-img"
                    style={{
                      width: '60px',
                      height: '60px',
                      marginRight: '1rem'
                    }}
                    src={
                      item.img_url ||
                      '/static/images/products/product_not_found.png'
                    }
                  />
                  <Typography>{item?.name}</Typography>
                </Box>
                <Box style={{ minWidth: '100px' }}>
                  {canEditCategory && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onHandleUpdate(item)}
                    >
                      {`Update`}
                    </Button>
                  )}
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default MainCategories;
