import React, { useEffect, useMemo, useState } from 'react';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  makeStyles
} from '@material-ui/core';
import { LoaderBar, LoadingButton, Page } from 'src/components';
import { slices, useAppDispatch } from 'src/redux';
import { usePermissions, useSnackBar } from 'src/hooks';

import { FlashDealsCreateRequest, Product } from 'src/types';
import { FlashDealsProductsCardContent } from '../component';
import { dateTodayVanilla } from 'src/utils';
import { unwrapResult } from '@reduxjs/toolkit';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import format from 'date-fns/format';
import { useLocation } from 'react-router-dom';

const { actions: flashDealsActions } = slices.flashDeals;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 20
  },
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  createBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 60
  }
}));

const component = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const snackBar = useSnackBar();
  const { canAddFlashDeals } = usePermissions();

  const location: any = useLocation();
  const productFromStateLocation: Product = location.state;

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProduct, onProductChange] = useState<Product>();
  const [fromDateTime, onFromDateTimeChange] = useState<
    MaterialUiPickersDate | undefined
  >(dateTodayVanilla());
  const [toDateTime, onToDateTimeChange] = useState<
    MaterialUiPickersDate | undefined
  >(dateTodayVanilla());

  const flashDealCreatePayload = useMemo(() => {
    let requestPayload: FlashDealsCreateRequest = {};
    requestPayload.product_id = selectedProduct?.id;
    requestPayload.deal_start = format(
      new Date(fromDateTime || ''),
      'yyyy-MM-dd HH:mm:ss'
    );
    requestPayload.deal_end = format(
      new Date(toDateTime || ''),
      'yyyy-MM-dd HH:mm:ss'
    );
    return requestPayload;
  }, [fromDateTime, selectedProduct, toDateTime]);

  const isFlashDealsCreateParamsValid = () => {
    if (!flashDealCreatePayload?.product_id) {
      return 'Please select a product';
    }
    if (!flashDealCreatePayload?.deal_start) {
      return 'Please select a start date';
    }
    if (!flashDealCreatePayload?.deal_end) {
      return 'Please select an end date';
    }
    return '';
  };

  const onCreateFlashDeal = async () => {
    const errMsg = isFlashDealsCreateParamsValid();
    if (errMsg) {
      snackBar.show({
        severity: 'error',
        message: errMsg || 'Something is Wrong'
      });
      return;
    }
    setLoading(true);
    const response = unwrapResult(
      await dispatch(
        flashDealsActions.flashDealsCreateThunk(flashDealCreatePayload)
      ).finally(() => setLoading(false))
    );
    if (response.success) {
      snackBar.show({
        severity: 'success',
        message: 'Flash Deal created successfully.'
      });
    } else {
      snackBar.show({
        severity: 'error',
        message:
          response?.message || response?.errors
            ? JSON.stringify(response?.errors)
            : 'Something went wrong'
      });
    }
  };

  useEffect(() => {
    if (productFromStateLocation?.id) {
      onProductChange(productFromStateLocation);
    }
    return () => onProductChange(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="New Flash Deal">
      <LoaderBar isLoading={loading} />
      <Container maxWidth={false}>
        <Box mt={2}>
          <Card>
            <CardHeader
              title="New Flash Deal"
              subheader="The information of New Flash Deal."
            />

            <Divider />
            <FlashDealsProductsCardContent
              selectedProduct={selectedProduct}
              onProductChange={(p) => onProductChange(p)}
              fromDateTime={fromDateTime}
              onFromDateTimeChange={(d) => onFromDateTimeChange(d)}
              toDateTime={toDateTime}
              onToDateTimeChange={(d) => onToDateTimeChange(d)}
            />

            <Divider />
            {/* TODO: Checkbox if navigate. */}
          </Card>
        </Box>

        <Box style={{ marginTop: 30 }}>
          <LoadingButton
            disabled={!canAddFlashDeals}
            startIcon={<PlusOneIcon />}
            color="primary"
            height={50}
            loading={loading}
            title="Create Flash Deal"
            fullWidth
            variant="contained"
            onClick={onCreateFlashDeal}
          />
        </Box>
      </Container>
    </Page>
  );
};

export const AddFlashDealsView = React.memo(component);
