// TODO: remove later
/* eslint-disable no-unused-vars */
import { Box, Container, makeStyles } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Page } from 'src/components';
import { LoaderBar } from 'src/components';
import Toolbar from './components/Toolbar';
import GamesListTable from './components/GamesListTable';
import { useAppDispatch } from 'src/redux';
import { getGamesListThunk } from 'src/redux/slices/games/thunks';
import { Game } from 'src/redux/slices/games/types';
import { unwrapResult } from '@reduxjs/toolkit';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const GamesListView: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [games, setGames] = useState<Game[]>([]);

  const getGames = useCallback(async () => {
    setIsLoading(true);
    const response = unwrapResult(
      await dispatch(getGamesListThunk({ keyword: '', limit: 99 }))
    );
    setIsLoading(false);
    setGames(response?.originalData?.data);
  }, [dispatch]);

  const onSearchGames = useCallback(
    async (val: string) => {
      // limit is temporary no pagination yet
      setIsLoading(true);
      const response = unwrapResult(
        await dispatch(getGamesListThunk({ keyword: val, limit: 10 }))
      );
      setIsLoading(false);
      setGames(response?.originalData?.data);
    },
    [dispatch]
  );

  useEffect(() => {
    getGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="Games">
      <Container maxWidth={false}>
        <Toolbar getGames={getGames} onSearchGames={onSearchGames} />
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <GamesListTable games={games} getGames={getGames} />
        </Box>
      </Container>
    </Page>
  );
};

export default GamesListView;
