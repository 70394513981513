import React, { FC, useCallback, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { FixMeLater } from 'src/types';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Highlight } from 'src/redux/slices/pc-bundles/types';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  swiperButton: {
    minWidth: 'unset', // bypass strict mui button minWidth for customization
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    border: '1px solid grey',
    '&::after': { fontSize: '20px' }
  }
}));

interface Props {
  highlights: Highlight[];
  removeHighlight: (url: string) => void;
}

const HighlightSwiper: FC<Props> = ({ highlights, removeHighlight }) => {
  const classes = useStyles();
  const swiperRef = useRef<FixMeLater>(null);

  const dynamicWidth = useCallback((v: boolean) => (v ? '113px' : '356px'), []);

  //   Powered by chatgpt
  const convertToEmbedLink = (i: Highlight) => {
    if (i.isShort) {
      const shortVideoId = i.video.split('/shorts/')[1];
      return `https://www.youtube.com/embed/${shortVideoId}`;
    }

    const videoId = i.video.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      return `https://www.youtube.com/embed/${videoId.substring(
        0,
        ampersandPosition
      )}`;
    }
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <Grid container style={{ marginTop: '1em', height: '100%' }}>
      <Grid item xs={1} className={classes.buttonContainer}>
        <Button
          className={classes.swiperButton}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <ArrowBackIcon style={{ color: 'grey' }} />
        </Button>
      </Grid>
      <Grid item xs={10}>
        <Swiper
          spaceBetween={10}
          slidesPerView="auto"
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          style={{ height: '100%', padding: '0px 1em' }}
        >
          {highlights[0] &&
            highlights.map((i, ind) => (
              <SwiperSlide
                style={{
                  width: dynamicWidth(i.isShort)
                }}
                key={ind}
              >
                <Box
                  style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <iframe
                    width={dynamicWidth(i.isShort)}
                    height={'200px'}
                    src={convertToEmbedLink(i)}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                  ></iframe>
                  <Button
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      minWidth: 'unset',
                      borderRadius: '50%',
                      border: '1px solid gray',
                      background: 'white',
                      width: '1.5em',
                      height: '1.5em'
                    }}
                    onClick={() => removeHighlight(i.video)}
                  >
                    <ClearIcon color="secondary" style={{ fontSize: '1em' }} />
                  </Button>
                </Box>
              </SwiperSlide>
            ))}
        </Swiper>
      </Grid>
      <Grid className={classes.buttonContainer} item xs={1}>
        <Button
          className={classes.swiperButton}
          onClick={() => swiperRef.current?.slideNext()}
        >
          <ArrowForwardIcon style={{ color: 'grey' }} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default HighlightSwiper;
