import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  ChangeTransactionSerialPayload,
  ChangeTransactionSerialResponse,
  CommonAxiosResponse,
  CommonResponseMessage,
  UpdateTransactionDetailRequest
} from 'src/types';

export const updateTransactionDetailThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  UpdateTransactionDetailRequest,
  { state: RootState }
>('transaction/updateTransactionDetailThunk', async (data) => {
  let request = data;
  request.remarks = request?.remarks || 'update data';
  delete request.order_no; // EDITING OF ORDER_NO NOT ALLOWED.

  const response = await Api.patch(
    `/transaction/details/update/${request?.id}`,
    request
  );
  // if (!response.success) {
  //   throw new Error('Failed updateTransactionDetailThunk');
  // }
  return response;
});

export const changeTransactionSerialThunk = createAsyncThunk<
  CommonAxiosResponse<ChangeTransactionSerialResponse>,
  ChangeTransactionSerialPayload,
  { state: RootState }
>('transactions/changeTransactionSerialThunk', async (data) => {
  const response = await Api.patch(
    '/transactions/item_change_serial_no/update',
    data
  );

  return response;
});
