import React, { FC, memo, useEffect, useState } from 'react';
import {
  CircularProgress,
  InputAdornment,
  SvgIcon,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { GetBranchCategoryData, Product } from 'src/types';
import { Search as SearchIcon } from 'react-feather';
import { useInventoryAudit } from 'src/hooks/inventory-audit';

interface Props {
  label?: string;
  placeholder?: string;
  debounceDelay?: number;
  onOptionSelected: (option?: GetBranchCategoryData) => void;
}

const component: FC<Props> = ({
  label = 'Search Products',
  placeholder = '',
  debounceDelay = 1000,
  onOptionSelected
}) => {
  const {
    productOptions,
    commonAuditParams,
    isLoading,
    getBranchCategoryProduct
  } = useInventoryAudit();

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Product[]>([]);

  const handleOptionSelected = (option?: GetBranchCategoryData) => {
    if (!options) {
      return;
    }
    onOptionSelected(option);
  };

  const onHanddleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior
      const selectedOption = productOptions.find(
        (option) => option.product_name === inputValue
      );
      handleOptionSelected(selectedOption);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (inputValue === '') {
        setOptions([]);
        return;
      }
      if (inputValue !== '') {
        getBranchCategoryProduct({
          keyword: inputValue,
          branch_id: commonAuditParams?.branch_id,
          category_id: commonAuditParams?.category_id
        });
        return;
      }
    };
    const debounceTimeout = setTimeout(fetchData, debounceDelay);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [commonAuditParams, debounceDelay, getBranchCategoryProduct, inputValue]);

  return (
    <Autocomplete
      options={productOptions}
      getOptionLabel={(option) => `${option.product_name}`}
      onChange={(event, newValue: any) => handleOptionSelected(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={onHanddleKeyDown}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export const ProductByCategory = memo(component);
