import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Box,
  Button,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import { slices, useAppDispatch } from 'src/redux';
import {
  AddReplaceTransactionRequest,
  AddTempTransactionProp,
  ProductConsumable,
  ProductWithSerialNo,
  TransactionInputField
} from 'src/types';
import { Autocomplete } from '@material-ui/lab';
import { theme } from 'src/theme';
import { useDebouncedEffect, useSnackBar } from 'src/hooks';
import { unwrapResult } from '@reduxjs/toolkit';
import { localize } from 'src/constants';
import { cleanSN, isEmptyOrSpaces } from 'src/utils';
import { ListingStatusEnum } from 'src/enums';
import { NonSerializeProductDropdown } from 'src/components/dropdown/NonSerializeDropdown';
import { BranchListDropDown } from 'src/components/dropdown';
import { isEmpty } from 'lodash';
import { useBranchInfo } from 'src/hooks/branch/use-branch-info';

const { actions: productActions } = slices.product;
const { actions: listingActions } = slices.listing;

const useStyles = makeStyles((theme) => ({
  body: {
    position: 'absolute',
    width: '50vw',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 8
  },
  filter: {
    marginTop: 20,
    marginBottom: 20
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  footer: {
    marginTop: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  consumableField: {
    display: 'flex',
    flexDirection: 'row'
  },
  consumableSerial: {
    fontSize: 10,
    fontWeight: 500
  }
}));

const bodyStyle: CSSProperties = {
  top: '20%'
};

const modalStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

interface Props {
  visible: boolean;
  mostUsedBranch: string;
  onHandleClose: () => void;
  data?: AddReplaceTransactionRequest;
  onAddOrReplace: (data: AddReplaceTransactionRequest) => void;
}

const AddItemTransaction = ({
  visible,
  mostUsedBranch,
  onHandleClose,
  data,
  onAddOrReplace
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const {
    branchNameToID,
    selectedBranchIDs,
    setSelectedBranchIDs
  } = useBranchInfo();

  const [loadingListingInfo, setLoadingListing] = useState<boolean>(false);
  const [serialNo, setSerialNo] = useState('');

  const [productNameInput, setProductNameInput] = useState('');
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [products, setProducts] = useState<ProductWithSerialNo[]>([]);
  const [
    selectedProduct,
    setSelectedProduct
  ] = useState<ProductWithSerialNo | null>(null);

  const [consumableInput, setConsumableInput] = useState('');
  const [isConsumablesLoading, setIsConsumablesLoading] = useState(false);
  const [consumables, setConsumables] = useState<ProductConsumable[]>([]);
  const [
    selectedConsumable,
    setSelectedConsumable
  ] = useState<ProductConsumable | null>(null);
  const [consumableQty, setConsumableQty] = useState<number>();
  const [errorSerial, setErrorSerial] = useState<string>('');

  const onClose = () => {
    onHandleClose();
  };

  const consumableOptionLabel = useCallback(
    (option: ProductConsumable) => {
      return (
        <div>
          <Typography>{`${option?.product_name} (per ${option?.consumable_unit}) - ${option?.consumable_qty} stocks left`}</Typography>
          <Typography
            className={classes.consumableSerial}
          >{`${option?.serial_no}`}</Typography>
        </div>
      );
    },
    [classes.consumableSerial]
  );

  const getListingInfoViaSerial = async () => {
    if (!serialNo) {
      return false;
    }
    setLoadingListing(true);
    const response = unwrapResult(
      await dispatch(
        listingActions.getListingViaSNThunk({
          serial_no: cleanSN(serialNo),
          branch_ids: selectedBranchIDs
        })
      ).finally(() => setLoadingListing(false))
    );

    const listing = response?.originalData?.listing;

    if (!listing) {
      setErrorSerial(`This serial no "${serialNo}" does not exist`);
      setSerialNo('');
      return undefined;
    }
    if (listing?.status !== ListingStatusEnum.Available) {
      setErrorSerial(`Serial: "${serialNo}" is not available`);
      setSerialNo('');
      return undefined;
    }
    return listing;
  };

  const isSerialNumberValid = () => {
    const serial_no = serialNo?.trim();
    setSerialNo('');

    if (isEmptyOrSpaces(serial_no)) {
      snackBar.show({
        severity: 'error',
        message: localize.ERR_INVALID_SERIAL_NO
      });
      setSerialNo('');
      return false;
    }
    return true;
  };

  const addTransactionToTempList = (param: AddTempTransactionProp) => {
    const { serialNoArg, productNameArg, quantity } = param;

    const newTransactionListingData: AddReplaceTransactionRequest = {
      quantity,
      serial_no: serialNoArg,
      product_name: productNameArg,
      transaction_no: data?.transaction_no,
      toBeReplacedItem: data?.toBeReplacedItem,
      mode: data?.mode,
      branch_id: selectedBranchIDs || []
    };

    setSerialNo('');
    setSelectedProduct(null);
    setProductNameInput('');
    setSelectedConsumable(null);
    setConsumableInput('');
    setConsumableQty(undefined);
    // serialNoTextFieldRef?.current?.focus();

    onAddOrReplace(newTransactionListingData);
  };

  const onAddToTempTransaction = async () => {
    snackBar.hide();

    // If a product with SN is selected / inputted
    if (selectedProduct) {
      addTransactionToTempList({
        serialNoArg: selectedProduct?.serial_no,
        productNameArg: selectedProduct?.product_name
      });
      return;
    }

    // If SN is selected / inputted
    if (serialNo) {
      if (loadingListingInfo) {
        return;
      }
      if (!isSerialNumberValid()) {
        return;
      }

      const listingData = await getListingInfoViaSerial();
      if (!listingData) {
        return;
      }

      addTransactionToTempList({
        serialNoArg: listingData?.serial_no,
        productNameArg: listingData?.product_name
      });
      return;
    }

    // If consumable is selected / inputted
    if (selectedConsumable) {
      // product id and quantity is neede because in this product type. We have no serial no.
      addTransactionToTempList({
        serialNoArg: selectedConsumable?.serial_no,
        productNameArg: selectedConsumable?.product_name,
        quantity: consumableQty || 1,
        product_id: selectedConsumable?.product_id,
        is_consumable: true,
        listing_id: selectedConsumable?.listing_id,
        consumable_unit: selectedConsumable?.consumable_unit
      });
      return;
    }
  };

  const onChangeSerialNo = (serial_no: string) => {
    setProductNameInput('');
    setConsumableInput('');
    setConsumableQty(undefined);
    setSerialNo(serial_no);
    setErrorSerial('');
  };

  const onGetConsumablesViaKeyword = useCallback(
    async (branchIds?: number[]) => {
      setIsConsumablesLoading(true);
      const response = unwrapResult(
        await dispatch(
          productActions.getProductConsumablesThunk({
            keyword: consumableInput,
            branch_id: branchIds || selectedBranchIDs
          })
        ).finally(() => setIsConsumablesLoading(false))
      );

      if (response?.success) {
        setConsumables(response?.originalData?.products || []);
      }
    },
    [consumableInput, dispatch, selectedBranchIDs]
  );

  const onGetProductsViaKeyword = useCallback(
    async (branchIds?: number[]) => {
      setIsProductsLoading(true);
      const response = unwrapResult(
        await dispatch(
          productActions.getProductsWithSerialThunk({
            keyword: productNameInput,
            branch_ids: branchIds || selectedBranchIDs
          })
        ).finally(() => setIsProductsLoading(false))
      );

      if (response?.success) {
        setProducts(response?.originalData?.products || []);
      }
    },
    [dispatch, productNameInput, selectedBranchIDs]
  );

  const onProductNameInputChange = (text?: string) => {
    setSerialNo('');
    setProductNameInput(text || '');
  };

  // Disable field that is not related to current field.
  const isFieldDisabled = useCallback(
    (field: TransactionInputField) => {
      switch (field) {
        case 'serialized':
          return consumableInput ||
            selectedConsumable ||
            consumableQty ||
            loadingListingInfo ||
            productNameInput ||
            !selectedBranchIDs
            ? true
            : false;
        case 'non-sn':
          return consumableInput ||
            selectedConsumable ||
            consumableQty ||
            serialNo ||
            !selectedBranchIDs
            ? true
            : false;
        case 'consumable':
          return productNameInput || serialNo || !selectedBranchIDs
            ? true
            : false;
        default:
          break;
      }
    },
    [
      consumableInput,
      consumableQty,
      loadingListingInfo,
      productNameInput,
      selectedBranchIDs,
      selectedConsumable,
      serialNo
    ]
  );

  // Clear field that is not related to current field onFocus.
  const onFocusOfField = (field: TransactionInputField) => {
    switch (field) {
      case 'serialized':
        setProductNameInput('');
        setSelectedProduct(null);

        setConsumableInput('');
        setSelectedConsumable(null);
        setConsumableQty(undefined);
        break;
      case 'non-sn':
        setSerialNo('');
        setConsumableInput('');
        setSelectedConsumable(null);
        setConsumableQty(undefined);
        break;
      case 'consumable':
        setProductNameInput('');
        setSelectedProduct(null);
        setSerialNo('');
        break;
      default:
        break;
    }
  };

  const onChangeSelectedConsumable = (newValue: ProductConsumable | null) => {
    if (!consumableQty && newValue) {
      setConsumableQty(1);
    }
    if (!newValue) {
      setConsumableQty(undefined);
    }
    setSelectedConsumable(newValue);
  };

  const onEnterPress = (e: any) => {
    e.key === 'Enter' && onAddToTempTransaction();
  };

  const okBtnLabel = useMemo(() => {
    if (data?.mode === 'add') {
      return 'Add item to transaction';
    }
    if (data?.mode === 'replace') {
      return 'Replace item to transaction';
    }
    return 'Ok';
  }, [data]);

  const titleLabel = useMemo(() => {
    const transactionNo = data?.transaction_no;
    if (data?.mode === 'add') {
      return `Add item to transaction(${transactionNo})`;
    }
    if (data?.mode === 'replace') {
      return `Replace item(${data?.toBeReplacedItem?.product_name}) on transaction(${transactionNo})`;
    }
    return '';
  }, [data]);

  const onClearFieldsData = () => {
    setSerialNo('');
    setSelectedProduct(null);
    setSelectedConsumable(null);
    setConsumableQty(0);
    setConsumableInput('');
  };

  const onChangeBranch = useCallback(
    (prodName?: string, branchIds?: number[]) => {
      //when change start with fresh input fields
      onClearFieldsData();
      setSelectedBranchIDs(branchIds || []);
      onGetConsumablesViaKeyword(branchIds);
      onGetProductsViaKeyword(branchIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onGetConsumablesViaKeyword, onGetProductsViaKeyword]
  );

  useEffect(() => {
    setSelectedBranchIDs([branchNameToID(mostUsedBranch)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mostUsedBranch]);

  useEffect(() => {
    //if branch ids is empty clear this fields
    if (isEmpty(selectedBranchIDs)) {
      onClearFieldsData();
    }
  }, [selectedBranchIDs]);

  useDebouncedEffect(onGetProductsViaKeyword, 500, [productNameInput]);
  useDebouncedEffect(onGetConsumablesViaKeyword, 500, [consumableInput]);

  return (
    <Modal
      disableBackdropClick
      open={visible}
      onClose={onHandleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={modalStyle}
    >
      <div style={bodyStyle} className={classes.body}>
        <div className={classes.titleContainer}>
          <Typography variant="h4" color="textPrimary">
            {titleLabel}
          </Typography>
        </div>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <BranchListDropDown
                disabled={!!mostUsedBranch}
                defaultValue={selectedBranchIDs}
                onHandleBranchChange={(branchIds?: number[]) => {
                  onChangeBranch(
                    consumableInput || productNameInput,
                    branchIds
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onFocus={() => onFocusOfField('serialized')}
                disabled={isFieldDisabled('serialized')}
                error={!!errorSerial}
                fullWidth
                helperText={errorSerial || 'e.g. GPU, CPU, RAM'}
                label="Serialized - Serial no."
                name="serial_no"
                onChange={(e) => onChangeSerialNo(e.target.value)}
                onKeyDown={onEnterPress}
                value={serialNo}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="small"
                      onClick={() => onChangeSerialNo('')}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <NonSerializeProductDropdown
                selectedProduct={selectedProduct}
                setSelectedProduct={(product: ProductWithSerialNo | null) =>
                  setSelectedProduct(product)
                }
                productOptions={products}
                productLoading={isProductsLoading}
                isDisabled={() => isFieldDisabled('non-sn') || false}
                onProdInputChange={(prodName?: string) => {
                  onProductNameInputChange(prodName);
                }}
                onFocus={() => onFocusOfField('non-sn')}
              />
            </Grid>
            <Grid className={classes.consumableField} item xs={12}>
              <TextField
                style={{ width: 150 }}
                type="number"
                label="quantity"
                name="consumable_qty"
                variant="outlined"
                onFocus={() => onFocusOfField('consumable')}
                value={consumableQty || ''}
                disabled={isFieldDisabled('consumable') || !selectedConsumable}
                onChange={(e) => setConsumableQty(+e.target.value)}
              />
              <Autocomplete
                fullWidth
                onFocus={() => onFocusOfField('consumable')}
                style={{ marginLeft: theme.spacing(1) }}
                loading={isConsumablesLoading}
                disabled={isFieldDisabled('consumable')}
                value={selectedConsumable}
                onChange={(_, newValue) => onChangeSelectedConsumable(newValue)}
                onKeyDown={onEnterPress}
                inputValue={consumableInput}
                onInputChange={(_, newInputValue) =>
                  setConsumableInput(newInputValue)
                }
                id="consumable_name"
                options={consumables}
                renderOption={consumableOptionLabel}
                getOptionLabel={(option) => `${option?.product_name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="consumable_name"
                    label="Consumable - Product Name"
                    variant="outlined"
                    value={consumableInput}
                    helperText="e.g. Cables, RJ45"
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Box display="flex" flexDirection="column">
          <Button
            className={classes.footer}
            fullWidth
            onClick={onAddToTempTransaction}
            color="primary"
            variant="contained"
          >
            {okBtnLabel}
          </Button>
          <Button
            className={classes.footer}
            fullWidth
            onClick={onClose}
            color="primary"
            variant="outlined"
          >
            Close
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export const AddItemTransactionModal = React.memo(AddItemTransaction);
