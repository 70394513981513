import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Banner } from 'src/types/ecomm/banner';
import CloseIcon from '@material-ui/icons/Close';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import { useSnackBar } from 'src/hooks';

interface Props {
  open: boolean;
  lastPosition?: number;
  onClose: () => void;
  setIsLoading: (val: boolean) => void;
  getBanners: () => void;
  details?: Banner;
}

const { actions: ecomBannerActions } = slices.ecommBanner;

export const BannerDetailsModal: FC<Props> = ({
  open,
  lastPosition,
  onClose,
  setIsLoading,
  getBanners,
  details
}) => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const [label, setLabel] = useState<string>();
  const [errLabel, setErrLabel] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [publishVal, setPublishVal] = useState<number>(0);

  // TODO: Continue upload and update function on next pr
  const onUploadBanner = useCallback(async () => {
    const img = details?.img_url;

    // label and image are required in api
    if (errLabel) {
      snackBar.show({
        severity: 'error',
        useSound: true,
        message: 'Invalid Description'
      });
      return;
    }

    if (!img) {
      snackBar.show({
        severity: 'error',
        useSound: true,
        message: 'Invalid Image'
      });
      return;
    }

    setIsLoading(true);

    const res = unwrapResult(
      await dispatch(
        ecomBannerActions.uploadEcommBannersThunk({
          description: label,
          link,
          img_url: img,
          position: lastPosition || 0
        })
      )
    );

    if (res.success) {
      onClose();
      snackBar.show({
        severity: 'success',
        useSound: true,
        message: 'Banner uploaded'
      });
      getBanners();

      // delay to allow dom to render all banners then scroll to bottom of window to show new added banner position
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 800);
      return;
    }

    const errors = Object.values(res.errors);

    snackBar.show({
      severity: 'error',
      useSound: true,
      message: errors[0]
        ? errors.join(' | ')
        : 'Something went wrong, re-check inputs or try again later'
    });
    setIsLoading(false);
    return;
  }, [
    details,
    errLabel,
    setIsLoading,
    dispatch,
    label,
    link,
    lastPosition,
    snackBar,
    getBanners,
    onClose
  ]);

  const onUpdateBanner = useCallback(async () => {
    if (!details?.id) {
      return;
    }

    setIsLoading(true);

    const res = unwrapResult(
      await dispatch(
        ecomBannerActions.updateBannerThunk({
          ...details,
          description: label,
          link,
          published: publishVal ? true : false
        })
      ).finally(() => setIsLoading(false))
    );

    if (res.success) {
      snackBar.show({
        severity: 'success',
        useSound: true,
        message: 'Banner updated'
      });
      getBanners();
      onClose();
      return;
    }

    const errors = Object.values(res.errors);

    snackBar.show({
      severity: 'error',
      useSound: true,
      message: errors[0]
        ? errors.join(' | ')
        : 'Something went wrong, re-check inputs or try again later'
    });
  }, [
    details,
    dispatch,
    getBanners,
    label,
    link,
    onClose,
    publishVal,
    setIsLoading,
    snackBar
  ]);

  const onSave = useCallback(() => {
    if (details?.id) {
      onUpdateBanner();
      return;
    }
    onUploadBanner();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, onUploadBanner]);

  const title = useMemo(
    () => (details?.id ? 'Update Banner' : 'Create Banner'),
    [details]
  );

  const buttonText = useMemo(() => (details?.id ? 'update' : 'save banner'), [
    details
  ]);

  useEffect(() => {
    if (details?.id) {
      setLabel(details.description || '');
      setLink(details.link || '');
      setLabel(details.description || '');
      setPublishVal(details.published || 0);
    }
  }, [details]);

  return (
    <Dialog open={open}>
      <div
        id="header"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1em'
        }}
      >
        <Typography variant="h3">{title}</Typography>
        <CloseIcon onClick={() => onClose()} />
      </div>
      <DialogTitle
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '.3em 1em 0 1.4em'
        }}
      ></DialogTitle>
      <DialogContent>
        {details?.img_url && (
          <img
            src={details?.img_url}
            style={{ width: '100%', objectFit: 'cover' }}
          />
        )}
        <div
          style={{
            marginTop: '1em',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1em'
          }}
        >
          <TextField
            value={label}
            error={!!errLabel}
            helperText={errLabel}
            size="small"
            variant="outlined"
            fullWidth
            label="Label"
            onChange={(e) => {
              setLabel(String(e.target.value));
              setErrLabel('');
            }}
          />
          <TextField
            value={link}
            size="small"
            variant="outlined"
            fullWidth
            label="href/link"
            onChange={(e) => setLink(String(e.target.value))}
          />
        </div>
        <div
          id="bundle-status"
          style={{ marginTop: '1em', textAlign: 'center' }}
        >
          <Typography>Bundle Status</Typography>
          <RadioGroup
            value={publishVal}
            onChange={(_, val) => setPublishVal(+val)}
            style={{
              justifyContent: 'center',
              flexDirection: 'row',
              height: '100%',
              padding: '0 1em'
            }}
          >
            <FormControlLabel
              label="Published"
              value={1}
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              label="Unpublished"
              value={0}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '1em' }}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => {
            onSave();
          }}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
