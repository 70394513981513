import React, { FC } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { Button } from '@material-ui/core';

interface Props {
  url: string;
  deleteUrl: () => void;
}

const YouTubeEmbed: FC<Props> = ({ url, deleteUrl }) => {
  const convertToEmbedLink = (value: string) => {
    const videoId = value.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      return `https://www.youtube.com/embed/${videoId.substring(
        0,
        ampersandPosition
      )}`;
    }
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        paddingTop: '56.25%',
        marginTop: '1em'
      }}
    >
      <iframe
        src={convertToEmbedLink(url)}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none'
        }}
      ></iframe>
      <Button
        style={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          minWidth: 'unset',
          borderRadius: '50%',
          border: '1px solid gray',
          background: 'white',
          width: '3em',
          height: '3em'
        }}
        onClick={deleteUrl}
      >
        <ClearIcon color="secondary" style={{ fontSize: '1.5em' }} />
      </Button>
    </div>
  );
};

export default YouTubeEmbed;
