import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';

import PlusOneIcon from '@material-ui/icons/PlusOne';
import { LinkComponent, LoaderBar } from 'src/components';
import { ProductTypeEnum } from 'src/enums';
import {
  AmountSrpChange,
  ChangeSrpPayload,
  GetTransactionDetailResponse,
  GetTransactionsViaTransactionNoResponse,
  Transaction
} from 'src/types';
import { formatCurrency } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from 'src/hooks';
import { colors } from 'src/constants';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import { cloneDeep } from 'lodash';
import { sortProductsByCategory } from 'src/utils/categories/sortByCategory';
import { SplitButtons } from 'src/components/buttons/SplitButtons';
import { SrpChangesDialog } from './SrpChangesDialog';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  addBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  extraInfo: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
}));

interface Props {
  isLoading?: boolean;
  onAddItemPress: () => void;
  transactionInfo?: GetTransactionsViaTransactionNoResponse;
  transactionDetails?: GetTransactionDetailResponse;
  onVoidItem: (data: Transaction) => void;
  onReplaceItemPress: (data: Transaction) => void;
  // setTransactionToRma: (data: Transaction) => void;
  getDeleteTransactionInfo: () => void;
  onChangeSrp: (params?: ChangeSrpPayload) => void;
}

const TransactionsCardWithTable = ({
  isLoading,
  // setTransactionToRma,
  transactionInfo,
  transactionDetails,
  onAddItemPress,
  onVoidItem,
  getDeleteTransactionInfo,
  onChangeSrp,
  // eslint-disable-next-line no-unused-vars
  onReplaceItemPress
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [changeSrp, setChangeSrp] = useState<boolean>(false);
  const [srpValue, setSrpValue] = useState<string | undefined>(
    transactionDetails?.data?.srp_percentage
  );

  const isSrpPercentageChange = useMemo(
    () => srpValue === transactionDetails?.data?.srp_percentage,
    [srpValue, transactionDetails]
  );

  const transactionItems = useMemo(() => {
    const validTransactionItems = transactionInfo?.items
      ?.filter(
        (item) =>
          !(
            (item?.isDeleted === 1 && item?.srp_exempted === 1) ||
            (item?.isDeleted === 0 && item?.srp_exempted === 1) ||
            (item?.isDeleted === 1 && item?.srp_exempted === 0) ||
            (item?.retail_price != null && item?.retail_price <= 0)
          )
      )
      .map((item) => ({
        product_id: item?.product_id,
        product_name: item?.product_name,
        retail_price: item?.retail_price
      }));
    return validTransactionItems;
  }, [transactionInfo]);

  const appliedSrpChangesItems = useMemo(() => {
    const intSrpVal = srpValue
      ? parseFloat(srpValue.replace('%', '')) / 100
      : 1;

    const transformRetailPrice = (price: number) =>
      srpValue === '0%'
        ? price /
          (1 + parseFloat(transactionDetails?.data?.srp_percentage_raw || ''))
        : price * (1 + intSrpVal);
    return transactionItems?.map((item) => {
      // Ensure we only use the fallback if the retail price is undefined or null
      const retailPrice =
        item?.retail_price != null ? item.retail_price : 999999;
      const newRetailPrice = transformRetailPrice(retailPrice);
      return {
        product_name: item?.product_name,
        breakdown: `from ${formatCurrency(retailPrice)} to ${formatCurrency(
          newRetailPrice
        )}`,
        new_retail_price: newRetailPrice
      };
    });
  }, [srpValue, transactionDetails, transactionItems]);

  //computed prev and new total amount
  const appliedTransactionTotalAmountChange: AmountSrpChange = useMemo(() => {
    const baseTotalAmount = transactionItems?.reduce((accumulator, current) => {
      if (current?.retail_price) {
        return accumulator + current?.retail_price;
      }
      return accumulator;
    }, 0);

    const newTotalAmount = appliedSrpChangesItems?.reduce(
      (accumulator, current) => {
        if (current?.new_retail_price) {
          return accumulator + current?.new_retail_price;
        }
        return accumulator;
      },
      0
    );
    return {
      prev_amount: baseTotalAmount,
      new_amount: newTotalAmount
    };
  }, [appliedSrpChangesItems, transactionItems]);

  //sorted values
  const sortedTransactionItems = useMemo(() => {
    if (transactionInfo?.items) {
      const sortedTransactionItems = cloneDeep(transactionInfo?.items) || [];
      return sortProductsByCategory(sortedTransactionItems);
    }
    return [];
  }, [transactionInfo]);

  const quantityStr = useCallback((item: Transaction) => {
    const quantity = item?.quantity || 1;
    if (
      item?.product_type === ProductTypeEnum.Consumable &&
      item?.consumable_unit
    ) {
      return `${quantity} ${item?.consumable_unit}(s)`;
    }
    return `${quantity}`;
  }, []);

  const itemSerialNo = useCallback((item: Transaction) => {
    if (item?.product_type === ProductTypeEnum.Consumable) {
      return '--';
    }
    if (item?.serial_no) {
      return item?.serial_no;
    }
    return '--';
  }, []);

  const onHandleChangeSrp = useCallback(() => {
    if (
      appliedTransactionTotalAmountChange?.prev_amount != null &&
      appliedTransactionTotalAmountChange.prev_amount <= 0
    ) {
      return;
    }
    const srpPercentageToNumber = parseFloat(srpValue ? srpValue : '') / 100;

    onChangeSrp({
      transaction_no: transactionDetails?.data?.transaction_no,
      srp_percentage: srpPercentageToNumber
    });
  }, [
    appliedTransactionTotalAmountChange,
    onChangeSrp,
    srpValue,
    transactionDetails
  ]);

  useEffect(() => {
    setSrpValue(transactionDetails?.data?.srp_percentage);
  }, [transactionDetails]);

  return (
    <div>
      <LoaderBar isLoading={isLoading || false} />
      <Card className={classes.cardContainer}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Quantity</TableCell>
                  {multiBranchFeat ? <TableCell>Branch name</TableCell> : null}
                  <TableCell>Category</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Unit Price</TableCell>
                  <TableCell>Amount</TableCell>
                  {/* TODO: RMA is now deprecated */}
                  {/* <TableCell>RMA</TableCell> */}
                  <TableCell>Voided By</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedTransactionItems?.map((item: Transaction) => (
                  <TableRow
                    hover
                    key={item.id}
                    style={
                      item?.isDeleted ? { backgroundColor: colors.error } : {}
                    }
                  >
                    <TableCell>{quantityStr(item)}</TableCell>
                    {multiBranchFeat ? (
                      <TableCell>
                        <LinkComponent title={`${item?.branch_name ?? '--'}`} />
                      </TableCell>
                    ) : null}
                    <TableCell>
                      <LinkComponent title={`${item?.category_name ?? '--'}`} />
                    </TableCell>
                    <TableCell>
                      <LinkComponent
                        onClick={() =>
                          navigate(`/app/products/${item?.product_id}`)
                        }
                        href={`/app/products/${item?.product_id}`}
                        title={`${item?.product_name ?? '--'}`}
                      />
                      {item.isDeleted ? (
                        <Tooltip title={`Void reason: ${item?.void_reason}`}>
                          <Chip
                            label="Deleted"
                            style={{ height: '100%', marginLeft: 10 }}
                            size="small"
                            variant="outlined"
                            color="primary"
                          />
                        </Tooltip>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {item?.isDeleted ? (
                        itemSerialNo(item)
                      ) : (
                        <LinkComponent
                          onClick={() =>
                            navigate(
                              `/app/products-listings/${item.listing_id}`
                            )
                          }
                          href={`/app/products-listings/${item.listing_id}`}
                          title={itemSerialNo(item)}
                        />
                      )}
                    </TableCell>
                    <TableCell>{formatCurrency(item?.retail_price)}</TableCell>
                    {/* Show retail price if amount is unavailable in table cell below for items with quantity by 
                    meters eg. rj45 w/ quantity 3 meters because retail price is manually set by operations regardless of meters */}
                    <TableCell>
                      {formatCurrency(item?.amount || item?.retail_price)}
                    </TableCell>
                    {/* TODO: RMA is deprecated, remove later */}
                    {/* <TableCell onClick={(e) => e.stopPropagation()}>
                      {item?.product_type === ProductTypeEnum.Consumable ? (
                        'N/A'
                      ) : item?.rma ? (
                        <RmaChip rma={item?.rma} />
                      ) : (
                        <Tooltip title={`Set ${item?.product_name} as RMA`}>
                          <IconButton
                            size="small"
                            disabled={item?.isDeleted ? true : false}
                            onClick={(e) => {
                              e.stopPropagation();
                              setTransactionToRma(item);
                            }}
                          >
                            <SubdirectoryArrowLeftIcon color="secondary" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell> */}

                    <TableCell>
                      {item?.deleted_by_name && (
                        <Tooltip title={item?.deleted_by_name}>
                          <Chip
                            label={item?.deleted_by_name}
                            variant="outlined"
                            style={{ overflow: 'hidden', maxWidth: '120px' }}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <Tooltip title={`Void ${item?.product_name}`}>
                        <IconButton
                          disabled={item?.isDeleted ? true : false}
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            onVoidItem(item);
                            getDeleteTransactionInfo();
                          }}
                        >
                          <BackspaceOutlinedIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip
                        title={`Under Investigation. For now, void and add again`}
                      >
                        <IconButton
                          disabled={true}
                          size="small"
                          // eslint-disable-next-line no-unused-vars
                          onClick={(e) => {
                            // e.stopPropagation();
                            // onReplaceItemPress(item);
                          }}
                        >
                          <CachedIcon color="secondary" />
                        </IconButton>
                      </Tooltip> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className={classes.extraInfo}>
              <Button
                disabled={isLoading}
                startIcon={<PlusOneIcon />}
                onClick={() => onAddItemPress()}
                variant="outlined"
                color="primary"
                className={classes.addBtn}
              >
                Add item in transaction
              </Button>

              <div style={{ marginBottom: 5 }}>
                <SplitButtons
                  initial_val={srpValue}
                  disableButton={
                    isSrpPercentageChange ||
                    (appliedTransactionTotalAmountChange?.prev_amount != null &&
                      appliedTransactionTotalAmountChange.prev_amount <= 0)
                  }
                  title={
                    isSrpPercentageChange ? 'Current SRP Changes' : 'APPLY SRP'
                  }
                  options={['0%', '12%']}
                  onChange={(option?: string) => {
                    setSrpValue(option);
                  }}
                  onApplyClick={() => {
                    setChangeSrp(true);
                  }}
                />
              </div>

              <SrpChangesDialog
                isOpen={changeSrp}
                appliedSrpChangesItem={appliedSrpChangesItems}
                amountSrpChange={appliedTransactionTotalAmountChange}
                title={`Change Transaction SRP Percentage By ${srpValue}`}
                subTitle={
                  'Confirm Breakdown of the following SRP changes for each items in transaction.'
                }
                onHandleConfirmAction={() => {
                  onHandleChangeSrp();
                }}
                onHandleClose={() => setChangeSrp(false)}
              />

              {permissions?.canViewSales ? (
                <Typography color="textSecondary" variant="subtitle2">
                  sub total:{' '}
                  {`${formatCurrency(transactionInfo?.sub_total_price) ??
                    `0.00`}`}
                </Typography>
              ) : null}
              {permissions?.canViewSales ? (
                <Typography color="textSecondary" variant="subtitle2">
                  deducted kachi points:{' '}
                  {`-${formatCurrency(
                    Math.abs(transactionInfo?.kachi_points || 0)
                  )}`}
                </Typography>
              ) : null}
              <Typography variant="h4">
                TOTAL PRICE:{' '}
                {`${formatCurrency(transactionInfo?.total_price) ?? `0.00`}`}
              </Typography>
              {permissions?.canViewSales ? (
                <Typography color="textSecondary" variant="subtitle2">
                  total dealers price:{' '}
                  {`${formatCurrency(transactionInfo?.total_dealers_price) ??
                    `0.00`}`}
                </Typography>
              ) : null}
              {permissions?.canViewSales ? (
                <Typography color="textSecondary" variant="subtitle2">
                  total gross:{' '}
                  {`${formatCurrency(transactionInfo?.total_gross) ?? `0.00`}`}
                </Typography>
              ) : null}
            </div>
          </Box>
        </PerfectScrollbar>
      </Card>
    </div>
  );
};

export const TransactionsCard = React.memo(TransactionsCardWithTable);
