import React, { FC, memo, useEffect, useState } from 'react';
import {
  Box,
  makeStyles,
  Card,
  Typography,
  CardActionArea,
  LinearProgress,
  IconButton
} from '@material-ui/core';
import { useBlogCategory } from 'src/hooks/blog-category';
import { Pagination } from '@material-ui/lab';
import { BlogCategoryForm } from './BlogCategoryForm';
import { CreateBlogCategoryPayload } from 'src/redux/slices/blog-category';
import DeleteIcon from '@material-ui/icons/Delete';
import { DecisionDialog } from 'src/components';
import { usePermissions } from 'src/hooks';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem'
  },
  card: {
    marginBottom: 3,
    marginTop: 3
  },
  cardAction: {
    padding: '1rem'
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

interface Props {}

const component: FC<Props> = () => {
  const { canDeleteBlogCategory, canUpdateBlogCategory } = usePermissions();
  const {
    isSuccess,
    blogCategorytListLoading,
    blogCategoryList,
    blogCategoryListMeta,
    blogCategoryDetails,
    getBlogCategoryList,
    getBlogCategoryDetail,
    updateBlogCategory,
    deleteBlogCategory,
    setBlogCategoryDetails
  } = useBlogCategory();
  const classes = useStyles();

  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [blogId, setIsBlogId] = useState<number>();

  const onChangePage = (pageNum: number) => {
    const params = { page: pageNum };
    getBlogCategoryList(params);
  };

  const onHandleBlogCategoryDetails = (blog_id?: number) => {
    if (canUpdateBlogCategory) {
      setIsOpenForm(true);
      getBlogCategoryDetail(blog_id || 0);
      setIsBlogId(blog_id);
    }
  };

  const onHandleDeleteBlogCategory = (blog_id?: number) => {
    setIsBlogId(blog_id);
    setIsDelete(true);
  };

  const onHandleClose = () => {
    setBlogCategoryDetails({});
    setIsOpenForm(false);
  };

  useEffect(() => {
    const params = { page: 1 };
    getBlogCategoryList(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setIsOpenForm(false);
    }
  }, [isSuccess]);

  return (
    <Box className={classes.root}>
      {blogCategorytListLoading && <LinearProgress />}
      {blogCategoryList?.map((blogCategory, index) => (
        <Card key={index} className={classes?.card}>
          <CardActionArea
            className={classes?.cardAction}
            onClick={() => onHandleBlogCategoryDetails(blogCategory?.id)}
          >
            <Box className={classes.box}>
              <Typography>{blogCategory?.name}</Typography>

              {canDeleteBlogCategory && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onHandleDeleteBlogCategory(blogCategory?.id);
                  }}
                >
                  <DeleteIcon color="secondary" />
                </IconButton>
              )}
            </Box>
          </CardActionArea>
        </Card>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={1}>
        {blogCategoryList && blogCategoryList?.length > 0 && (
          <Pagination
            count={blogCategoryListMeta?.last_page}
            page={blogCategoryListMeta?.current_page}
            onChange={(e, pageNumber) => onChangePage(pageNumber)}
          />
        )}
      </Box>

      <BlogCategoryForm
        isOpen={isOpenForm}
        action="update"
        blogCategoryDetailsData={blogCategoryDetails}
        handleClose={onHandleClose}
        onHandleSubmit={(values: CreateBlogCategoryPayload) => {
          updateBlogCategory({ ...values, blog_id: blogId });
        }}
      />
      {isDelete && (
        <DecisionDialog
          isOpen={isDelete}
          title="Delete Blog Category"
          subTitle={`Are you sure you want to delete blog category`}
          onHandleConfirmAction={() => deleteBlogCategory(blogId)}
          onHandleClose={() => setIsDelete(false)}
        />
      )}
    </Box>
  );
};

export const BlogCategoryList = memo(component);
