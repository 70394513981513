import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select
} from '@material-ui/core';

import React, { useEffect, useMemo, useState } from 'react';
import useUserInfo from 'src/hooks/user/use-user-info';

interface Props {
  branchLabel?: string;
  disabled?: boolean;
  allowNoSelectedBranch?: boolean;
  multiple?: boolean;
  defaultValue?: number[];
  paperSpace?: string | number;
  //this will return branch ids
  onHandleBranchChange: (branchIds?: number[]) => void;
  onHandleFocus?: () => void;
  isElevated?: boolean;
}

const component = ({
  defaultValue,
  branchLabel = 'Branch',
  allowNoSelectedBranch = true,
  disabled = false,
  multiple = false,
  paperSpace = '10px',
  onHandleBranchChange,
  onHandleFocus,
  isElevated = true
}: Props) => {
  const { userBranchOptions } = useUserInfo();
  const [noSelectedBranch, setNoSelectedBranch] = useState<boolean>(false);

  const defaultValueBranch = useMemo(() => {
    let branchNames: string[] = [];

    if (defaultValue) {
      branchNames = userBranchOptions
        .filter((branch) => defaultValue.includes(branch.value))
        .map((branch) => branch.name);
    }

    return branchNames;
  }, [defaultValue, userBranchOptions]);

  const [selectedBranchName, setSelectedBranchName] = useState<string[]>(
    defaultValueBranch || []
  );

  const handleChange = (event?: React.ChangeEvent<{ value: any }>) => {
    const selectedValue = event?.target.value;

    const branchIds = userBranchOptions
      .filter((branch) => selectedValue.includes(branch.name))
      .map((branch) => branch.value);

    //We are sure about the string
    if (selectedValue === `No Selected Branch` && allowNoSelectedBranch) {
      setNoSelectedBranch(true);
    } else {
      setNoSelectedBranch(false);
    }

    setSelectedBranchName(selectedValue);
    onHandleBranchChange(branchIds);
  };

  useEffect(() => {
    setSelectedBranchName(defaultValueBranch);
  }, [defaultValueBranch]);

  const RenderBranchDropdown = () => {
    if (multiple) {
      return (
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="branch-checkbox-label">Branch</InputLabel>
          <Select
            disabled={disabled}
            labelId="branch-checkbox-label"
            id="branch-checkbox"
            multiple
            value={selectedBranchName}
            onChange={handleChange}
            onFocus={onHandleFocus}
            renderValue={(selected: any) => selected.join(', ')}
            label="Branch"
          >
            {userBranchOptions
              ? userBranchOptions?.map((options) => (
                  <MenuItem key={options?.value} value={options?.name}>
                    <Checkbox
                      checked={selectedBranchName.includes(options?.name)}
                    />
                    {options?.name}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <Paper style={{ padding: paperSpace }} elevation={isElevated ? 1 : 0}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="branch-dropdown">{branchLabel}</InputLabel>
            <Select
              disabled={disabled}
              labelId="branch-dropdown"
              id="dropdown-select"
              value={selectedBranchName}
              onChange={handleChange}
              label="Branch Receiver"
              renderValue={(selected: any) => selected}
              //optionals
              onFocus={onHandleFocus}
            >
              {allowNoSelectedBranch && (
                <MenuItem value={'No Selected Branch'}>
                  <Radio checked={noSelectedBranch} />
                  {'No Selected Branch'}
                </MenuItem>
              )}

              {userBranchOptions
                ? userBranchOptions?.map((options) => (
                    <MenuItem key={options.value} value={options?.name}>
                      <Radio
                        checked={selectedBranchName.includes(options.name)}
                      />
                      {options?.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Paper>
      );
    }
  };

  return <div>{userBranchOptions ? <RenderBranchDropdown /> : null}</div>;
};
//TODO:remove on develop
export const BranchListDropDown = React.memo(component);
