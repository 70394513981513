import React, { FC, useCallback, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  FormControl,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { ListingStatusEnum } from 'src/enums';
import DeleteIcon from '@material-ui/icons/Delete';
import { usePermissions, useSnackBar } from 'src/hooks';
import useUserInfo from 'src/hooks/user/use-user-info';
import { slices, useAppDispatch } from 'src/redux';
import {
  ErrorSerialNoList,
  GetListingViaSerialRequest,
  SwapSNTransaction
} from 'src/types';
import { TransactListError } from '../../components/TransactListError';
import { debounce } from 'lodash';
import { DecisionDialog } from 'src/components';
import { cleanSN } from 'src/utils';

const { actions: listingActions } = slices.listing;
const { actions: transactionActions } = slices.transaction;

type CustomTransaction = {
  old_serial?: string;
  new_serial?: string;
  product_id?: number;
  product_name?: string;
};

interface Props {
  transactionItems: SwapSNTransaction[];
  transactionNo?: string;
  getTransactionInfo: () => void;
}

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: '1em'
  }
}));

export const SwapSerialNoTempList: FC<Props> = ({
  transactionItems,
  transactionNo,
  getTransactionInfo
}) => {
  const classes = useStyles();
  const { canReplaceTransactionSerial } = usePermissions();
  const { allBranchIDs } = useUserInfo();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const serialNoTextFieldRef = useRef<any>(null);

  const [errorSerialNos, setErrorSerialNos] = useState<ErrorSerialNoList>([]);
  const [tempList, setTempList] = useState<CustomTransaction[]>([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [changeSerialLoading, setChangeSerialLoading] = useState<boolean>(
    false
  );

  const handleOpenConfirm = () => {
    if (errorSerialNos[0]) {
      snackBar.show({
        severity: 'error',
        message: 'Please retry/clear error items',
        useSound: true
      });
    } else {
      toggleConfirmDialog();
    }
  };

  const toggleConfirmDialog = () => {
    if (changeSerialLoading) {
      return;
    }
    setIsConfirmOpen(!isConfirmOpen);
  };

  const changeTransactionSerial = useCallback(() => {
    setChangeSerialLoading(true);
    if (tempList?.[0] && transactionNo) {
      tempList.forEach(async (i) => {
        if (i?.new_serial && i?.old_serial) {
          const res = unwrapResult(
            await dispatch(
              transactionActions.changeTransactionSerialThunk({
                transaction_no: transactionNo,
                // i believe cleanSN here is uneeded but included for assurance
                current_serial_no: cleanSN(i.old_serial),
                new_serial_no: cleanSN(i.new_serial)
              })
            )
          );
          if (!res.success) {
            setChangeSerialLoading(false);
            snackBar.show({
              severity: 'error',
              message: 'Failed to change serial',
              useSound: true
            });
            setIsConfirmOpen(false);
            return;
          } else {
            setChangeSerialLoading(false);
            setIsConfirmOpen(false);
            setTempList([]);
            getTransactionInfo();
            snackBar.show({
              severity: 'success',
              message: 'Replaced serial no(s) successfully',
              useSound: true
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempList, transactionNo]);

  const fetchListingViaParam = async (val: GetListingViaSerialRequest) => {
    const response = unwrapResult(
      await dispatch(listingActions.getListingViaSNThunk(val))
    );
    return response;
  };

  const handleError = (sn: string, message: string) => {
    const itemAlreadyExistInErrSerialNos = errorSerialNos.find(
      (x) => x?.serial_no === sn
    );

    if (itemAlreadyExistInErrSerialNos) {
      setErrorSerialNos((prev) => {
        const newErrorSerialNos = prev.map((x) => {
          if (x?.serial_no === sn) {
            return { ...x, message: message };
          }
          return x;
        });
        return newErrorSerialNos;
      });
      return;
    }

    setErrorSerialNos((prev) => [...prev, { serial_no: sn, message: message }]);
  };

  const onClearAll = () => {
    setErrorSerialNos([]);
  };

  const onResyncErrorItem = debounce((index: number) => {
    const errorItem = errorSerialNos?.[index];
    addTransactionSerial(errorItem?.serial_no ?? '');
  }, 500);

  const onRemoveErrorItem = (index: number) => {
    setErrorSerialNos((prev) => prev.filter((_, i) => i !== index));
  };

  const addTransactionSerial = useCallback(
    async (sn: string, branchIDs = allBranchIDs) => {
      const cleanSerial = cleanSN(sn);
      let message = '';
      if (!cleanSerial) {
        snackBar.show({
          severity: 'error',
          message: 'Invalid Serial',
          useSound: true
        });
        return;
      }

      if (tempList.some((i) => i.new_serial === cleanSerial)) {
        snackBar.show({
          severity: 'error',
          message: 'Serial is already listed',
          useSound: true
        });
        return;
      }

      const response = await fetchListingViaParam({
        serial_no: cleanSerial,
        branch_ids: branchIDs
      });

      if (!response.success) {
        message = response.message;
        snackBar.show({
          severity: 'error',
          message: message,
          useSound: true
        });

        handleError(cleanSerial, message);
        return;
      }

      const listing = response?.originalData?.listing;

      if (listing?.status !== ListingStatusEnum.Available) {
        message = 'Product not available';
        snackBar.show({
          severity: 'error',
          message: message,
          useSound: true
        });
        handleError(cleanSerial, message);
        return;
      }

      const oldEquivalent = transactionItems.find(
        (i) =>
          i?.product_id === listing?.product_id &&
          i?.branch_id === listing?.branch_id &&
          !i?.isDeleted &&
          i?.serial_no &&
          !tempList.map((i) => i.old_serial).includes(i.serial_no)
      );

      if (!oldEquivalent) {
        message = 'No similar products found in this transaction';
        snackBar.show({
          severity: 'error',
          message: message,
          useSound: true
        });
        handleError(cleanSerial, message);
        return;
      } else if (listing?.serial_no) {
        let newItem: CustomTransaction = {
          old_serial: oldEquivalent.serial_no,
          new_serial: listing.serial_no,
          product_id: listing.product_id,
          product_name: listing.product_name
        };

        setTempList([...tempList, newItem]);
        snackBar.show({
          severity: 'success',
          message: 'Product added to list',
          useSound: true
        });
        setErrorSerialNos((prev) =>
          prev?.filter((i) => i?.serial_no !== newItem.new_serial)
        );
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allBranchIDs, snackBar, tempList, transactionItems]
  );

  const keyPress = (e: any) => {
    if (!serialNoTextFieldRef.current) {
      return;
    }

    if (e.key === 'Enter') {
      let sn = cleanSN(e.target.value);
      addTransactionSerial(sn);
      if (serialNoTextFieldRef?.current) {
        serialNoTextFieldRef.current.value = '';
        serialNoTextFieldRef.current.focus();
      }
    }
  };

  const onDeletePayment = (val: CustomTransaction) => {
    setTempList(tempList.filter((i) => i.new_serial !== val.new_serial));
  };

  return canReplaceTransactionSerial ? (
    <Card className={classes.cardContainer}>
      <FormControl
        variant="outlined"
        style={{
          width: '200px',
          marginRight: '1em',
          marginBottom: '1em'
        }}
      >
        <Typography
          display="inline"
          variant="h5"
          style={{ marginBottom: '1em' }}
        >
          Change Transaction Serial
        </Typography>
        <TextField
          inputRef={serialNoTextFieldRef}
          placeholder="Serial No."
          onKeyDown={keyPress}
          variant="outlined"
        />
      </FormControl>

      <TransactListError
        onClearAll={onClearAll}
        onRetry={onResyncErrorItem}
        onRemove={onRemoveErrorItem}
        transactionErrorList={errorSerialNos}
      />

      {tempList[0] && (
        <>
          <Card style={{ marginTop: '1em' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Old Serial</TableCell>
                  <TableCell>New Serial</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tempList.map((i) => (
                  <TableRow key={i.new_serial}>
                    <TableCell>{i.product_name}</TableCell>
                    <TableCell>
                      <s>{i.old_serial}</s>
                    </TableCell>
                    <TableCell>{i.new_serial}</TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeletePayment(i);
                        }}
                      >
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1em'
            }}
          >
            <Button
              onClick={handleOpenConfirm}
              color="primary"
              variant="contained"
            >
              Replace Transactions Serial
            </Button>
          </Box>
        </>
      )}
      <DecisionDialog
        isOpen={isConfirmOpen}
        title="Confirm Serial Replace"
        subTitle={`Cannot undo Transaction Serial Replacement. Proceed anyway?`}
        onHandleConfirmAction={changeTransactionSerial}
        onHandleClose={toggleConfirmDialog}
      />
    </Card>
  ) : null;
};
