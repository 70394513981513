import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Page } from 'src/components';
import AddIconAdornment from './components/AddIconAdornment';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import useUserInfo from 'src/hooks/user/use-user-info';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  BundleComponent,
  ComponentGroup,
  ComponentGroupSummary,
  PrintComponentDetails
} from 'src/redux/slices/pc-bundles/types';
import { ListingStatusEnum } from 'src/enums';
import { copyToClipboard, formatCurrency } from 'src/utils';
import PrintIcon from '@material-ui/icons/Print';
import { useSnackBar } from 'src/hooks';
import DeleteIcon from '@material-ui/icons/Delete';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useErrDialog } from 'src/hooks/use-error-dialog';
import { ErrorDialog } from 'src/components/error/ErrorDialog';
import { Alert } from '@material-ui/lab';
import { ComponentGroupPrintModal } from './components/ComponentGroupPrintModal';
import { FixMeLater, SnackBarShow } from 'src/types';
import QRCode from 'qrcode';

interface Props {}

const { actions: listingActions } = slices.listing;
const { actions: productActions } = slices.product;
const { actions: pcBundleActions } = slices.pcBundles;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    height: '90vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  componentsListHeader: { fontSize: '.7em', color: '#7D879C' },
  mainGridItem: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 .5em'
  }
}));

const EditBundleComponents: FC<Props> = () => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const location: FixMeLater = useLocation();
  const { userDetails } = useUserInfo();

  const { id: bundleID } = useParams();
  const {
    errs,
    setErrs,
    showErr,
    setShowErr,
    handleErrorFromResponse
  } = useErrDialog();
  const dispatch = useAppDispatch();
  const { allBranchIDs } = useUserInfo();
  const defaultErrorSnackbar: SnackBarShow = {
    severity: 'error',
    message: 'Failed to save group, re-check data or try again later',
    useSound: true
  };

  const [serialVal, setSerialVal] = useState<string>('');
  const [groupSerial, setGroupSerial] = useState<string>('');
  const [groupSerialQR, setGroupSerialQR] = useState<string>('');
  const [errSerial, setErrSerial] = useState<string>('');
  const [isItemCore, setIsItemCore] = useState<boolean>(false);
  // todo: use in future pr
  // const [isBundleUnpublished, setIsBundleUnpublished] = useState<boolean>(true);
  const [componentsList, setComponentsList] = useState<BundleComponent[]>([]);
  const [groupList, setGroupList] = useState<ComponentGroupSummary[]>([]);
  const [groupID, setGroupID] = useState<number | null>(null);
  const [
    currentGroupData,
    setCurrentGroupData
  ] = useState<ComponentGroup | null>(null);
  const [groupName, setGroupName] = useState<string>('');
  const [errGroupName, setErrGroupName] = useState<string>('');
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const [isPrintOpen, setIsPrintOpen] = useState<boolean>(false);
  const [printComponentDetails, setPrintComponentDetails] = useState<
    PrintComponentDetails
  >();

  const employeeName = useMemo(() => {
    const name = `${userDetails?.first_name} ${userDetails?.last_name}`;
    return name;
  }, [userDetails]);

  const brand = useMemo(() => {
    const brand = location.state.brand;
    if (brand) {
      return brand?.replace(/powered by/i, '').trim();
    }
  }, [location]);

  const title = useMemo(() => {
    const title = location.state.build_name;
    return title || '';
  }, [location]);

  // for computing total amount locally
  const localTotalAmount = useMemo(() => {
    return componentsList.reduce(
      (total, component) => total + (component?.retail_price || 0),
      0
    );
  }, [componentsList]);

  const purchaseStatus = (val: boolean) => {
    return {
      status: val ? 'Purchased' : 'Available',
      color: val ? '#E94560' : '#219653'
    };
  };

  const onCopySerialNo = (e: any, a: string) => {
    e.stopPropagation();
    const copySerialNumber = `${a}`;
    copyToClipboard(copySerialNumber);
  };

  const formatComponent = (val: BundleComponent) => {
    const {
      listing_id,
      serial_no,
      product_id,
      category_id,
      is_core_component
    } = val;

    return {
      listing_id,
      serial_no,
      product_id,
      category_id,
      is_core_component
    };
  };

  const getComponentGroups = useCallback(async () => {
    if (!bundleID) {
      return;
    }

    const res = unwrapResult(
      await dispatch(
        pcBundleActions.getComponentGroupsThunk({ pc_bundle_id: +bundleID })
      )
    );

    if (res.success) {
      setGroupList(res.originalData.data);
    } else {
      console.error('failed to get component groups');
    }
  }, [bundleID, dispatch]);

  const resetState = useCallback(() => {
    setGroupSerial('');
    setComponentsList([]);
    setGroupName('');
    setCurrentGroupData(null);
    setGroupID(null);
  }, []);

  const onSaveComponentGroup = () => {
    groupSerial ? onUpdateComponentGroup() : onCreateComponentGroup();
  };

  const onUpdateComponentGroup = useCallback(async () => {
    window.alert('onUpdateComponentGroup');
    if (!bundleID || !groupID) {
      snackBar.show(defaultErrorSnackbar);
      return;
    }

    if (!componentsList[0]) {
      snackBar.show({
        severity: 'error',
        message: 'Productst cannot be empty',
        useSound: true
      });
      return;
    }

    const currentName = currentGroupData?.name;
    const currentComponents = JSON.stringify(currentGroupData?.components);

    if (currentName && currentComponents) {
      const hasDifferentName = currentName !== groupName;
      const hasDifferentComponents =
        currentComponents !== JSON.stringify(componentsList);

      const res = unwrapResult(
        await dispatch(
          pcBundleActions.updateComponentGroupThunk({
            group_id: groupID,
            pc_bundle_id: +bundleID,
            ...(hasDifferentName && { group_name: groupName }),
            ...(hasDifferentComponents && {
              products: componentsList.map((i) => formatComponent(i))
            })
          })
        )
      );

      if (res.success) {
        snackBar.show({
          severity: 'success',
          useSound: true,
          message: 'Component group saved'
        });
        setErrs([]);
        resetState();
        getComponentGroups();
      } else if (res.errors) {
        snackBar.show(defaultErrorSnackbar);
        setShowErr(true);
        handleErrorFromResponse(res);
      } else {
        snackBar.show(defaultErrorSnackbar);
      }
    } else {
      // if there are no changes
      snackBar.show({
        severity: 'error',
        useSound: true,
        message: 'No changes to save'
      });
    }
  }, [
    bundleID,
    componentsList,
    currentGroupData,
    defaultErrorSnackbar,
    dispatch,
    getComponentGroups,
    groupID,
    groupName,
    handleErrorFromResponse,
    resetState,
    setErrs,
    setShowErr,
    snackBar
  ]);

  const onCreateComponentGroup = useCallback(async () => {
    if (!bundleID) {
      return;
    }

    if (!groupName) {
      setErrGroupName('Invalid Name');
      snackBar.show({
        severity: 'error',
        message: 'Invalid Name',
        useSound: true
      });
      return;
    }

    const res = unwrapResult(
      await dispatch(
        pcBundleActions.createComponentGroupThunk({
          pc_bundle_id: +bundleID,
          group_name: groupName,
          products: componentsList.map((i) => formatComponent(i))
        })
      )
    );

    if (res.success) {
      snackBar.show({
        severity: 'success',
        useSound: true,
        message: 'Component group saved'
      });
      setErrs([]);
      resetState();
      getComponentGroups();
    } else if (res.errors) {
      snackBar.show(defaultErrorSnackbar);
      setShowErr(true);
      handleErrorFromResponse(res);
    } else {
      snackBar.show(defaultErrorSnackbar);
    }
  }, [
    bundleID,
    componentsList,
    defaultErrorSnackbar,
    dispatch,
    getComponentGroups,
    groupName,
    handleErrorFromResponse,
    resetState,
    setErrs,
    setShowErr,
    snackBar
  ]);

  const generateQRCode = useCallback((val: string) => {
    try {
      if (val) {
        QRCode.toDataURL(String(val), {
          margin: 0.5,
          errorCorrectionLevel: 'H'
        })
          .then((url) => setGroupSerialQR(url))
          .catch((err) => console.error(err));
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onListingCoreToggle = useCallback(
    (val: boolean, serial?: string) => {
      if (!serial) {
        return;
      }

      let updatedComponents = [...componentsList];
      const updateIndex = updatedComponents.findIndex(
        (i) => i.serial_no === serial
      );

      const newItem: BundleComponent = {
        ...componentsList[updateIndex],
        is_core_component: !val
      };
      updatedComponents.splice(updateIndex, 1, newItem);
      // TODO: adjustment for future once api is available
      setComponentsList(updatedComponents);
    },
    [componentsList]
  );

  const onDeleteComponent = useCallback(
    (val: BundleComponent) => {
      const { serial_no } = val;
      let updatedComponents = [...componentsList];

      if (!serial_no) {
        return;
      }

      const deleteIndex = componentsList.findIndex(
        (i) => i.serial_no === serial_no
      );
      updatedComponents.splice(deleteIndex, 1);

      if (deleteIndex >= 0) {
        setComponentsList(updatedComponents);
      }
    },
    [componentsList]
  );

  const addSerial = useCallback(async () => {
    let err = '';
    let trimSerial = serialVal.trim();

    if (!trimSerial) {
      setErrSerial('Invalid Serial Value');
      return;
    }

    const response = unwrapResult(
      await dispatch(
        listingActions.getListingViaSNThunk({
          serial_no: serialVal,
          branch_ids: allBranchIDs
        })
      )
    );

    const listing = response.originalData.listing;

    if (listing) {
      if (componentsList.find((i) => i.serial_no === trimSerial)) {
        // Check if serial is on list
        err = 'Serial already on list';
      } else if (
        listing.status !== ListingStatusEnum.Available &&
        listing.status !== ListingStatusEnum.OnHold
      ) {
        // Check if serial is available
        err = 'Serial not available';
      }

      if (err) {
        setErrSerial(err);
        return;
      }

      const {
        retail_price,
        product_name: product,
        category_name: category,
        product_id,
        category_id
      } = listing;

      if (!product_id) {
        console.error('missing product id');
        return;
      }

      // for getting listing id
      const response2 = unwrapResult(
        await dispatch(
          productActions.getProductListingUpdateHistoryThunk(serialVal)
        )
      );

      if (!response2.success) {
        console.error('error getting listing id');
        return;
      }

      const updatedComponents = [
        ...componentsList,
        {
          serial_no: serialVal,
          is_core_component: isItemCore,
          product,
          category,
          retail_price,
          category_id,
          product_id,
          listing_id: response2.originalData.data?.[0].listing_id
        }
      ];

      setComponentsList(updatedComponents);
      setSerialVal('');
    } else {
      setErrSerial(response.message);
      return;
    }
  }, [allBranchIDs, dispatch, isItemCore, componentsList, serialVal]);

  const onPrintGroup = useCallback(
    async (val: ComponentGroupSummary) => {
      const { id, group_name, serial_no } = val;
      if (!id) {
        console.error('bundle group id error');
        return;
      }
      setIsPrintOpen(true);

      const res = unwrapResult(
        await dispatch(pcBundleActions.getComponentGroupDetailsThunk(id))
      );

      const groupDetails = res.originalData.data;
      const newTotal = res.originalData.data.total_amount;
      const currentDate = new Date();
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      const formattedDate = currentDate.toLocaleString('en-PH', options);

      // ensure all data required is complete
      if (res.success && newTotal) {
        generateQRCode(val?.serial_no || '');
        const printDetails: PrintComponentDetails = {
          group_name,
          serial_no,
          components: groupDetails.serial_nos,
          title,
          brand,
          prepared_by: employeeName,
          total_amount: newTotal,
          time_stamp: formattedDate
        };
        setPrintComponentDetails(printDetails);
      } else {
        snackBar.show({
          severity: 'error',
          useSound: true,
          message: 'Something went wrong, please try again'
        });
      }
    },
    [brand, dispatch, employeeName, generateQRCode, snackBar, title]
  );

  const onClickGroup = useCallback(
    async (val: ComponentGroupSummary) => {
      const { id, group_name, serial_no } = val;
      if (!id) {
        console.error('bundle group id error');
        return;
      }

      // do nothing if serial is already selected
      if (serial_no === groupSerial) {
        return;
      }

      const res = unwrapResult(
        await dispatch(pcBundleActions.getComponentGroupDetailsThunk(id))
      );

      const groupDetails = res.originalData.data;
      const newTotal = res.originalData.data.total_amount;

      if (res.success && newTotal) {
        // ensure all data required is complete
        resetState(); // reset states before setting data to prevent serial duplication bug
        setGroupName(group_name || '');
        setErrGroupName('');
        setGroupSerial(serial_no || '');
        setComponentsList(groupDetails.serial_nos || []);
        setTotalAmount(newTotal);
        setCurrentGroupData({
          serial_no: group_name || '',
          name: serial_no || '',
          components: groupDetails.serial_nos || []
        });
      } else {
        snackBar.show({
          severity: 'error',
          useSound: true,
          message: 'Something went wrong, please try again'
        });
      }
    },
    [dispatch, groupSerial, resetState, snackBar]
  );

  useEffect(() => {
    groupSerial && generateQRCode(groupSerial);
  }, [generateQRCode, groupSerial]);

  useEffect(() => {
    getComponentGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Will compute total locally if current group details isnt from api response
  useEffect(() => {
    !groupSerial && setTotalAmount(localTotalAmount);
  }, [componentsList, groupSerial, localTotalAmount]);

  return (
    <Page className={classes.root} title="Core Components">
      <ErrorDialog
        open={showErr}
        errs={errs}
        closeDialog={() => setShowErr(false)}
      />
      <ComponentGroupPrintModal
        isOpen={isPrintOpen}
        printDetails={printComponentDetails}
        qrSrc={groupSerialQR}
        toggleDialogOpen={() => setIsPrintOpen(false)}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 1em'
        }}
      >
        <Typography variant="h3">CORE COMPONENTS</Typography>
        <Button
          color="secondary"
          variant="outlined"
          style={{ minWidth: '200px' }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Box>

      {groupSerial && (
        <Box
          style={{
            display: 'flex',
            padding: '1em 1em 0 1em',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Alert
            severity="info"
            style={{ flexGrow: 1, margin: '1em 1em 0 1em' }}
          >
            <FormHelperText>
              View only mode for now, cannot edit existing component groups
            </FormHelperText>
          </Alert>
          <img src={groupSerialQR} alt="Group Serial QR" height="60px" />
        </Box>
      )}
      <Grid
        id="main-container"
        container
        style={{ padding: '.5em 1em 1em 0em', height: '90%' }}
      >
        <Grid item xs={6} className={classes.mainGridItem}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>Scan Product Serial No.</Typography>
            <Checkbox
              // TODO: change all disabled condition to bundle.status == unpublished in the future
              disabled={!!groupSerial}
              checked={isItemCore}
              onClick={() => setIsItemCore(!isItemCore)}
              color="primary"
            />
            <Typography>Core Component</Typography>
          </Box>
          <TextField
            style={{ height: '5em' }}
            disabled={!!groupSerial}
            value={serialVal}
            error={!!errSerial}
            helperText={errSerial}
            fullWidth
            variant="outlined"
            placeholder="Scan serial no"
            onChange={(e) => {
              setErrSerial('');
              setSerialVal(String(e.target.value));
            }}
            onKeyDown={(e) => e.key === 'Enter' && addSerial()}
            InputProps={{
              endAdornment: <AddIconAdornment onClick={addSerial} />
            }}
          />

          <Box
            className="grow-box"
            style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: 0, // needed to not make box overflow from grid item
              flexGrow: '1'
            }}
          >
            <Typography>Component Group</Typography>
            <Card style={{ flexGrow: 1, display: 'flex' }}>
              <CardContent
                className="scrollableList"
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {/* Box here for scroll cause card content is buggy with scroll */}
                <Box style={{ height: '100%', overflowY: 'auto' }}>
                  {groupList?.[0] &&
                    groupList.map((i) => (
                      <Tooltip
                        key={i.serial_no}
                        onClick={() => onClickGroup(i)}
                        title="See assigned serial nos."
                      >
                        <Card style={{ marginTop: '1em', cursor: 'pointer' }}>
                          <CardContent
                            style={{
                              display: 'flex',
                              padding: '1em',
                              alignItems: 'center'
                            }}
                          >
                            <Box
                              style={{
                                width: '100%',
                                justifyContent: 'space-around',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: 'bold',
                                  width: '14em',
                                  fontSize: '1.2em'
                                }}
                              >
                                {i.group_name}
                              </Typography>
                              <Typography
                                style={{
                                  width: '5em',
                                  textAlign: 'center',
                                  color: purchaseStatus(!!i.purchased_at).color
                                }}
                              >
                                {purchaseStatus(!!i.purchased_at).status}
                              </Typography>
                              <Box
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  columnGap: '1em'
                                }}
                              >
                                <FileCopyIcon
                                  style={{ color: '#7D879C' }}
                                  onClick={(e) =>
                                    onCopySerialNo(e, i.serial_no || '')
                                  }
                                />
                                <Typography
                                  style={{
                                    textAlign: 'center',
                                    width: '14em',
                                    color: '#7D879C'
                                  }}
                                >
                                  {i.serial_no}
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              onClick={() => onPrintGroup(i)}
                              style={{
                                flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'end'
                              }}
                            >
                              <PrintIcon style={{ color: '#7D879C' }} />
                            </Box>
                          </CardContent>
                        </Card>
                      </Tooltip>
                    ))}
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.mainGridItem}>
          <Box
            style={{ height: '41.98px', display: 'flex', alignItems: 'center' }}
          >
            <Typography>Components</Typography>
          </Box>
          <Card style={{ flexGrow: '1' }} className="grow-box">
            <CardContent
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  minHeight: '55px'
                }}
              >
                <TextField
                  disabled={!!groupSerial}
                  value={groupName}
                  error={!!errGroupName}
                  helperText={errGroupName}
                  onChange={(e) => {
                    setGroupName(String(e.target.value));
                    setErrGroupName('');
                  }}
                  placeholder="Enter Group Name"
                  InputProps={{
                    style: { fontWeight: 'bold' },
                    endAdornment: <CreateOutlinedIcon />
                  }}
                />
                {groupSerial && (
                  <Typography style={{ color: '#7D879C' }}>
                    {groupSerial}
                  </Typography>
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '1em'
                }}
              >
                <Typography className={classes.componentsListHeader}>
                  Products
                </Typography>
                <Typography className={classes.componentsListHeader}>
                  Core Component
                </Typography>
              </Box>
              <Box
                id="listings"
                className="scrollableList"
                style={{
                  flexGrow: 1,
                  overflowY: 'auto',
                  minHeight: 0
                }}
              >
                {componentsList?.[0] &&
                  componentsList.map((i) => (
                    <Grid
                      container
                      key={i.product_name}
                      style={{ marginTop: '1em' }}
                    >
                      <Grid item xs={9}>
                        <Typography variant="h6">
                          {i?.serial_no ?? ''}
                        </Typography>
                        <Typography
                          style={{
                            whiteSpace: 'nowrap', // Prevent text from wrapping
                            overflow: 'hidden', // Hide the overflowing text
                            textOverflow: 'ellipsis', // Show ellipsis for overflow
                            fontWeight: 'bold',
                            fontSize: '.7em'
                          }}
                        >
                          {i.product ?? i.product_name ?? ''}
                        </Typography>
                        <Typography variant="h6" style={{ fontSize: '.7em' }}>
                          {formatCurrency(i.retail_price)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          alignItems: 'start',
                          justifyContent: 'end'
                        }}
                        xs
                      >
                        <DeleteIcon
                          color="secondary"
                          onClick={() => onDeleteComponent(i)}
                          style={{display: groupSerial ? 'none': 'block' }} 
                        />
                        <Checkbox
                          style={{
                            margin: '0 .8em',
                            height: '.7em'
                          }}
                          disabled={!!groupSerial}
                          checked={!!i?.is_core_component}
                          onClick={() =>
                            onListingCoreToggle(
                              !!i?.is_core_component,
                              i?.serial_no
                            )
                          }
                          color="primary"
                        />
                      </Grid>
                    </Grid>
                  ))}
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'end' }}>
                <Box
                  style={{
                    marginTop: '1em',
                    display: 'flex',
                    alignItems: 'end'
                  }}
                >
                  <Typography style={{ fontSize: '.8em', fontWeight: 'bold' }}>
                    Total Amount:
                  </Typography>
                  <Typography
                    color="secondary"
                    style={{
                      marginLeft: '1em',
                      fontWeight: 'bold'
                    }}
                  >
                    {formatCurrency(totalAmount)}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box
        style={{
          width: '100%',
          paddingRight: '1.2em',
          display: 'flex',
          justifyContent: 'end',
          columnGap: '1em'
        }}
      >
        <Button
          onClick={resetState}
          style={{ width: '241px' }}
          color="primary"
          variant="contained"
        >
          Create New Group
        </Button>
        <Button
          disabled={!!groupSerial}
          onClick={onSaveComponentGroup}
          style={{ width: '241px' }}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </Page>
  );
};
export default EditBundleComponents;
