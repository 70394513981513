/* eslint-disable no-unused-vars */
// TODO: remove later
import React, { FC, useCallback, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';

interface Props {
  title: string;
  uploadUrl: (url: string) => void;
}

const UploadYtLink: FC<Props> = ({ title, uploadUrl }) => {
  const [url, setUrl] = useState<string>('');
  const [errUrl, setErrUrl] = useState<string>('');

  const handleUpload = useCallback(() => {
    const youtubeRegex = /^(https?:\/\/)?(www\.youtube\.com|youtu\.be)\/(watch\?v=|shorts\/)?\S*$/;
    let trimStr = url.trim();
    let err = '';

    if (youtubeRegex.test(trimStr)) {
      if (trimStr.includes('shorts')) {
        err = 'Invalid link: YouTube Shorts link not allowed';
      } else {
        err = '';
      }
    } else {
      err = 'Invalid YouTube link';
    }

    if (err) {
      setErrUrl(err);
      return;
    }

    uploadUrl(trimStr);
    setUrl('');
    setErrUrl('');
  }, [uploadUrl, url]);

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Typography>{title}</Typography>
      </Box>
      <TextField
        fullWidth
        placeholder="Enter Youtube Link"
        error={!!errUrl}
        helperText={errUrl}
        variant="outlined"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleUpload()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleUpload}>
                <AddIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </>
  );
};

export default UploadYtLink;
