import React, { FC, memo } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { AmountSrpChange } from 'src/types';
import { formatCurrency } from 'src/utils';

interface DecisionDialogProps {
  isOpen: boolean;
  title?: string;
  subTitle?: string;
  okayButtonName?: string;
  appliedSrpChangesItem?: any[];
  amountSrpChange?: AmountSrpChange;
  onHandleConfirmAction: () => void;
  onHandleClose: () => void;
}

const component: FC<DecisionDialogProps> = ({
  isOpen,
  title,
  subTitle,
  okayButtonName = 'Confirm',
  appliedSrpChangesItem,
  amountSrpChange,
  onHandleConfirmAction,
  onHandleClose
}) => {
  const onHandleConfirm = () => {
    onHandleConfirmAction();
    onHandleClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ fontWeight: 700, fontSize: '20px' }}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ fontSize: '16px' }}>{subTitle}</Typography>
          </DialogContentText>
          {appliedSrpChangesItem?.map((srp_item, index) => (
            <Box
              key={index}
              mb={3}
              sx={{ borderBottom: '2px solid lightgray' }}
            >
              <Typography variant="h5" style={{ marginBottom: 5 }}>
                {srp_item?.product_name}
              </Typography>
              <Typography variant="h5">{srp_item?.breakdown}</Typography>
            </Box>
          ))}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="h3" style={{ marginRight: 5 }}>
              Total Amount:
            </Typography>

            <Typography variant="h3">
              {`from ${formatCurrency(
                amountSrpChange?.prev_amount
              )} to new total amount: ${formatCurrency(
                amountSrpChange?.new_amount
              )}`}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onHandleConfirm} color="primary" autoFocus>
            {okayButtonName}
          </Button>
          <Button onClick={onHandleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const SrpChangesDialog = memo(component);
