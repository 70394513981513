export type WarrantyDuration = {
  name: string;
  value: number;
};

export const warranties: WarrantyDuration[] = [
  {
    name: 'Not Applicable',
    value: 0
  },
  {
    name: '7 days',
    value: 7
  },
  {
    name: '1 month - (31 days)',
    value: 31
  },
  {
    name: '3 months - (93 days)',
    value: 93
  },
  {
    name: '6 months - (186 days)',
    value: 186
  },
  {
    name: '9 months - (279 days)',
    value: 279
  },
  {
    name: '1 year - (365 days)',
    value: 365
  },
  {
    name: '1 year and 3 months - (458 days)',
    value: 458
  },
  {
    name: '1 year and 6 months - (551 days)',
    value: 551
  },
  {
    name: '1 year and 9 months - (644 days)',
    value: 644
  },
  {
    name: '2 years - (730 days)',
    value: 730
  },
  {
    name: '2 years and 3 months - (823 days)',
    value: 823
  },
  {
    name: '2 years and 6 months - (916 days)',
    value: 916
  },
  {
    name: '2 years and 9 months - (1009 days)',
    value: 1009
  },
  {
    name: '3 years - (1095 days)',
    value: 1095
  }
];
