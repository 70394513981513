import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import {
  CreateGameRequest,
  CreateGameResponse,
  DeleteGameResponse,
  GameDetailsResponse,
  GamesListRequest,
  GamesListResponse,
  UpdateGameRequest
} from './types';

export const getGamesListThunk = createAsyncThunk<
  CommonAxiosResponse<GamesListResponse>,
  GamesListRequest | undefined
>('games/getGamesListThunk', async (data) => {
  const response = await Api.get('/ecomm/internal/game/details', data);
  return response;
});

export const createGameThunk = createAsyncThunk<
  CommonAxiosResponse<CreateGameResponse>,
  CreateGameRequest
>('games/createGameThunk', async (data) => {
  const response = await Api.post('/ecomm/internal/game/create', data);
  return response;
});

export const getGameDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GameDetailsResponse>,
  number
>('games/getGameDetailsThunk', async (id) => {
  const response = await Api.get(`ecomm/internal/game/details/${id}`);
  return response;
});

export const updateGameDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GameDetailsResponse>,
  UpdateGameRequest
>('games/updateGameDetailsThunk', async (data) => {
  const { id, ...request } = data;
  const response = await Api.patch(`ecomm/internal/game/update/${id}`, request);
  return response;
});

export const deleteGameDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteGameResponse>,
  number
>('games/deleteGameDetailsThunk', async (id) => {
  const response = await Api.delete(`ecomm/internal/game/delete/${id}`);
  return response;
});
