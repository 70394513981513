import { Card, CardContent, Grid } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import UploadYtLink from './UploadYtLink';
import YouTubeEmbed from './YouTubeEmbed';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { BundleDetailsV2 } from 'src/redux/slices/pc-bundles/types';

interface Props {
  bundle?: BundleDetailsV2;
}

const {
  selectors: pcBundleSelectors,
  actions: pcBundleActions
} = slices.pcBundles;

const BenchmarkAndAssembly: FC<Props> = ({ bundle }) => {
  const dispatch = useAppDispatch();

  const benchmark = useAppSelector(pcBundleSelectors.selectBenchmark);
  const assembly = useAppSelector(pcBundleSelectors.selectAssembly);

  const [benchmarkVal, setBenchmarkVal] = useState<string>('');
  const [assemblyVal, setAssemblyVal] = useState<string>('');

  useEffect(() => {
    dispatch(pcBundleActions.setAssembly(bundle?.assembling_video || ''));
    dispatch(pcBundleActions.setBenchmark(bundle?.benchmark_video || ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundle]);

  useEffect(() => {
    setBenchmarkVal(benchmark || '');
  }, [benchmark]);

  useEffect(() => {
    setAssemblyVal(assembly || '');
  }, [assembly]);

  return (
    <Grid container spacing={1} style={{ marginTop: '1em' }}>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <UploadYtLink
              title="Enter YT Url for Benchmark"
              uploadUrl={(url: string) => {
                setBenchmarkVal(url);
                dispatch(pcBundleActions.setBenchmark(url));
              }}
            />
            {benchmarkVal && (
              <YouTubeEmbed
                url={benchmarkVal}
                deleteUrl={() => setBenchmarkVal('')}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <UploadYtLink
              title="Enter YT Url for Assembly"
              uploadUrl={(url: string) => {
                setAssemblyVal(url);
                dispatch(pcBundleActions.setAssembly(url));
              }}
            />
            {assemblyVal && (
              <YouTubeEmbed
                url={assemblyVal}
                deleteUrl={() => setAssemblyVal('')}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default BenchmarkAndAssembly;
