import React from 'react';
import { View, StyleSheet, Image } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { formatCurrency } from 'src/utils';
import { TextRegularWarranty } from 'src/views/transactions/TransactionPDF/components';

interface Props {
  amount: number;
  containerStyle?: Style;
  isSIPricing?: boolean;
}

const styles = StyleSheet.create({
  text: {
    fontSize: '12px',
    fontWeight: 'bold',
    maxLines: 1
  },
  image: {
    height: '10px',
    width: '10px',
    marginTop: 2,
    marginHorizontal: 2
  },
  container: { flexDirection: 'row' }
});

export const RenderCurrency = ({ amount }: Props) => {
  return (
    <View style={[styles.container]}>
      <Image
        style={[styles.image, { paddingLeft: 2 }]}
        src="/static/images/icons/ico-php.png"
      />
      <TextRegularWarranty style={styles.text}>
        {`${formatCurrency(amount, '', 2, { removeCurrency: true })}`}
      </TextRegularWarranty>
    </View>
  );
};
