import React from 'react';

import DeleteIcon from '@material-ui/icons/Delete';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { colors } from 'src/constants';
import { Image } from 'src/redux/slices/pc-bundles/types';

interface Props {
  index: number; // For identification on what to delete.. Could be Id could also be index
  imgSrc?: Image;
  onDelete: (id: number) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    border: '1px solid grey'
  },
  hiddenBorder: {
    border: '0px'
  },
  buttonsContainer: {
    marginTop: 5,
    marginLeft: 5,
    position: 'absolute',
    zIndex: 99
  },
  deleteIcon: {
    padding: 3,
    backgroundColor: colors.common.white,
    cursor: 'pointer'
  },
  img: {
    '&:hover': {
      opacity: 0.3
    }
  }
}));

const component = ({ imgSrc, onDelete, index }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box className={classes.buttonsContainer}>
        <IconButton
          className={classes.deleteIcon}
          onClick={() => onDelete(index)}
        >
          <DeleteIcon fontSize="medium" color="secondary" />
        </IconButton>
      </Box>
      <img
        className={classes.img}
        alt="Cover Image"
        height={100}
        src={imgSrc?.file}
      />
    </div>
  );
};

export const DeletableImg = React.memo(component);
