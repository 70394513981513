import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { CustomInputEvent, CustomKeyboardEvent } from 'src/types';
import AddGameDialog from './AddGameDialog';

interface Props {
  className?: string;
  getGames: (keyword?: string) => void;
  onSearchGames: (val: string) => void;
}

const Toolbar = ({ className, onSearchGames, getGames, ...rest }: Props) => {
  const [searchText, setSearchText] = useState('');

  const [isAddGameOpen, setIsAddGameOpen] = useState<boolean>(false);

  const toggleAddGameDialog = useCallback(() => {
    setIsAddGameOpen(!isAddGameOpen);
  }, [isAddGameOpen]);

  const handleChange = (event: CustomInputEvent) => {
    setSearchText(event.target.value);
  };

  const onSearchClick = () => {
    onSearchGames(searchText);
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearchGames(searchText);
    }
  };

  return (
    <div className={className} {...rest}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography color="textPrimary" gutterBottom variant="h3">
          Games
        </Typography>
        <Button
          onClick={toggleAddGameDialog}
          color="primary"
          variant="contained"
        >
          add games
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid alignItems="center" container spacing={2}>
              <Grid item lg={4} md={6} xs={12}>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    onKeyPress={onKeyPress}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search games"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid alignItems="center" item xs={1}>
                <Button
                  onClick={onSearchClick}
                  color="primary"
                  variant="contained"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <AddGameDialog
          getGames={getGames}
          open={isAddGameOpen}
          handleClose={toggleAddGameDialog}
        />
      </Box>
    </div>
  );
};

export default Toolbar;
