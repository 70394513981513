import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

import { Supplier } from 'src/types/supplier';
import { slices, useAppDispatch } from 'src/redux';
import { localize } from 'src/constants';
import { usePermissions, useSnackBar } from 'src/hooks';
import { AlertDialog, LoaderBar } from 'src/components';
import {
  containsAlphabetsWithSpacesAndDashes,
  isEmptyOrSpaces
} from 'src/utils';

interface Props {
  supplier: Supplier;
  className?: string;
}

const { actions: supplierActions } = slices.supplier;

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const SupplierDetailView = ({ className, supplier, ...rest }: Props) => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const snackBar = useSnackBar();
  const { canEditSupplier, canDeleteSupplier } = usePermissions();

  const [showEdit, setEdit] = useState(false);
  const [supplierDetail, setSupplierDetail] = useState(supplier);
  const [supplierName, setSupplierName] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState<boolean>(
    false
  );

  const onSaveDetailPress = async () => {
    if (!id || !canEditSupplier) {
      return;
    }
    if (
      !supplierName ||
      isEmptyOrSpaces(supplierName) ||
      !containsAlphabetsWithSpacesAndDashes(supplierName)
    ) {
      snackBar.show({
        severity: 'error',
        message: 'Please provide proper Supplier name'
      });
      return;
    }
    const response = unwrapResult(
      await dispatch(
        supplierActions.updateSupplierThunk({ id: +id, name: supplierName })
      )
    );
    if (response?.success && response?.message) {
      snackBar.show({ severity: 'success', message: response.message });
      setSupplierDetail(response.originalData.supplier);
    } else {
      snackBar.show({
        severity: 'error',
        message: response?.message ? response.message : localize.ERR_API_UNKNOWN
      });
    }
  };

  const deleteThisSupplier = () => {
    onDeleteSupplier();
  };

  const handleClickOpen = () => {
    setDeleteProductDialog(true);
  };

  const handleClose = () => {
    setDeleteProductDialog(false);
  };

  const onDeleteSupplier = async () => {
    if (!id || !canDeleteSupplier) {
      return;
    }
    setIsLoading(true);
    const deleteSupplierResponse = unwrapResult(
      await dispatch(supplierActions.supplierDeleteThunk(+id))
    );
    if (deleteSupplierResponse?.success && deleteSupplierResponse?.message) {
      setIsLoading(false);
      // eslint-disable-next-line no-alert
      navigate(-1);
    }
    // eslint-disable-next-line no-alert
    // alert('Deletion of supplier is not allowed.');
  };

  useEffect(() => {
    setSupplierName(supplierDetail.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEdit]);

  return (
    <Box mt={2}>
      <Card className={clsx(classes.root, className)} {...rest}>
        <LoaderBar isLoading={isLoading} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={8} md={8} xs={8}>
              <Box>
                <Typography
                  style={{ marginTop: 10 }}
                  color="textPrimary"
                  gutterBottom
                  variant="h3"
                >
                  {supplierDetail?.name ? supplierDetail?.name : supplier.name}
                </Typography>
                <Typography color="textSecondary" variant="h6">
                  {supplierDetail?.name ? supplierDetail?.name : supplier.name}
                </Typography>
              </Box>
            </Grid>
            {canEditSupplier && (
              <Grid item lg={4} md={4} xs={4}>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button
                    onClick={() => setEdit(!showEdit)}
                    variant="contained"
                  >
                    {showEdit ? 'Hide Edit Fields' : 'Edit'}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
          {canEditSupplier && (
            <Box hidden={!showEdit} mt={2}>
              <Card elevation={0}>
                <CardContent>
                  <TextField
                    fullWidth
                    required
                    name="supplierName"
                    variant="outlined"
                    label="Supplier Name"
                    onChange={(e) => setSupplierName(e.target.value)}
                    value={supplierName}
                  />
                </CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  paddingX={2}
                  mt={2}
                >
                  <Button
                    disabled={!canDeleteSupplier}
                    onClick={handleClickOpen}
                    color="secondary"
                    variant="contained"
                  >
                    Delete Supplier
                  </Button>
                  <AlertDialog
                    title={`Delete ${supplierName}`}
                    customButtons={
                      <>
                        <Button
                          onClick={deleteThisSupplier}
                          color="secondary"
                          autoFocus
                        >
                          Delete
                        </Button>
                        <Button onClick={handleClose} color="primary">
                          Cancel
                        </Button>
                      </>
                    }
                    subTitle={`Are you sure you want to delete ${supplierName}`}
                    isVisible={deleteProductDialog}
                  />
                  <Button
                    onClick={onSaveDetailPress}
                    color="primary"
                    variant="contained"
                  >
                    Save details
                  </Button>
                </Box>
              </Card>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default SupplierDetailView;
