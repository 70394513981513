import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonTransactionsResponse,
  CreateTransactionResponse,
  CreateTransactionRequest,
  TransactionsThunkRequest,
  VoidItemTransactionRequest,
  VoidItemTransactionResponse,
  AddReplaceTransactionRequest,
  GetTransactionsViaTransactionNoResponse,
  VoidTransactionDeletePayload,
  ChangeSrpPayload
} from 'src/types/transaction';
import { CommonAxiosResponse } from 'src/types';

export * from './transaction-remarks-thunk';
export * from './transaction-details-thunk.ts';

export const getCustomerTransactionsThunk = createAsyncThunk<
  CommonAxiosResponse<CommonTransactionsResponse>,
  TransactionsThunkRequest,
  { state: RootState }
>('transaction/getCustomerTransactionsThunk', async (data) => {
  const response = await Api.get('/transactions/getCustomerTransactions', data);
  if (!response.success) {
    throw new Error('Failed getCustomerTransactionsThunk');
  }
  return response;
});

export const getTransactionsThunk = createAsyncThunk<
  CommonAxiosResponse<CommonTransactionsResponse>,
  TransactionsThunkRequest,
  { state: RootState }
>('transaction/getTransactionsThunk', async (data) => {
  const response = await Api.get('/transactions', data);
  if (!response.success) {
    throw new Error('Failed getTransactionsThunk');
  }
  return response;
});

export const getTransactionViaTransactionNo = createAsyncThunk<
  CommonAxiosResponse<CommonTransactionsResponse>,
  string | undefined,
  { state: RootState }
>('transaction/getTransactionsViaTransactionNo', async (transaction_no) => {
  const response = await Api.get(
    `/transactions/getTransactionsViaTransactionNo`,
    { transaction_no }
  );
  if (!response.success) {
    throw new Error('Failed getTransactionsViaTransactionNo');
  }
  return response;
});

export const getTransactionViaTransactionNoForGallery = createAsyncThunk<
  CommonAxiosResponse<GetTransactionsViaTransactionNoResponse>,
  string | undefined,
  { state: RootState }
>(
  'transaction/getTransactionViaTransactionNoForGallery',
  async (transaction_no) => {
    const response = await Api.get(
      `/transactions/getTransactionsViaTransactionNo`,
      { transaction_no }
    );
    return response;
  }
);

let createTransactionAbortController: AbortController | null = null;

export const createTransactionThunk = createAsyncThunk<
  CommonAxiosResponse<CreateTransactionResponse>,
  {
    transaction_remarks?: string;
    pc_bundle_component_group_serial_no?: string;
    multipleListings: CreateTransactionRequest[];
  },
  { state: RootState }
>('transaction/createTransactionThunk', async (data) => {
  if (createTransactionAbortController) {
    createTransactionAbortController.abort();
  }

  createTransactionAbortController = new AbortController();

  const response = await Api.post(`/transactions`, data, {
    signal: createTransactionAbortController.signal
  });
  if (!response) {
    throw new Error('Failed at createTransactionThunk.');
  }
  return response;
});

export const deleteTransactionViaTransactionNo = createAsyncThunk<
  CommonAxiosResponse<CommonTransactionsResponse>,
  VoidTransactionDeletePayload,
  { state: RootState }
>('transaction/deleteTransactionThunk', async (data) => {
  const response = await Api.post(
    `/transactions/deleteTransactionViaTransactionNo`,
    data
  );
  if (!response.success) {
    throw new Error('Failed deleteTransactionThunk');
  }
  return response;
});

export const voidItemTransaction = createAsyncThunk<
  CommonAxiosResponse<VoidItemTransactionResponse>,
  VoidItemTransactionRequest,
  { state: RootState }
>('transaction/voidItemTransaction', async (data) => {
  const response = await Api.post(`/transactions/voidItemTransaction`, data);
  return response;
});

export const addItemToTransactionNoThunk = createAsyncThunk<
  CommonAxiosResponse<CommonTransactionsResponse>,
  AddReplaceTransactionRequest,
  { state: RootState }
>('transaction/addItemToTransactionNoThunk', async (data) => {
  const response = await Api.post(`/transactions/addItemToTransactionNo`, data);
  if (!response.success) {
    throw new Error('Failed addItemToTransactionNoThunk');
  }
  return response;
});

export const replaceItemToTransactionNoThunk = createAsyncThunk<
  CommonAxiosResponse<CommonTransactionsResponse>,
  AddReplaceTransactionRequest,
  { state: RootState }
>('transaction/replaceItemToTransactionNoThunk', async (data) => {
  const response = await Api.post(
    `/transactions/replaceItemToTransactionNo`,
    data
  );
  if (!response.success) {
    throw new Error('Failed replaceItemToTransactionNoThunk');
  }
  return response;
});

export const changeTransactionSrpThunk = createAsyncThunk<
  CommonAxiosResponse<any>,
  ChangeSrpPayload | undefined,
  { state: RootState }
>('transaction/changeTransactionSrpThunk', async (data) => {
  const response = await Api.patch(
    `/transactions/apply_srp_items_retail_price/update/${data?.transaction_no}`,
    { srp_percentage: data?.srp_percentage }
  );
  if (!response.success) {
    throw new Error('Failed Change Transaction item SRP');
  }
  return response;
});

// export const updateTransactionsThunk = createAsyncThunk<
//   CommonAxiosResponse<UpdateTransactionResponse>,
//   UpdateTransactionRequest,
//   { state: RootState }
// >('transaction/updateTransactionsThunk', async (data) => {
//   const response = await Api.patch(`/transactions/${data.id}`, data);
//   if (!response.success) {
//     throw new Error('Failed at updateTransactionsThunk.');
//   }
//   return response;
// });
