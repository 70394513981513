import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectPCBundlesState = (state: RootState) => state.pcBundles;

const selectKeyword = createSelector(
  selectPCBundlesState,
  (state) => state.filters.keyword
);

const selectStatusFilter = createSelector(
  selectPCBundlesState,
  (state) => state.filters.status
);

const selectSort = createSelector(
  selectPCBundlesState,
  (state) => state.filters.sort
);

const selectBundleList = createSelector(
  selectPCBundlesState,
  (state) => state.bundles
);

const selectDetails = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails
);

const selectTitle = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails.title
);

const selectDescription = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails.description
);

const selectThumbnail = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails.thumbnail
);

const selectGames = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails.games
);

const selectUseCore = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails.useCore
);

const selectProducts = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails.products
);

const selectHighlights = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails.highlights
);

const selectBenchmark = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails.benchmark_video
);

const selectStatus = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails.status
);

const selectImages = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails.images
);

const selectManufacturer = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails.manufacturer_id
);

const selectAssembly = createSelector(
  selectPCBundlesState,
  (state) => state.bundleDetails.assembling_video
);

export const selectors = {
  selectPCBundlesState,
  selectStatus,
  selectImages,
  selectSort,
  selectStatusFilter,
  selectKeyword,
  selectManufacturer,
  selectAssembly,
  selectDetails,
  selectBundleList,
  selectTitle,
  selectDescription,
  selectThumbnail,
  selectGames,
  selectUseCore,
  selectProducts,
  selectHighlights,
  selectBenchmark
};
