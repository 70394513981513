import React, { useCallback, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { GameDetails } from './GameDetails';
import { useSnackBar } from 'src/hooks';
import { Game, UpdateGameRequest } from 'src/redux/slices/games/types';
import { useAppDispatch } from 'src/redux';
import {
  deleteGameDetailsThunk,
  getGameDetailsThunk,
  updateGameDetailsThunk
} from 'src/redux/slices/games/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ErrorDialog } from 'src/components/error/ErrorDialog';
import { useErrDialog } from 'src/hooks/use-error-dialog';

interface Props {
  games: Game[];
  getGames: () => void;
}

const GamesListTable = ({ games, getGames, ...rest }: Props) => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const {
    errs,
    setErrs,
    showErr,
    setShowErr,
    handleErrorFromResponse
  } = useErrDialog();

  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);
  const [dialogDetails, setDialogDetails] = useState<Game | null>(null);

  const onGamePress = useCallback(
    async (id: number) => {
      const res = unwrapResult(await dispatch(getGameDetailsThunk(id)));
      if (res.success) {
        toggleGameDetailsDialog();
        setDialogDetails(res?.originalData.data);
      } else {
        console.error('failed to fetch game details');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [games]
  );

  const toggleGameDetailsDialog = useCallback(() => {
    setIsDetailOpen(!isDetailOpen);
  }, [isDetailOpen]);

  const deleteGame = useCallback(
    async (id: number) => {
      if (!id) {
        return;
      }
      const res = unwrapResult(await dispatch(deleteGameDetailsThunk(id)));
      const { message } = res;
      if (res.success) {
        getGames();
        snackBar.show({
          severity: 'success',
          useSound: true,
          message
        });
      } else {
        snackBar.show({
          severity: 'error',
          useSound: true,
          message
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onUpdateGameDetails = useCallback(
    async (name: string, icon_url: string) => {
      if (!dialogDetails?.id) {
        return;
      }

      let request: UpdateGameRequest = { id: dialogDetails.id };

      let errMessage = '';
      if (!name) {
        errMessage = 'Invalid name';
      }

      if (!icon_url) {
        errMessage = 'Game Image Icon is Required';
      }

      if (errMessage) {
        snackBar.show({
          severity: 'error',
          message: errMessage
        });
        return;
      }

      if (name !== dialogDetails?.name) {
        request.name = name;
      }

      if (icon_url !== dialogDetails?.icon) {
        request.icon_url = icon_url;
      }

      const res = unwrapResult(await dispatch(updateGameDetailsThunk(request)));
      if (res.success) {
        snackBar.show({
          severity: 'success',
          message: 'Bundle updated successfully',
          useSound: true
        });
        setDialogDetails(null);
        toggleGameDetailsDialog();
        getGames();
        setErrs([]);
      } else if (res.errors) {
        handleErrorFromResponse(res);
        setShowErr(true);
        snackBar.show({
          severity: 'error',
          useSound: true,
          message:
            'Failed to update bundle. Check if data is correct and compelete'
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialogDetails]
  );

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game Title</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {games.map((item: Game, ind: number) => (
                <TableRow hover key={ind}>
                  <TableCell
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Button
                      variant="text"
                      onClick={() => item?.id && onGamePress(item?.id)}
                    >
                      <Typography>{item.name}</Typography>
                    </Button>
                    <Button onClick={() => item?.id && deleteGame(item.id)}>
                      <DeleteIcon color="secondary" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <GameDetails
            details={dialogDetails}
            open={isDetailOpen}
            loading={false}
            onUpdatePress={onUpdateGameDetails}
            handleClose={toggleGameDetailsDialog}
          />
        </Box>
        <ErrorDialog
          open={showErr}
          errs={errs}
          closeDialog={() => setShowErr(false)}
        />
      </PerfectScrollbar>
    </Card>
  );
};

export default GamesListTable;
