import { FormHelperText, makeStyles, Typography } from '@material-ui/core';
import React, { CSSProperties, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import clsx from 'clsx';

interface Props {
  multiple?: boolean;
  title?: string;
  activeTitle?: string;
  helperText?: string;
  onImageSelected: (file: any) => void;
  containerStyle?: CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: 50,
    backgroundColor: theme.palette.grey[50],
    border: '0.5px dashed grey',
    boxShadow: theme.shadows[3],
    borderRadius: 8,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  rejectedFilesDiv: {
    padding: theme.spacing(1.5)
  }
}));

// eslint-disable-next-line no-empty-pattern
const component = ({
  multiple = true,
  title = 'Drag and drop some image(s) here, or click to select image(s)',
  activeTitle = 'Release Mouse',
  helperText = 'Size Up to 1MB and only jpeg, jpg and png is allowed',
  onImageSelected,
  containerStyle
}: Props) => {
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles) => {
      onImageSelected(acceptedFiles);
    },
    [onImageSelected]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections
  } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg']
    },
    maxSize: 1048576,
    multiple
  });

  const fileRejectionItems = fileRejections?.map(({ file, errors }) => {
    return (
      <li key={file?.name}>
        {file?.name} - {file?.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e?.code}>{e?.message}</li>
          ))}
        </ul>
      </li>
    );
  });

  return (
    <div style={containerStyle}>
      <div {...getRootProps()} className={clsx(classes.root)}>
        <input {...getInputProps()} />
        <Typography variant="h5">
          {isDragActive ? activeTitle : title}
        </Typography>
        <AddPhotoAlternateIcon />
      </div>
      <FormHelperText style={{ textAlign: 'center' }}>
        {helperText}
      </FormHelperText>
      {fileRejections?.length > 0 ? (
        <div className={classes.rejectedFilesDiv}>
          <h4>Rejected file(s)</h4>
          <ul>{fileRejectionItems}</ul>
        </div>
      ) : null}
    </div>
  );
};

export const SelectImageButton = React.memo(component);
