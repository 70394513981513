import React, { FC } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { IconButton, InputAdornment } from '@material-ui/core';

interface Props {
  onClick: () => void;
}

const AddIconAdornment: FC<Props> = ({ onClick }) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={onClick}>
        <AddIcon />
      </IconButton>
    </InputAdornment>
  );
};
export default AddIconAdornment;
