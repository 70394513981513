import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Page } from 'src/components';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { useParams } from 'react-router';
import {
  BundleDetails,
  BundleDetailsV2
} from 'src/redux/slices/pc-bundles/types';
import { BundleGeneralInfo } from './components/BundleGeneralInfo';
import { AddBundleImages } from './components/AddBundleImages';
import Games from './components/Games';
import Highlights from './components/Highlights';
import BenchmarkAndAssembly from './components/BenchmarkAndAssembly';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackBar } from 'src/hooks';
import usePrompt from 'src/utils/navigation-prompt';
import { useErrDialog } from 'src/hooks/use-error-dialog';
import { ErrorDialog } from 'src/components/error/ErrorDialog';
import SelectManufacturerV2 from './components/SelectManufacturerV2';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const {
  selectors: pcBundleSelectors,
  actions: pcBundleActions
} = slices.pcBundles;

const PCBundleDetails: FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const dispatch = useAppDispatch();
  const {
    errs,
    setErrs,
    showErr,
    setShowErr,
    handleErrorFromResponse
  } = useErrDialog();

  const hasSavedOriginalData = useRef(0);

  const { id } = useParams();

  const payload = useAppSelector(pcBundleSelectors.selectDetails);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<BundleDetailsV2>();
  const [originalData, setOriginalData] = useState<BundleDetails>({});

  const hasChangedValues = useMemo(() => {
    const pVal = JSON.stringify(payload);
    const ogVal = JSON.stringify(originalData);

    return pVal !== ogVal;
  }, [originalData, payload]);

  const getDetails = useCallback(async () => {
    if (id) {
      const res = unwrapResult(
        await dispatch(pcBundleActions.getPCBundleDetailsThunk(+id))
      );
      if (res.success) {
        setDetails(res?.originalData?.data);
      }
    }
  }, [dispatch, id]);

  const onSaveBundle = useCallback(async () => {
    // TODO: apply update build api
    setIsLoading(true);
    if (id) {
      const res = unwrapResult(
        await dispatch(
          pcBundleActions.updateBundleThunk({ id: +id, ogVal: originalData })
        )
      );
      if (res.success) {
        snackbar.show({
          useSound: true,
          severity: 'success',
          message: 'Bundle updated successfully'
        });
        getDetails();
        setErrs([]);
        hasSavedOriginalData.current = 0;
      } else if (res.errors) {
        handleErrorFromResponse(res);
        setShowErr(true);
        snackbar.show({
          severity: 'error',
          useSound: true,
          message:
            'Failed to update bundle. Check if data is correct and compelete'
        });
      } else {
        snackbar.show({
          severity: 'error',
          useSound: true,
          message:
            'Failed to update bundle. Check if data is correct and compelete'
        });
      }
    }
    setIsLoading(false);
  }, [
    dispatch,
    getDetails,
    handleErrorFromResponse,
    id,
    originalData,
    setErrs,
    setShowErr,
    snackbar
  ]);

  usePrompt(`Any unsaved changes will be lost`, hasChangedValues);

  useEffect(() => {
    // reset global payload state when navigating away
    // old payload causes error on new submit of update request
    return () => {
      dispatch(pcBundleActions.resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasSavedOriginalData.current && payload.title) {
      setOriginalData(payload);
      hasSavedOriginalData.current = 1;
    }
  }, [payload]);

  return (
    <Page className={classes.root} title="PC Bundle Details">
      <Container maxWidth={false}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // border: '1px solid black',
            marginBottom: '1em'
          }}
        >
          <Typography color="textPrimary" gutterBottom variant="h3">
            Bundle Details
          </Typography>
          <SelectManufacturerV2 bundle={details} />
        </Box>
        <BundleGeneralInfo bundle={details} />
        {/* bundle V1 code retain for now */}
        {/* <SelectManufacturer bundle={details} /> */}
        <AddBundleImages bundle={details} />
        <Games bundle={details} />
        {/* bundle V1 code retain for now */}
        {/* <BundleComponents bundle={details} /> */}
        <Highlights bundle={details} />
        <BenchmarkAndAssembly bundle={details} />

        <Box
          style={{
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'end'
          }}
        >
          <Button
            disabled={isLoading || !hasChangedValues}
            style={{ width: '20em' }}
            variant="contained"
            color="primary"
            onClick={onSaveBundle}
          >
            Save PC Bundle
          </Button>
        </Box>
        <ErrorDialog
          open={showErr}
          errs={errs}
          closeDialog={() => setShowErr(false)}
        />
      </Container>
    </Page>
  );
};
export default PCBundleDetails;
