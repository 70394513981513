import { useCallback, useState } from 'react';

// use with ErrorDialog component
export const useErrDialog = () => {
  const [errs, setErrs] = useState<string[]>([]);
  const [showErr, setShowErr] = useState<boolean>(false);

  const handleErrorFromResponse = useCallback((res: any) => {
    const { errors } = res;
    const errorMessages: string[] = Object.values(errors);
    setErrs(errorMessages);
  }, []);

  return {
    errs,
    setErrs,
    showErr,
    setShowErr,
    handleErrorFromResponse
  };
};
