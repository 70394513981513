import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Page } from 'src/components';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { BundleGeneralInfo } from '../components/BundleGeneralInfo';
import { AddBundleImages } from '../components/AddBundleImages';
import { useSnackBar } from 'src/hooks';
import Games from '../components/Games';
import Highlights from '../components/Highlights';
import BenchmarkAndAssembly from '../components/BenchmarkAndAssembly';
import usePrompt from 'src/utils/navigation-prompt';
import { useErrDialog } from 'src/hooks/use-error-dialog';
import { ErrorDialog } from 'src/components/error/ErrorDialog';
import SelectManufacturerV2 from '../components/SelectManufacturerV2';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  upload: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: '10px 12px',
    width: '100%',
    height: '3.5em',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#000'
    }
  }
}));

const {
  selectors: pcBundleSelectors,
  actions: pcBundleActions
} = slices.pcBundles;

const CreatePCBundle: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackbar = useSnackBar();
  const payload = useAppSelector(pcBundleSelectors.selectDetails);
  const {
    errs,
    setErrs,
    showErr,
    setShowErr,
    handleErrorFromResponse
  } = useErrDialog();

  // check if any field has a stored value
  const values = useMemo(() => {
    const arr = Object.values(payload);
    return arr.map((i) => {
      if (Array.isArray(i) && !i[0]) {
        return false;
      }
      return !!i;
    });
  }, [payload]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSaveBundle = useCallback(async () => {
    setIsLoading(true);
    const res = unwrapResult(
      await dispatch(pcBundleActions.createPCBundleThunk())
    );

    if (res.success) {
      snackbar.show({
        severity: 'success',
        useSound: true,
        message: 'Bundle created successfully'
      });
      setErrs([]);
      dispatch(pcBundleActions.resetState());
    } else if (res.errors) {
      handleErrorFromResponse(res);
      setShowErr(true);
      snackbar.show({
        severity: 'error',
        useSound: true,
        message:
          'Failed to create bundle. Check if data is correct and compelete'
      });
    } else {
      snackbar.show({
        severity: 'error',
        useSound: true,
        message:
          'Failed to create bundle. Check if data is correct and compelete'
      });
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  usePrompt(
    `There are unsaved changes, Are you sure to leave page`,
    values.some((i) => i === true)
  );

  return (
    <Page className={classes.root} title="Create PC Bundle">
      <Container maxWidth={false}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1em'
          }}
        >
          <Typography color="textPrimary" gutterBottom variant="h3">
            Create Bundle
          </Typography>
          <SelectManufacturerV2 />
        </Box>
        <BundleGeneralInfo />
        {/* bundle v1 code retain for now */}
        {/* <SelectManufacturer /> */}
        <AddBundleImages />
        <Games />
        {/* bundle v1 code retain for now */}
        {/* <BundleComponents /> */}
        <Highlights />
        <BenchmarkAndAssembly />
        <Box
          style={{
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'end'
          }}
        >
          <Button
            disabled={isLoading}
            style={{ width: '20em' }}
            variant="contained"
            color="primary"
            onClick={onSaveBundle}
          >
            Save PC Bundle
          </Button>
        </Box>

        <ErrorDialog
          open={showErr}
          errs={errs}
          closeDialog={() => setShowErr(false)}
        />
      </Container>
    </Page>
  );
};

export default CreatePCBundle;
