import { makeStyles, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import UploadIcon from 'src/components/icons/UploadIcon';

// todo: Clear uneeded props
interface Props {
  onImageSelected: (file: any) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: '10px 12px',
    width: '100%',
    height: '3.5em',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#000'
    }
  },
  rejectedFilesDiv: {
    padding: theme.spacing(1.5)
  }
}));

const component = ({ onImageSelected }: Props) => {
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles) => {
      onImageSelected(acceptedFiles);
    },
    [onImageSelected]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections
  } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg']
    },
    // TODO: Change to proper limit later
    maxSize: 1048576
  });

  const fileRejectionItems = fileRejections?.map(({ file, errors }) => {
    return (
      <li key={file?.name}>
        {file?.name} - {file?.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e?.code}>{e?.message}</li>
          ))}
        </ul>
      </li>
    );
  });

  return (
    <div>
      <div {...getRootProps()} className={clsx(classes.root)}>
        <input {...getInputProps()} />
        <Typography>
          {isDragActive ? 'Release Mouse' : 'Upload Bundle Thumbnail'}
        </Typography>
        <UploadIcon />
      </div>
      {fileRejections?.length > 0 ? (
        <div className={classes.rejectedFilesDiv}>
          <h4>Rejected file(s)</h4>
          <ul>{fileRejectionItems}</ul>
        </div>
      ) : null}
    </div>
  );
};

export const UploadThumbnail = React.memo(component);
