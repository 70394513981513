import React, { useMemo, useState } from 'react';
import {
  Box,
  Container,
  Tab,
  Tabs,
  Typography,
  makeStyles
} from '@material-ui/core';
import { DecisionDialog, Page } from 'src/components';
import { TransferList } from './component/TransferList';
import usePrompt from 'src/utils/navigation-prompt';
import { useStockTransfer } from 'src/hooks/stock-transfer';
import { usePermissions } from 'src/hooks';
import { ReceivingView } from './component/ReceivingV2';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,

    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export const StockTransferView = () => {
  const classes = useStyles();
  const {
    originBranchListProduct,
    clearAllOriginItems,
    clearAllReceivingItems
  } = useStockTransfer();
  const { canReceiveTransferStocks } = usePermissions();

  const [tabValue, setTabValue] = useState<number>(0);
  const [changeTab, setChangeTab] = useState<boolean>(false);

  const shouldPromptAppear = useMemo(
    () => originBranchListProduct && originBranchListProduct?.length > 0,
    [originBranchListProduct]
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (shouldPromptAppear) {
      setChangeTab(true);
      return;
    }
    clearAllOriginItems();
    clearAllReceivingItems();
    setTabValue(newValue);
  };

  const onHandleProceedChangeTab = () => {
    clearAllOriginItems();
    clearAllReceivingItems();
    setTabValue(1); //1 mean receiving branch
  };

  usePrompt(
    `There is currently added products from the list, navigating away will clear your progress.`,
    shouldPromptAppear
  );

  return (
    <Page title={'Stock Transfer'} className={classes.root}>
      <Container maxWidth="xl" style={{ minHeight: '85vh' }}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width={'100%'}
          mb={'1rem'}
        >
          <Typography color="textPrimary" gutterBottom variant="h2">
            {'Transfer Stock'}
          </Typography>
        </Box>
        {canReceiveTransferStocks ? (
          <>
            <Tabs
              centered
              variant="fullWidth"
              value={tabValue}
              style={{ marginBottom: '1rem' }}
              onChange={handleTabChange}
            >
              <Tab label="Origin" />
              <Tab label="Receiving" />
            </Tabs>
            {tabValue === 0 && <TransferList />}
            {tabValue === 1 && <ReceivingView />}
          </>
        ) : (
          <TransferList />
        )}
        <DecisionDialog
          isOpen={changeTab}
          title="Unsaved Changes Warning"
          subTitle="By confirming, you will switch to a new tab, and any unsaved changes to the transfer items will be lost."
          onHandleConfirmAction={onHandleProceedChangeTab}
          onHandleClose={() => setChangeTab(false)}
        />
      </Container>
    </Page>
  );
};
