import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { DragAndDropImgUpload, LoaderBar, Page } from 'src/components';
import { cloneDeep, isEqual } from 'lodash';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Banner, BannerPosition } from 'src/types/ecomm/banner';
import { convertImageFileToBase64 } from 'src/utils';
import { usePermissions, useSnackBar } from 'src/hooks';
import { colors } from 'src/constants';
import { BannerItem } from './components/BannerItem';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { BannerDetailsModal } from './components/BannerDetailsModal';

const { actions: ecomBannerActions } = slices.ecommBanner;

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  previewDiv: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  publishBtn: {
    padding: 3,
    marginLeft: 4,
    backgroundColor: colors.common.white,
    cursor: 'pointer'
  },
  saveBtn: {
    marginTop: theme.spacing(2)
  }
}));

const component = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const { canViewEcomBanners, canEditEcomBanners } = usePermissions();

  const [eBanners, setEbanners] = useState<Banner[]>([]);
  const [arrangement, setArrangement] = useState<BannerPosition[]>([]);
  const [bannerDetails, setBannerDetails] = useState<
    Banner | { img_url: any } // ts error band aid
  >();
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const onDragStart = () => setIsDragging(true);

  const onPhotosChangeInternal = useCallback(
    async (images: File[]) => {
      if (!canEditEcomBanners) {
        snackBar.show({ severity: 'error', message: 'Permission Denied' });
        return;
      }

      const img = images[0];
      if (!img) {
        snackBar.show({ severity: 'error', message: 'Image Upload Error' });
        return;
      }

      setBannerDetails({ img_url: await convertImageFileToBase64(img) });
      setIsDetailsOpen(true);
    },
    [canEditEcomBanners, snackBar]
  );

  const onClickBanner = useCallback((banner: Banner) => {
    if (!banner?.id) {
      return;
    }

    setBannerDetails(banner);
    setIsDetailsOpen(true);
  }, []);

  const onDeleteBanner = async (index: number) => {
    if (!canEditEcomBanners) {
      snackBar.show({ severity: 'error', message: 'Permission Denied' });
      return;
    }
    const clonedData = cloneDeep(eBanners);
    const itemImg = clonedData[index];

    if (itemImg?.id) {
      setIsLoading(true);
      const resp = unwrapResult(
        await dispatch(
          ecomBannerActions.deleteEcomBannerThunk(itemImg?.id)
        ).finally(() => setIsLoading(false))
      );
      if (resp?.success === true) {
        snackBar.show({
          severity: 'success',
          message: 'Successfully deleted banner'
        });
        clonedData?.splice(index, 1);
      } else {
        snackBar.show({ severity: 'error', message: 'Something went wrong' });
      }
    } else {
      clonedData?.splice(index, 1);
    }
    setEbanners(clonedData);
  };

  const getBannerImages = useCallback(async () => {
    if (!canViewEcomBanners) {
      return;
    }
    setIsLoading(true);
    const res = unwrapResult(
      await dispatch(ecomBannerActions.getEcommBannersThunk()).finally(() =>
        setIsLoading(false)
      )
    );

    if (!res.success) {
      console.error('failed to get banners');
      return;
    }

    const banners = res?.originalData?.data || [];

    if (banners[0]) {
      let bannersArr: Banner[] = [...banners];
      bannersArr.sort((a, b) => {
        // condition to prevent ts error
        if (a.position !== undefined && b.position !== undefined) {
          return a.position - b.position;
        }
        return 0; // fall back if undefined just retain position
      });

      setEbanners(bannersArr);
      return;
    }
  }, [canViewEcomBanners, dispatch]);

  // uploadBannerV1 for delete confirmation
  // const onUploadBanner = async () => {
  //   if (!canEditEcomBanners) {
  //     snackBar.show({ severity: 'error', message: 'Permission Denied' });
  //     return;
  //   }

  //   const clonedImgsToBeUploaded = cloneDeep(eBanners);
  //   const filteredImgsToBeUploaded = clonedImgsToBeUploaded?.filter(
  //     (x) => !x?.id
  //   );
  //   filteredImgsToBeUploaded.map((toBeUploaded) => {
  //     toBeUploaded.description =
  //       toBeUploaded?.description || `${timeStampNow()}`; // If empty generate timeStamp
  //   });
  //   setIsLoading(true);
  //   forEachPromise(filteredImgsToBeUploaded, (item) => {
  //     return new Promise((resolve) => {
  //       process.nextTick(() => {
  //         dispatch(ecomBannerActions.uploadEcommBannersThunk(item))
  //           .then(() => resolve(true))
  //           .catch(() => resolve(true));
  //       });
  //     });
  //   }).finally(() => {
  //     setIsLoading(false);
  //     snackBar.show({
  //       severity: 'success',
  //       message: 'Successfully uploaded item'
  //     });
  //     getBannerImages();
  //   });
  // };

  // not used anymore for delete confirmation
  // const onPublishBanner = async (bannerData?: Banner, published?: boolean) => {
  //   if (!canEditEcomBanners) {
  //     snackBar.show({ severity: 'error', message: 'Permission Denied' });
  //     return;
  //   }
  //   if (!bannerData?.id) {
  //     return;
  //   }
  //   // set on state
  //   const index = eBanners?.findIndex((x) => x?.id === bannerData?.id);
  //   setEbanners((prev) =>
  //     prev?.map((item, i) => {
  //       if (index !== i) {
  //         return item;
  //       }
  //       return { ...item, published: published ? 1 : 0 };
  //     })
  //   );
  //   // set on API
  //   setIsLoading(true);
  //   unwrapResult(
  //     await dispatch(
  //       ecomBannerActions.updateBannerThunk({
  //         ...bannerData,
  //         published: published,
  //         id: bannerData?.id
  //       })
  //     ).finally(() => setIsLoading(false))
  //   );
  //   getBannerImages();
  // };

  // not used anymore for delete confirmation
  // const onEditTextField = useCallback(
  //   (index: number, newValue: string, field: 'description' | 'link') => {
  //     setEbanners((prev) =>
  //       prev?.map((item, i) => {
  //         if (index !== i) {
  //           return item;
  //         }
  //         return { ...item, [field]: newValue };
  //       })
  //     );
  //   },
  //   []
  // );

  const onDragEnd = (res: any) => {
    // todo: add debounced api request in future pr
    if (!res.destination) {
      return;
    }

    let updatedList = [...eBanners];
    const [removed] = updatedList.splice(res.source.index, 1);
    updatedList.splice(res.destination.index, 0, removed);
    const arrangementPayload: BannerPosition[] = updatedList.map((i, ind) => ({
      banner_id: i.id,
      position: ind
    }));

    if (!isEqual(eBanners, updatedList)) {
      setEbanners(updatedList);
      setArrangement(arrangementPayload);
    }
    setIsDragging(false);
  };

  const updateBannerArrangement = useCallback(async () => {
    setIsLoading(true);
    const res = unwrapResult(
      await dispatch(
        ecomBannerActions.updateBannerArrangementThunk({ arrangement })
      )
    );

    if (!res.success) {
      console.error('Failed to save new banner arrangemetn');
    }

    setIsLoading(false);
  }, [arrangement, dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isDragging && arrangement[0]) {
        updateBannerArrangement();
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [arrangement, isDragging, updateBannerArrangement]);

  useEffect(() => {
    getBannerImages();
  }, [getBannerImages]);

  return (
    <Page className={classes.root} title="Ecomm Banner">
      <BannerDetailsModal
        open={isDetailsOpen}
        details={bannerDetails}
        setIsLoading={(i) => setIsLoading(i)}
        onClose={() => setIsDetailsOpen(false)}
        getBanners={getBannerImages}
        // If the banners array has 10 items, the last item's position is at index 9 (zero-based indexing).
        // When adding a new banner, the new banner's position should be set to 10, the next available index.
        lastPosition={eBanners.length}
      />
      <Container maxWidth={false} style={{ marginBottom: 50 }}>
        {canEditEcomBanners ? (
          <Box mt={2}>
            <Card>
              <CardHeader title="Ecommerce Banners" />
              <Divider />
              <CardContent>
                <Typography className={classes.subHeader} variant="h6">
                  Upload Image(s)
                </Typography>
                <DragAndDropImgUpload
                  title="Drag or select photos"
                  onImageSelected={onPhotosChangeInternal}
                />
              </CardContent>
              <Divider />
            </Card>
          </Box>
        ) : null}

        {/* V1 Version for delete confirmation */}
        {/* {hasBannersToBeUploaded && canEditEcomBanners ? (
          <Box mt={2}>
            <Card>
              <LoaderBar isLoading={isLoading} />
              <CardHeader title="To Be Uploaded Banners" />
              <Divider />
              <CardContent>
                <Grid container spacing={2} className={classes.previewDiv}>
                  {eBanners?.map((banner, i) =>
                    !banner?.id ? (
                      <Grid key={`${banner?.id}${i}`} item>
                        <Card elevation={5}>
                          <CardContent>
                            <DeletableImg
                              index={i}
                              hideImageBorder
                              imgSrc={banner?.img_url}
                              onDelete={onDeleteImage}
                            />
                            {!banner?.id ? (
                              <Typography variant="caption" color="error">
                                This image is not yet uploaded
                              </Typography>
                            ) : null}

                            <TextField
                              value={banner?.description}
                              onChange={(e) =>
                                onEditTextField(
                                  i,
                                  e?.target?.value,
                                  'description'
                                )
                              }
                              style={{ marginTop: 10 }}
                              size="small"
                              variant="outlined"
                              fullWidth
                              label="Description"
                            />
                            <TextField
                              onChange={(e) =>
                                onEditTextField(i, e?.target?.value, 'link')
                              }
                              style={{ marginTop: 10 }}
                              size="small"
                              variant="outlined"
                              fullWidth
                              label="href/link"
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    ) : null
                  )}
                </Grid>

                <Button
                  fullWidth
                  startIcon={<SaveIcon />}
                  onClick={onUploadBanner}
                  color="primary"
                  className={classes.saveBtn}
                  variant="contained"
                >
                  Upload Banners
                </Button>
              </CardContent>
            </Card>
          </Box>
        ) : null} */}

        {canViewEcomBanners ? (
          <Box mt={2}>
            <Card>
              <LoaderBar isLoading={isLoading} />
              <CardHeader title="Uploaded Banners" />
              <Divider />
              <CardContent>
                <Grid container spacing={2} className={classes.previewDiv}>
                  <DragDropContext
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                  >
                    <Droppable
                      droppableId="droppable-banner-list"
                      direction="vertical"
                    >
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '1em',
                            width: '100%'
                          }}
                        >
                          {eBanners.map((i, ind) => (
                            <Draggable
                              key={ind}
                              draggableId={String(ind)}
                              index={ind}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <BannerItem
                                    item={i}
                                    onClick={onClickBanner}
                                    onDelete={() => onDeleteBanner(ind)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  {/* old ui code can i delete this? */}
                  {/* {eBanners?.map((banner, i) =>
                    banner?.id ? (
                      <Grid key={`${banner?.id}${i}`} item>
                        <Card elevation={5}>
                          <CardMedia>
                            <DeletableImg
                              index={i}
                              hideImageBorder
                              imgSrc={banner?.img_url}
                              onDelete={onDeleteImage}
                            />
                          </CardMedia>
                          <CardContent>
                            <TextField
                              disabled={!canEditEcomBanners}
                              value={banner?.description || ''}
                              style={{ marginTop: 10 }}
                              size="small"
                              variant="outlined"
                              fullWidth
                              label="Label"
                              onChange={(e) =>
                                onEditTextField(
                                  i,
                                  e?.target?.value,
                                  'description'
                                )
                              }
                            />
                            <TextField
                              disabled={!canEditEcomBanners}
                              style={{ marginTop: 10 }}
                              value={banner?.link || ''}
                              size="small"
                              variant="outlined"
                              fullWidth
                              label="href/link"
                              onChange={(e) =>
                                onEditTextField(i, e?.target?.value, 'link')
                              }
                            />
                          </CardContent>
                          <Divider />
                          {canEditEcomBanners ? (
                            <CardActions
                              style={{ justifyContent: 'space-between' }}
                            >
                              <CheckBoxLabel
                                label="Published"
                                checked={banner?.published ? true : false}
                                onChange={(v) => onPublishBanner(banner, v)}
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => onUpdateBanner(banner)}
                              >
                                Update
                              </Button>
                            </CardActions>
                          ) : null}
                        </Card>
                      </Grid>
                    ) : null
                  )} */}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ) : null}
      </Container>
    </Page>
  );
};

export const EcommBannerView = React.memo(component);
