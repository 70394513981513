import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FixMeLater } from 'src/types';
import { BundleDetails, BundleSummary, Highlight, Image } from './types';
import * as thunks from './thunks';
import { Game } from '../games/types';

type State = {
  filters: {
    status: string;
    sort: string;
    keyword: string;
    limit: number;
  };
  bundles: BundleSummary[];
  bundleDetails: BundleDetails;
};

const initialState: State = {
  filters: {
    status: '',
    sort: 'desc',
    keyword: '',
    limit: 100
  },
  bundles: [],
  bundleDetails: {
    title: '',
    description: '',
    thumbnail: '',
    manufacturer_id: undefined,
    images: [],
    games: [],
    useCore: false,
    products: [],
    highlights: [],
    benchmark_video: '',
    assembling_video: '',
    status: undefined
  }
};

const resetFilters = (state: State) => {
  state.filters = initialState.filters;
};

const resetState = (state: State) => {
  state.bundleDetails = initialState.bundleDetails;
};

const setBundleDetails = (
  state: State,
  { payload }: PayloadAction<BundleDetails>
) => {
  if (payload) state.bundleDetails = payload;
};

const setTitle = (state: State, { payload }: PayloadAction<string>) => {
  state.bundleDetails.title = payload || '';
};

const setKeyword = (state: State, { payload }: PayloadAction<string>) => {
  state.filters.keyword = payload || '';
};

const setStatusFilter = (state: State, { payload }: PayloadAction<string>) => {
  state.filters.status = payload || '';
};

const setSort = (state: State, { payload }: PayloadAction<string>) => {
  state.filters.sort = payload || '';
};

const setManufacturer = (state: State, { payload }: PayloadAction<number>) => {
  if (payload) state.bundleDetails.manufacturer_id = payload;
};

const setImages = (state: State, { payload }: PayloadAction<Image[]>) => {
  if (payload) state.bundleDetails.images = payload;
};

const setDesc = (state: State, { payload }: PayloadAction<string>) => {
  state.bundleDetails.description = payload || '';
};

const setStatus = (state: State, { payload }: PayloadAction<string>) => {
  state.bundleDetails.status = payload || '';
};

const setThumbnail = (state: State, { payload }: PayloadAction<string>) => {
  if (payload) state.bundleDetails.thumbnail = payload;
};

const setGames = (state: State, { payload }: PayloadAction<Game[]>) => {
  state.bundleDetails.games = payload || [];
};

const setUseCore = (state: State, { payload }: PayloadAction<boolean>) => {
  if (payload) state.bundleDetails.useCore = payload;
};

const setProducts = (
  state: State,
  { payload }: PayloadAction<FixMeLater[]>
) => {
  if (payload) state.bundleDetails.products = payload;
};

const setHighlight = (
  state: State,
  { payload }: PayloadAction<Highlight[]>
) => {
  if (payload) state.bundleDetails.highlights = payload;
};

const setBenchmark = (state: State, { payload }: PayloadAction<string>) => {
  if (payload) state.bundleDetails.benchmark_video = payload;
};

const setAssembly = (state: State, { payload }: PayloadAction<string>) => {
  if (payload) state.bundleDetails.assembling_video = payload;
};

const slice = createSlice({
  name: 'pc-bundles',
  initialState,
  reducers: {
    setBundleDetails,
    resetState,
    resetFilters,
    setDesc,
    setImages,
    setManufacturer,
    setSort,
    setKeyword,
    setStatusFilter,
    setTitle,
    setThumbnail,
    setStatus,
    setGames,
    setUseCore,
    setProducts,
    setHighlight,
    setBenchmark,
    setAssembly
  },
  extraReducers(builder) {
    builder.addCase(
      thunks.getPCBundleListThunk.fulfilled,
      (state, { payload }) => {
        state.bundles = payload.originalData.data;
      }
    );
  }
});

export const reducer = slice.reducer;
export const actions = {
  ...slice.actions,
  ...thunks
};
