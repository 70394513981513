import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useMemo, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import {
  GetBranchCategoryData,
  GetBranchCategoryPayload,
  GetProductWithTotalAvailableItemsPayload
} from 'src/types';
import { useSnackBar } from '../use-snackbar';
import { CommonAuditParams } from 'src/redux/slices/inventory-audit';
import { cleanSN } from 'src/utils';

const { actions: branchActions } = slices.branch;
const { actions: productActions } = slices.product;
const {
  selectors: inventoryAuditSelector,
  actions: inventoryAuditActions
} = slices.inventoryAudit;

export const useInventoryAudit = () => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const inventoryAuditProductListing = useAppSelector(
    inventoryAuditSelector.selectInventoryAuditProductListing
  );

  const productsToBeAudited = useAppSelector(
    inventoryAuditSelector.selectToBeAuditProductList
  );

  const commonAuditParams = useAppSelector(
    inventoryAuditSelector.selectCommonAuditParams
  );

  const [productOptions, setProductOptions] = useState<GetBranchCategoryData[]>(
    []
  );

  //state to check api call success
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //only with product ids added in listing can be scanned with serial numbers
  const currentProductIdsToBeAudited = useMemo(() => {
    return inventoryAuditProductListing?.map((product) => product?.id);
  }, [inventoryAuditProductListing]);

  const currentProductIdsOnListing = useMemo(() => {
    const filterProductIds: any = inventoryAuditProductListing
      ?.map((prod) => prod.id)
      ?.filter((id: any) => id !== undefined);

    return filterProductIds || [];
  }, [inventoryAuditProductListing]);

  const hasAuditListing = useMemo(() => {
    return (
      inventoryAuditProductListing && inventoryAuditProductListing?.length > 0
    );
  }, [inventoryAuditProductListing]);

  //check if there is audit descrepancy
  const hasAuditCountMismatch = useMemo(() => {
    if (
      inventoryAuditProductListing &&
      inventoryAuditProductListing.length > 0
    ) {
      const hasMismatchAuditCount = inventoryAuditProductListing.some(
        (product) => product?.audited_count !== product?.available_listing_count
      );

      return hasMismatchAuditCount;
    }
    return false;
  }, [inventoryAuditProductListing]);

  //===functions to use===

  const handleCommonAuditParams = (params: CommonAuditParams) => {
    dispatch(inventoryAuditActions.commonAuditParamsAction(params));
  };

  const handleSelectedAuditProducts = (params: GetBranchCategoryData) => {
    const isExist = productsToBeAudited?.find(
      (prod) => prod.product_id === params.product_id
    );
    if (isExist) {
      snackBar.show({ severity: 'error', message: 'Already added on list' });
      return;
    }
    //add to listing
    dispatch(inventoryAuditActions.productToBeAuditAction(params));
  };

  const handleRemoveSelectedAuditProduct = (prod_id?: number) => {
    if (prod_id) {
      const filteredAuditProductList = productsToBeAudited?.filter((prod) => {
        return prod?.product_id !== prod_id;
      });

      dispatch(
        inventoryAuditActions.newProductToBeAuditAction(
          filteredAuditProductList || []
        )
      );
    }
  };

  const handleNonSnCountAudit = useCallback(
    (product_id?: number, count?: number) => {
      const newInventoryAuditNonSnCount = inventoryAuditProductListing?.reduce(
        (accumulator: any, current: any) => {
          const isExist = product_id === current?.id;
          if (isExist) {
            const newUpdateInventoryAuditListing = {
              ...current,
              audited_count: count
            };
            return [...accumulator, newUpdateInventoryAuditListing];
          }
          return [...accumulator, current];
        },
        []
      );

      dispatch(
        inventoryAuditActions?.scannedProductSnAction(
          newInventoryAuditNonSnCount || []
        )
      );
    },
    [dispatch, inventoryAuditProductListing]
  );

  const resetInventoryAudit = useCallback(() => {
    dispatch(inventoryAuditActions.resetAuditStateAction());
  }, [dispatch]);

  //===endpoints call===

  const submitInventoryAuditLogs = useCallback(async () => {
    try {
      setIsLoading(true);
      const newProductAuditListing = inventoryAuditProductListing?.map(
        (product) => {
          return {
            product_id: product?.id,
            audit_qty: product?.audited_count,
            available_qty: product?.available_listing_count,
            scanned_serial_nos: product?.scanned_serial_nos || []
          };
        }
      );

      const response = unwrapResult(
        await dispatch(
          inventoryAuditActions?.SubmitProductInventoryAuditThunk({
            branch_id: commonAuditParams?.branch_id,
            products: newProductAuditListing
          })
        )
      );

      if (response?.success) {
        snackBar.show({
          severity: 'success',
          message: response?.message,
          useSound: true
        });
        //reset all audit state after success
        dispatch(inventoryAuditActions?.resetAuditStateAction());
      } else {
        //I just get the first error only as it is to complex to display all
        const errorArray: any[] = Object.values(response.errors).flat();
        const firstError = errorArray[0]?.toString();

        snackBar.show({
          severity: 'error',
          message: firstError || 'Something went wrong',
          useSound: true
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [commonAuditParams, dispatch, inventoryAuditProductListing, snackBar]);

  const getProductWithTotalAvailableItem = useCallback(
    async (
      action: 'all' | 'single',
      params?: GetProductWithTotalAvailableItemsPayload
    ) => {
      if (action === 'all') {
        //clear first the add all
        dispatch(inventoryAuditActions.resetInventoryAuditListing());
      }
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(
            productActions?.getProductWithTotalAvailableItemThunk(params)
          )
        );

        if (response?.success) {
          setIsSuccess(true);
          //empty the produuct to be audited to prepare for new list
          dispatch(inventoryAuditActions.newProductToBeAuditAction([]));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const getProductListingViaSerialNo = useCallback(
    async (sn: string) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(
            productActions?.getProductListingViaSerialNoThunk({
              branch_id: commonAuditParams?.branch_id,
              serial_no: cleanSN(sn) || '',
              product_ids: currentProductIdsOnListing
            })
          )
        );

        if (response?.success) {
          const { data } = response?.originalData;

          const newScannedSerializedProduct = inventoryAuditProductListing?.reduce(
            (accumulator: any, current: any) => {
              // Check if the current product matches the product_id
              const isExist = current?.id === data?.product_id;

              if (isExist) {
                const serialNoExists = current?.scanned_serial_nos?.includes(
                  data?.serial_no
                );

                if (!serialNoExists) {
                  snackBar.show({
                    severity: 'success',
                    message: `Successfully audited ${data?.product_name}`,
                    useSound: true
                  });

                  // Update the product with the new serial number and increment the audited_count
                  const updatedProduct = {
                    ...current,
                    audited_count: (current?.audited_count || 0) + 1,
                    scanned_serial_nos: [
                      ...(current?.scanned_serial_nos || []),
                      data?.serial_no
                    ]
                  };
                  return [...accumulator, updatedProduct];
                } else {
                  snackBar?.show({
                    severity: 'error',
                    message: `The serial number is already added to ${data?.product_name}`,
                    useSound: true
                  });
                }
              }

              // return unchanged data
              const productWithEmptySerials = {
                ...current,
                scanned_serial_nos: current?.scanned_serial_nos || []
              };

              return [...accumulator, productWithEmptySerials];
            },
            []
          );

          dispatch(
            inventoryAuditActions?.scannedProductSnAction(
              newScannedSerializedProduct || []
            )
          );
        } else {
          snackBar.show({ severity: 'error', message: response?.message });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      commonAuditParams,
      currentProductIdsOnListing,
      inventoryAuditProductListing,
      snackBar,
      dispatch
    ]
  );

  const getBranchCategoryProduct = useCallback(
    async (params: GetBranchCategoryPayload) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(branchActions?.getBranchCategoryProductThunk(params))
        );

        if (response?.success) {
          setProductOptions(response?.originalData?.data || []);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  return {
    currentProductIdsToBeAudited,
    inventoryAuditProductListing,
    productOptions,
    commonAuditParams,
    productsToBeAudited,
    isSuccess,
    hasAuditCountMismatch,
    isLoading,
    hasAuditListing,

    getBranchCategoryProduct,
    getProductListingViaSerialNo,
    getProductWithTotalAvailableItem,
    handleCommonAuditParams,
    handleSelectedAuditProducts,
    handleRemoveSelectedAuditProduct,
    setIsSuccess,
    handleNonSnCountAudit,
    submitInventoryAuditLogs,
    resetInventoryAudit
  };
};
