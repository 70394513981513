import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DatePicker } from '@material-ui/pickers';

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Typography,
  debounce,
  Theme,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  AccordionDetails,
  Accordion,
  AccordionSummary
} from '@material-ui/core';
import { listingPriceTypeOptions, listingStatusOptions } from 'src/constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import { LoaderBar, Page } from 'src/components';
import SaveIcon from '@material-ui/icons/Save';
import { useNavigate, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Branch,
  CustomInputEvent,
  FixMeLater,
  Listing,
  ProductListingHistoryData,
  warranties
} from 'src/types';
import { Supplier } from 'src/types/supplier';
import { useDebouncedEffect, usePermissions, useSnackBar } from 'src/hooks';
import { cleanSN, toUtcEquivalentDate } from 'src/utils';
import { ListingPriceTypeEnum } from 'src/enums';
import FormAddDialog from 'src/components/dialogs/FormDialog';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import { useBranchInfo } from 'src/hooks/branch/use-branch-info';
import useUserInfo from 'src/hooks/user/use-user-info';
import { ProductUpdateHistory } from '../product-details/ProductUpdateHistory';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: 20
  },
  subHeader: {
    marginBottom: 20
  },
  formControl: {
    marginBottom: theme.spacing(2)
  },
  customerInfoCard: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  marginLeft: {
    marginLeft: theme.spacing(0.5)
  }
}));

const {
  actions: supplierActions,
  selectors: supplierSelectors
} = slices.supplier;
const { actions: listingActions } = slices.listing;
const { actions: productActions } = slices.product;

const ProductListingView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const snackBar = useSnackBar();
  const { canUpdateListing, canSeeDealersPrice } = usePermissions();
  const {
    branches,
    getBranches,
    branchInputValue,
    setBranchInputValue,
    getBranchDetails
  } = useBranchInfo();
  const { userBranchOptions } = useUserInfo();

  const newBranchesOptions = useMemo(() => {
    return branches.filter((branch) =>
      userBranchOptions.some(
        (userBranch) => userBranch.name === branch.branch_name
      )
    );
  }, [branches, userBranchOptions]);

  const { id: listingId } = useParams();

  const suppliers = useAppSelector(supplierSelectors.selectSupplierList);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listingDetails, setListingDetails] = useState<Listing | undefined>();
  const [warrantyInput, setWarrantyInput] = useState('');
  const [supplierInput, setSupplierInput] = useState('');

  const [isCreateSupplierVisible, setIsCreateSupplierVisible] = useState<
    boolean
  >(false);
  const [addSupplierLoading, setAddSupplierLoading] = useState<boolean>(false);
  const [errFields, setErrFields] = useState({
    dr_no: '',
    purchaseDate: '',
    supplier: '',
    warranty: '',
    serial_no: ''
  });
  const [listingHistoryData, setListingHistoryData] = useState<
    ProductListingHistoryData[]
  >([]);
  const [isLoadingListingHistory, setIsLoadingHistory] = useState<boolean>(
    false
  );
  const [expanded, setExpanded] = useState<boolean>(false);

  const fieldValidations = [
    {
      field: 'dr_no',
      value: listingDetails?.dr_no,
      errorMessage: 'DR NO IS REQUIRED'
    },
    {
      field: 'purchaseDate',
      value: listingDetails?.purchase_date,
      errorMessage: 'PURCHASE DATE ON SUPPLIER IS REQUIRED'
    },
    {
      field: 'supplier',
      value: listingDetails?.supplier,
      errorMessage: 'SUPPLIER IS REQUIRED'
    },
    {
      field: 'warranty',
      value: warrantyInput,
      errorMessage: 'WARRANTY IS REQUIRED'
    },
    {
      field: 'serial_no',
      value: listingDetails?.serial_no,
      errorMessage: 'SERIAL NUMBER IS REQUIRED! '
    }
  ];

  // eslint-disable-next-line no-undef
  const onChangeBranch = async (branch: Partial<Branch> | null) => {
    const branchDetails = branch
      ? (await getBranchDetails(branch)).originalData?.data
      : null;
    if (branchDetails) {
      setListingDetails((prev) => {
        if (prev?.id) {
          return {
            ...prev,
            branch: branchDetails ?? undefined,
            branch_id: branchDetails?.id
          };
        }
      });
    }
  };

  const onOpenCreateSupplierDialog = () => {
    setIsCreateSupplierVisible(true);
  };

  const onCloseCreateSupplierDialog = () => {
    setIsCreateSupplierVisible(false);
    setAddSupplierLoading(false);
  };

  const getProductListingHistoryUpdates = useCallback(
    async (serialNo?: string) => {
      try {
        setIsLoadingHistory(true);
        const response = unwrapResult(
          await dispatch(
            productActions.getProductListingUpdateHistoryThunk(serialNo)
          )
        );
        if (response?.success) {
          setIsLoadingHistory(false);
          const { data } = response?.originalData;
          setListingHistoryData(data || []);
        }
      } catch (error) {
        setIsLoadingHistory(false);
        console.error(error);
      } finally {
        setIsLoadingHistory(false);
      }
    },
    [dispatch]
  );

  const onAddSupplierOnModalPress = async (name: string) => {
    // TODO: Add checker loader before close.
    setAddSupplierLoading(true);
    if (name) {
      const response = unwrapResult(
        await dispatch(supplierActions.createSupplierThunk({ name }))
      );
      if (response.success) {
        snackBar.show({ severity: 'success', message: response.message });
        onCloseCreateSupplierDialog();
      } else {
        snackBar.show({ severity: 'error', message: response.message });
        setAddSupplierLoading(false);
      }
    } else {
      snackBar.show({
        severity: 'error',
        message: 'Please fill up all required information.'
      });
    }
  };

  const areFieldsValid = () => {
    let areValid = true;

    fieldValidations.forEach((validation) => {
      const { field, value, errorMessage } = validation;
      if (!value) {
        setErrFields((prev) => ({ ...prev, [field]: errorMessage }));
        areValid = false;
      } else {
        setErrFields((prev) => ({ ...prev, [field]: '' }));
      }
    });

    return areValid;
  };

  const validateField = (name: string, value: any) => {
    const targetValidation = fieldValidations.find(
      (validation) => validation.field === name
    );

    if (targetValidation) {
      const { field, errorMessage } = targetValidation;

      if (!value) {
        setErrFields((prev) => ({ ...prev, [field]: errorMessage }));
      } else {
        setErrFields((prev) => ({ ...prev, [field]: '' }));
      }
    }
  };

  const warrantyValue = useMemo(() => {
    const x = warranties?.find(
      (x) => x.value === listingDetails?.warranty_duration
    );
    return x ?? null;
  }, [listingDetails]);

  const isDealersPriceFieldDisabled = useMemo(
    () =>
      listingDetails?.price_type === ListingPriceTypeEnum.FreeFromSupplier ||
      listingDetails?.price_type === ListingPriceTypeEnum.FreeForAll,
    [listingDetails]
  );

  const isRetailPriceFieldDisabled = useMemo(
    () => listingDetails?.price_type === ListingPriceTypeEnum.FreeForAll,
    [listingDetails]
  );

  const getSuppliers = debounce(async (keyword: string = '') => {
    dispatch(supplierActions.getSuppliersThunk({ keyword }));
  }, 500);

  const handleChangeFreebies = (event: FixMeLater) => {
    if (listingDetails?.id) {
      if (event.target.value === ListingPriceTypeEnum.NotFree) {
        setListingDetails((prev) => ({
          ...prev,
          id: listingDetails?.id,
          dealers_price: 0,
          price_type: event.target.value
        }));
      }
      if (event.target.value === ListingPriceTypeEnum.FreeFromSupplier) {
        setListingDetails((prev) => ({
          ...prev,
          id: listingDetails?.id,
          dealers_price: 0,
          price_type: event.target.value
        }));
      }
      if (event.target.value === ListingPriceTypeEnum.FreeForAll) {
        setListingDetails((prev) => ({
          ...prev,
          id: listingDetails?.id,
          dealers_price: 0,
          retail_price: 0,
          price_type: event.target.value
        }));
      }
    }
  };

  const handleChangeStatus = (event: FixMeLater) => {
    if (listingDetails?.id) {
      setListingDetails((prev) => ({
        ...prev,
        id: listingDetails?.id,
        status: event.target.value
      }));
    }
  };

  const updateItem = async () => {
    // This should be the only ones editable.
    if (!canUpdateListing) {
      return;
    }

    if (!areFieldsValid()) {
      snackBar.show({
        severity: 'error',
        message: 'Please complete required fields',
        useSound: true
      });
      return;
    }

    if (listingDetails) {
      let sn = listingDetails.serial_no;
      setIsLoading(true);
      const data: Listing = {
        ...listingDetails,
        serial_no: sn ? cleanSN(sn) : ''
      };

      const response = unwrapResult(
        await dispatch(listingActions.updateListingsThunk(data)).finally(() =>
          setIsLoading(false)
        )
      );
      if (response?.success && response?.originalData?.listing) {
        getListingDetails().finally(() => {
          snackBar.show({ severity: 'success', message: response?.message });
        });
        return;
      }

      // if not success and has error message
      if (!response?.success && response?.errors) {
        snackBar.show({
          severity: 'error',
          message: `${JSON.stringify(response?.errors)}`
        });
        return;
      }

      snackBar.show({ severity: 'error', message: 'Listing not updated.' });
    }
  };

  const getListingDetails = useCallback(async () => {
    if (listingId) {
      setIsLoading(true);
      const response = unwrapResult(
        await dispatch(
          listingActions.getListingsViaIdThunk(+listingId)
        ).finally(() => setIsLoading(false))
      );
      if (response?.success && response.originalData.listing) {
        setListingDetails(response.originalData.listing);
        getProductListingHistoryUpdates(
          response?.originalData?.listing?.serial_no
        );
      }
    }
  }, [dispatch, getProductListingHistoryUpdates, listingId]);

  const handleChange = (event: CustomInputEvent) => {
    const { name, value } = event.target;
    let modifiedValue = value;
    if (name === 'dr_no') {
      modifiedValue = modifiedValue.toUpperCase();
    }
    setListingDetails((prev) => {
      if (prev?.id) {
        return {
          ...prev,
          [name]: modifiedValue
        };
      }
    });
  };

  const setPurchaseDate = (date: Date | null) => {
    setListingDetails((prev) => {
      if (prev?.id) {
        return {
          ...prev,
          purchase_date: date
            ? toUtcEquivalentDate(date)?.toISOString()
            : undefined
        };
      }
    });
  };

  const onUpdateSupplier = (supplier: Supplier | null) => {
    setListingDetails((prev) => {
      if (prev?.id) {
        return {
          ...prev,
          supplier: supplier ?? undefined,
          supplier_id: supplier?.id
        };
      }
    });
  };

  const onUpdateWarranty = (warranty: FixMeLater | null) => {
    setListingDetails((prev) => {
      if (prev?.id) {
        return {
          ...prev,
          warranty_duration: warranty?.value ?? undefined
        };
      }
    });
  };

  useEffect(() => {
    getListingDetails();
  }, [getListingDetails]);

  useDebouncedEffect(() => getSuppliers(supplierInput), 500, [supplierInput]);
  useDebouncedEffect(() => getBranches(), 500, [branchInputValue]);

  return (
    <Page
      className={classes.root}
      title={`Listing Edit ${listingDetails?.serial_no || ''}`}
    >
      <LoaderBar isLoading={isLoading} />
      <Container maxWidth={false}>
        <Box mt={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mb={2}
          >
            <Typography
              color="textPrimary"
              variant="h3"
            >{`Listing Edit ${listingDetails?.serial_no || ''}`}</Typography>
          </Box>

          <Card
            hidden={!listingDetails?.purchase_info}
            className={classes.customerInfoCard}
          >
            <CardContent>
              <Typography className={classes.subHeader} variant="h4">
                Customer Information
              </Typography>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography display="inline" variant="h5">
                    Transaction No:
                    <Link
                      className={classes.marginLeft}
                      component="button"
                      variant="body1"
                      onClick={() =>
                        navigate(
                          `/app/transaction/${listingDetails?.purchase_info?.transaction_no}`
                        )
                      }
                      display="inline"
                    >
                      {` ${listingDetails?.purchase_info?.transaction_no ??
                        'Not Available'}`}
                    </Link>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography display="inline" variant="h5">
                    Customer Full Name:
                    <Link
                      className={classes.marginLeft}
                      component="button"
                      variant="body1"
                      onClick={() =>
                        navigate(
                          `/app/customers/${
                            listingDetails?.purchase_info?.customer_id
                          }?is_ecomm_user=${
                            listingDetails?.purchase_info?.is_ecomm_user ? 1 : 0
                          }`
                        )
                      }
                      display="inline"
                    >
                      {` ${listingDetails?.purchase_info?.full_name ??
                        'Not Available'}`}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography className={classes.subHeader} variant="h6">
                General Information
              </Typography>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Part no. (optional)"
                    name="part_no"
                    onChange={(e) => {
                      handleChange(e);
                      validateField(e?.target?.name, e?.target?.value);
                    }}
                    value={listingDetails?.part_no ?? ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    type="sku"
                    label="SKU"
                    name="sku"
                    onChange={handleChange}
                    value={listingDetails?.sku ?? ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={errFields?.serial_no ? true : false}
                    required
                    fullWidth
                    name="serial_no"
                    label="Serial no."
                    variant="outlined"
                    onChange={(e) => {
                      handleChange(e);
                      validateField(e?.target?.name, e?.target?.value);
                    }}
                    value={listingDetails?.serial_no ?? ''}
                    disabled={listingDetails?.purchase_info ? true : false}
                    helperText={
                      <>
                        {errFields?.serial_no}
                        <br />
                        Warning: Editing this while on a transaction might
                        result to inconsistencies
                      </>
                    }
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={listingDetails?.status ?? ''}
                      onChange={handleChangeStatus}
                      label="Status"
                    >
                      {listingStatusOptions?.map((item) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography className={classes.subHeader} variant="h6">
                Pricing
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Price Type</InputLabel>
                    <Select
                      value={listingDetails?.price_type ?? ''}
                      onChange={handleChangeFreebies}
                      label="Price Type"
                    >
                      {listingPriceTypeOptions?.map((item) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {canSeeDealersPrice ? (
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      disabled={isDealersPriceFieldDisabled}
                      label="Dealers Price"
                      name="dealers_price"
                      onChange={handleChange}
                      value={listingDetails?.dealers_price ?? ''}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₱</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                ) : null}

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    type="number"
                    disabled={isRetailPriceFieldDisabled}
                    label="Retail Price"
                    name="retail_price"
                    onChange={handleChange}
                    value={listingDetails?.retail_price ?? ''}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₱</InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography className={classes.subHeader} variant="h6">
                Other Information
              </Typography>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Box>
                    {/* TODO: https://v4.mui.com/components/autocomplete/#FreeSoloCreateOptionDialog.tsx */}
                    <Autocomplete
                      value={listingDetails?.supplier ?? null}
                      onChange={(_, newValue) => {
                        onUpdateSupplier(newValue);
                        validateField('supplier', newValue);
                      }}
                      onInputChange={(_, newInputValue) => {
                        setSupplierInput(newInputValue);
                      }}
                      noOptionsText={`"${supplierInput}" has not been found in suppliers. Please add it first`}
                      id="supplier"
                      options={suppliers}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option?.name}
                      renderInput={(params) => (
                        <TextField
                          error={errFields?.supplier ? true : false}
                          helperText={errFields?.supplier}
                          {...params}
                          required
                          label="Supplier"
                          variant="outlined"
                        />
                      )}
                    />
                    <Button
                      style={{ padding: 0, paddingTop: 5, paddingLeft: 5 }}
                      onClick={onOpenCreateSupplierDialog}
                      color="primary"
                      variant="text"
                    >
                      add a supplier
                    </Button>
                  </Box>
                </Grid>
                {multiBranchFeat ? (
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      value={listingDetails?.branch ?? null}
                      onChange={(_, newValue) => {
                        onChangeBranch(newValue);
                      }}
                      inputValue={branchInputValue}
                      onInputChange={(_, newInputValue) => {
                        setBranchInputValue(newInputValue);
                      }}
                      noOptionsText={`No such Branch.`}
                      id="branch"
                      options={newBranchesOptions}
                      getOptionLabel={(option) => option.branch_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Branch"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                ) : null}
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    // freeSolo
                    value={warrantyValue}
                    onChange={(_, newValue) => {
                      onUpdateWarranty(newValue);
                      validateField('warranty', newValue);
                    }}
                    inputValue={warrantyInput}
                    onInputChange={(_, newInputValue) => {
                      setWarrantyInput(newInputValue);
                    }}
                    id="warranty"
                    options={warranties}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        error={errFields?.warranty ? true : false}
                        helperText={errFields?.warranty}
                        {...params}
                        required
                        label="Warranty Duration (days)"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DatePicker
                    error={errFields?.purchaseDate ? true : false}
                    helperText={errFields?.purchaseDate}
                    fullWidth
                    // maxDate={new Date()}
                    format="MMMM dd, yyyy"
                    inputVariant="outlined"
                    name="purchase_date"
                    label="Purchase Date on Supplier"
                    value={listingDetails?.purchase_date ?? null}
                    onChange={(date) => setPurchaseDate(date)}
                    animateYearScrolling
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={errFields?.dr_no ? true : false}
                    helperText={errFields?.dr_no}
                    fullWidth
                    required
                    label="DR No."
                    name="dr_no"
                    onChange={(e) => {
                      handleChange(e);
                      validateField(e?.target?.name, e?.target?.value);
                    }}
                    value={listingDetails?.dr_no ?? ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            {canUpdateListing ? (
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  startIcon={<SaveIcon />}
                  style={{ marginLeft: 18 }}
                  onClick={updateItem}
                  color="primary"
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            ) : null}
          </Card>
          <FormAddDialog
            loading={addSupplierLoading}
            fieldName="Supplier"
            title="Create Supplier"
            isVisible={isCreateSupplierVisible}
            subTitle="Input Supplier"
            onAddPress={onAddSupplierOnModalPress}
            handleClose={onCloseCreateSupplierDialog}
          />
        </Box>

        <Paper style={{ marginTop: '2rem' }} elevation={0}>
          <Accordion
            onChange={() => setExpanded(!expanded)}
            style={{ marginBottom: '1.5rem', padding: '0.5rem' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ padding: 0 }}>
                <Typography variant="h5">{`View Update/Edit History For Serial No.${listingDetails?.serial_no}`}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '1rem' }}>
              <ProductUpdateHistory
                dialogViews={false}
                isOpen={expanded}
                serialNo={listingDetails?.serial_no}
                handleClose={() => {}}
                expandFirstCard={true}
                productListingHistory={listingHistoryData}
                isLoadingData={isLoadingListingHistory}
              />
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Container>
    </Page>
  );
};

export default ProductListingView;
