import { Box, Card, CardContent, Typography } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { BundleDetailsV2 } from 'src/redux/slices/pc-bundles/types';
import { Manufacturer } from 'src/constants/inventory';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { FixMeLater } from 'src/types';
import { LoaderBar } from 'src/components';

const {
  selectors: manufacturerSelectors,
  actions: manufacturerActions
} = slices.manufacturer;

const {
  selectors: pcBundleSelectors,
  actions: pcBundleActions
} = slices.pcBundles;

interface Props {
  bundle?: BundleDetailsV2;
}

const SelectManufacturerV2: FC<Props> = ({ bundle }) => {
  const dispatch = useAppDispatch();
  const manufacturerID = useAppSelector(pcBundleSelectors.selectManufacturer);

  const swiperRef = useRef<FixMeLater>(null);

  const manufacturers = useAppSelector(
    manufacturerSelectors.selectManufacturers
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [manufacturerVal, setManufacturerVal] = useState<Manufacturer | null>(
    null
  );

  const onChangeManufacturer = useCallback(
    (value: Manufacturer | null) => {
      value && dispatch(pcBundleActions.setManufacturer(value.id));
    },
    [dispatch]
  );

  const selectedBorder = useCallback(
    (id: number) => {
      return id === manufacturerVal?.id ? '3px solid #d23f57' : '';
    },
    [manufacturerVal]
  );

  const getManufacturers = useCallback(async () => {
    setIsLoading(true);
    await dispatch(
      manufacturerActions.getManufacturersThunk({ keyword: 'powered by' })
    ).finally(() => setIsLoading(false));
  }, [dispatch]);

  const manufacturerTitle = useCallback((val: string) => {
    return val?.replace(/powered by/i, '').trim();
  }, []);

  useEffect(() => {
    getManufacturers();
  }, [getManufacturers]);

  useEffect(() => {
    bundle?.manufacturer &&
      dispatch(pcBundleActions.setManufacturer(bundle?.manufacturer?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundle]);

  useEffect(() => {
    const manufacturer = manufacturers.find((i) => i.id === manufacturerID);
    setManufacturerVal(manufacturer || null);
  }, [manufacturerID, manufacturers]);

  return (
    <Box>
      {isLoading ? (
        <LoaderBar isLoading={isLoading} />
      ) : (
        <Swiper
          spaceBetween={10}
          slidesPerView={'auto'}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          style={{ height: '100%', padding: '.2em 1em .2em 1em' }}
        >
          {manufacturers?.[0] &&
            manufacturers.map((i, ind) => (
              <SwiperSlide
                onClick={() => onChangeManufacturer(i)}
                key={ind}
                style={{ width: 'fit-content' }}
              >
                <Card>
                  <CardContent
                    style={{ border: selectedBorder(i.id), padding: '.4em' }}
                  >
                    <Typography variant="h4">
                      {manufacturerTitle(i.name)}
                    </Typography>
                  </CardContent>
                </Card>
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </Box>
  );
};
export default SelectManufacturerV2;
