import { Card, CardContent, Container, makeStyles } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { LoaderBar, Page } from 'src/components';
import Toolbar from './components/Toolbar';
import { BundleCard } from './components/BundleCard';
import { slices, useAppSelector } from 'src/redux';

interface Props {}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const { selectors: pcBundleSelectors } = slices.pcBundles;

const PCBundleListView: FC<Props> = () => {
  const classes = useStyles();

  const bundles = useAppSelector(pcBundleSelectors.selectBundleList);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Page className={classes.root} title="PC Bundles">
      <Container maxWidth={false}>
        <Toolbar setIsLoading={(val) => setIsLoading(val)} />
      </Container>
      <Container maxWidth={false}>
        <LoaderBar isLoading={isLoading} />
        <Card
          style={{
            marginTop: '1em'
          }}
        >
          <CardContent>
            {bundles[0] &&
              bundles.map((i, ind) => <BundleCard key={ind} bundle={i} />)}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default PCBundleListView;
