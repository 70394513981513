import React, { FC, memo, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  Page,
  View,
  Text,
  PDFViewer,
  Document,
  Image,
  StyleSheet
} from '@react-pdf/renderer';
import {
  BundleComponent,
  PrintComponentDetails
} from 'src/redux/slices/pc-bundles/types';
import { RenderCurrency } from './RenderCurrency';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row'
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5
  },
  renderPageNum: {
    flexDirection: 'row',
    width: '100%',
    border: 1,
    padding: 5
  },
  brand: {
    fontFamily: 'Helvetica-Bold'
  },
  footer: {
    marginTop: 'auto',
    flexDirection: 'row',
    width: '100%'
  },
  boldTextContainer: {
    flexDirection: 'row',
    marginBottom: '5px'
  },
  textBold: {
    fontSize: '12px',
    fontFamily: 'Helvetica-Bold'
  },
  textNormal: { fontSize: '12px' },
  marginTop: {
    marginTop: '20px'
  },
  productsContainer: {
    flexDirection: 'row',
    marginTop: '10px'
  },
  textAfterBold: {
    marginLeft: '5px',
    fontSize: '12px'
  },
  parentView: {
    flexDirection: 'column',
    padding: 30,
    height: '100%'
  },
  nameAndDate: {
    marginTop: 5,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'space-between'
  }
});

interface Props {
  isOpen?: boolean;
  printDetails?: PrintComponentDetails;
  qrSrc?: string;
  toggleDialogOpen?: () => void;
}

const component: FC<Props> = ({
  isOpen = false,
  printDetails,
  qrSrc,
  toggleDialogOpen
}) => {
  const maxLinesPerPage = 49; // max lines for optimal ui layout

  const components = useMemo(() => {
    return printDetails?.components;
  }, [printDetails]);

  const componentsPerPage = useMemo(() => {
    if (!components?.length) {
      return [];
    }

    let dataPerPage: BundleComponent[] = [];
    let allData: BundleComponent[][] = [];
    const maxCharsPerLine = 66;
    let currentPageLines = 0; // Track total characters on the current page
    let linesTakenByComponent = 0;

    components.forEach((component, i) => {
      const nameLength = component?.product_name?.length || 0;
      linesTakenByComponent = Math.ceil(nameLength / maxCharsPerLine) + 2;

      // Check if adding the new item would exceed the max lines per page
      if (currentPageLines > maxLinesPerPage) {
        // Push the current page's data and start a new one
        allData.push(dataPerPage);
        dataPerPage = [];
        currentPageLines = 0; // Reset lines count for the new page
      }

      // Add the current component to the page
      dataPerPage.push(component);
      currentPageLines += linesTakenByComponent;

      // Push remaining data to allData if it's the last component
      if (i === components.length - 1 && dataPerPage.length) {
        allData.push(dataPerPage);
      }
    });

    return allData;
  }, [components]);

  return (
    <Dialog maxWidth={'md'} fullWidth open={isOpen} onClose={toggleDialogOpen}>
      <DialogTitle>
        <Typography variant="h4">Component Group</Typography>
        <IconButton
          aria-label="close"
          style={styles.closeButton}
          color="secondary"
          onClick={toggleDialogOpen}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PDFViewer
          width="100%"
          height="100%"
          style={{ height: '80vh', width: '100%' }}
        >
          <Document title={'Component Group'}>
            {componentsPerPage?.[0] &&
              componentsPerPage.map((i, ind) => (
                <Page key={ind} size={'A4'}>
                  <View style={styles.parentView}>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <View style={{ width: '50%' }}>
                        <Text style={styles.textNormal}>Powered By:</Text>
                        <Text style={styles.brand}>{printDetails?.brand}</Text>
                      </View>
                      <View style={{ width: '50%', height: '20px' }}>
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <Text style={styles.textBold}>Serial No:</Text>
                          <Text style={styles.textAfterBold}>
                            {printDetails?.serial_no}
                          </Text>
                        </View>
                        {/* overflow view below is intentionally overflowed from container to save space */}
                        <View
                          id="overflow-view"
                          style={{
                            marginTop: '20px',
                            flexDirection: 'row',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <Image
                            src={qrSrc}
                            style={{
                              height: 60,
                              width: 'auto',
                              objectFit: 'cover'
                            }}
                          />
                        </View>
                      </View>
                    </View>
                    <View
                      id="bundle-title-and-group-name"
                      style={styles.marginTop}
                    >
                      <View style={styles.boldTextContainer}>
                        <Text style={styles.textBold}>Title:</Text>
                        <Text style={styles.textAfterBold}>
                          {printDetails?.title}
                        </Text>
                      </View>
                      <View style={styles.boldTextContainer}>
                        <Text style={styles.textBold}>Group Name:</Text>
                        <Text style={styles.textAfterBold}>
                          {printDetails?.group_name}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.marginTop}>
                      <Text style={styles.textBold}>Products</Text>
                    </View>
                    <View>
                      {i?.[0] &&
                        i.map((i) => (
                          <View
                            key={i.serial_no}
                            style={styles.productsContainer}
                          >
                            <View
                              style={{ flexDirection: 'column', width: '85%' }}
                            >
                              <Text style={styles.textNormal}>
                                {i.product_name}
                              </Text>
                              <Text style={{ fontSize: '10px', color: 'grey' }}>
                                {i.serial_no}
                              </Text>
                            </View>
                            <View
                              style={{ width: '15%', alignItems: 'center' }}
                            >
                              <RenderCurrency amount={i.retail_price || 0} />
                            </View>
                          </View>
                        ))}
                    </View>
                    <View style={styles.footer}>
                      <View style={{ width: '50%' }}>
                        <View style={styles.boldTextContainer}>
                          <Text style={styles.textBold}>PREPARED BY:</Text>
                          <Text style={styles.textAfterBold}>
                            {printDetails?.prepared_by}
                          </Text>
                        </View>
                        <View style={styles.boldTextContainer}>
                          <Text style={styles.textBold}>TIME STAMP:</Text>
                          <Text style={styles.textAfterBold}>
                            {printDetails?.time_stamp}
                          </Text>
                        </View>
                      </View>
                      <View style={{ width: '50%' }}>
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginBottom: '5px'
                          }}
                        >
                          <Text style={styles.textBold}>TOTAL AMOUNT:</Text>
                          <RenderCurrency
                            amount={printDetails?.total_amount || 0}
                          />
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <Text style={styles.textBold}>{`Page ${ind + 1} of ${
                            componentsPerPage.length
                          } `}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </Page>
              ))}
          </Document>
        </PDFViewer>
      </DialogContent>
    </Dialog>
  );
};

export const ComponentGroupPrintModal = memo(component);
