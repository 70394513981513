import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { CustomInputEvent, CustomKeyboardEvent } from 'src/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import ClearIcon from '@material-ui/icons/Clear';

const {
  selectors: pcBundleSelectors,
  actions: pcBundleActions
} = slices.pcBundles;

interface Props {
  className?: string;
  setIsLoading: (v: boolean) => void;
}

const Toolbar = ({ className, setIsLoading, ...rest }: Props) => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(pcBundleSelectors.selectStatusFilter);
  const keyword = useAppSelector(pcBundleSelectors.selectKeyword);
  const sort = useAppSelector(pcBundleSelectors.selectSort);

  const [statusVal, setStatusVal] = useState<string>('');
  const [sortVal, setSortVal] = useState<string>('desc');
  const [keywordVal, setKeywordVal] = useState<string>('');

  const getBundles = useCallback(async () => {
    setIsLoading(true);
    await dispatch(pcBundleActions.getPCBundleListThunk()).finally(() =>
      setIsLoading(false)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, keyword, sortVal, statusVal]);

  // any type bandaid
  const onChangeStatus = (e: any) => {
    console.log(e.target.value, 'onChangeStatus');
    dispatch(pcBundleActions.setStatusFilter(e.target.value));
  };

  // any type bandaid
  const onChangeSort = (e: any) => {
    dispatch(pcBundleActions.setSort(e.target.value));
  };

  const onChangeKeyword = (event: CustomInputEvent) => {
    dispatch(pcBundleActions.setKeyword(event.target.value));
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      getBundles();
    }
  };
  useEffect(() => {
    getBundles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSortVal(sort);
  }, [sort]);

  useEffect(() => {
    setStatusVal(status);
  }, [status]);

  useEffect(() => {
    setKeywordVal(keyword);
  }, [keyword]);

  return (
    <div className={className} {...rest}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography color="textPrimary" gutterBottom variant="h3">
          PC Bundles
        </Typography>
        <Button href="/app/pc-bundles/add" color="default" variant="contained">
          Create PC Bundle
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid alignItems="center" container spacing={2}>
              <Grid item lg={5} md={6} xs={12}>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    value={keywordVal}
                    onChange={onChangeKeyword}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search PC Bundle"
                    variant="outlined"
                    onKeyPress={onKeyPress}
                  />
                </Box>
              </Grid>
              <Grid item lg md sm xs>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Select Status</InputLabel>
                  <Select
                    value={statusVal}
                    label="Select Status"
                    onChange={onChangeStatus}
                    endAdornment={
                      statusVal && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            dispatch(pcBundleActions.setStatusFilter(''))
                          }
                          style={{ margin: '.8em' }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value={'unpublished'}>Unpublished</MenuItem>
                    <MenuItem value={'published'}>Published</MenuItem>
                    <MenuItem value={'purchased'}>Purchased</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg md sm xs>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Sort By</InputLabel>
                  <Select
                    label="Sort By"
                    value={sortVal}
                    onChange={onChangeSort}
                  >
                    <MenuItem value={'desc'}>Latest to Oldest</MenuItem>
                    <MenuItem value={'asc'}>Oldest to Latest</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                alignItems="center"
                item
                lg={2}
                md
                sm
                xs
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  onClick={getBundles}
                  color="default"
                  variant="contained"
                >
                  Apply Filter
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default Toolbar;
