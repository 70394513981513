import React, { FC, useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { DragAndDropImgUpload } from 'src/components';
import { convertImageFileToBase64 } from 'src/utils';
import { cloneDeep } from 'lodash';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { DeletableImg } from './DeletableImg';
import { BundleDetailsV2 } from 'src/redux/slices/pc-bundles/types';

const useStyles = makeStyles((theme) => ({
  previewDiv: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const {
  selectors: pcBundleSelectors,
  actions: pcBundleActions
} = slices.pcBundles;

interface Props {
  bundle?: BundleDetailsV2;
}

const component: FC<Props> = ({ bundle }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const imgs = useAppSelector(pcBundleSelectors.selectImages);

  const onDeleteImage = async (index: number) => {
    const clonedData = cloneDeep(imgs);
    clonedData?.splice(index, 1);
    clonedData && dispatch(pcBundleActions.setImages(clonedData));
  };

  const imageConverter = (images?: string[]) => {
    return images ? images.map((i) => ({ file: i })) : [];
  };

  const onPhotosChangeInternal = async (img: File[]) => {
    if (img && img?.length > 0) {
      let imgsArr: any[] = await Promise.all(
        img?.map(
          async (item): Promise<any> => {
            const newImg = {
              file: await convertImageFileToBase64(item)
            };
            return newImg;
          }
        )
      );
      const clonedDataImgs = cloneDeep(imgs) || [];
      const mergedImgs = [...clonedDataImgs, ...imgsArr];
      dispatch(pcBundleActions.setImages(mergedImgs));
    }
  };

  useEffect(() => {
    if (bundle?.images?.[0]) {
      dispatch(pcBundleActions.setImages(imageConverter(bundle?.images)));
    }
  }, [bundle, dispatch]);

  return (
    <Card style={{ marginTop: '1em' }}>
      <CardContent>
        <Typography>Bundle Images</Typography>
        <Grid container spacing={2} className={classes.previewDiv}>
          {imgs?.map((i, ind) => (
            <Grid key={ind} item>
              <DeletableImg index={ind} imgSrc={i} onDelete={onDeleteImage} />
            </Grid>
          ))}
        </Grid>
        <DragAndDropImgUpload
          title="Drag or select photos"
          onImageSelected={onPhotosChangeInternal}
        />
      </CardContent>
    </Card>
  );
};

export const AddBundleImages = React.memo(component);
