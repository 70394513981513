import { createAsyncThunk } from '@reduxjs/toolkit';
// import { id } from 'date-fns/locale';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import {
  GetBannersResponse,
  UploadBannerRequest,
  UploadBannersResponse,
  DeleteBannersResponse,
  UpdateBannerResponse,
  UpdateBannerRequest,
  UpdateBannerArrangementRequest,
  UpdateBannerArrangementResponse
} from 'src/types/ecomm/banner';

export const getEcommBannersThunk = createAsyncThunk<
  CommonAxiosResponse<GetBannersResponse>,
  undefined,
  { state: RootState }
>('ecomm/banner/getEcommBannersThunk', async () => {
  const response = await Api.get('/ecomm/internal/banner/get', { limit: 100 });
  return response;
});

//upload
export const uploadEcommBannersThunk = createAsyncThunk<
  CommonAxiosResponse<UploadBannersResponse>,
  UploadBannerRequest,
  { state: RootState }
>('ecomm/banner/uploadEcommBannersThunk', async (data) => {
  const response = await Api.post('/ecomm/internal/banner/upload', data);
  return response;
});

//delete
export const deleteEcomBannerThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteBannersResponse>,
  number,
  { state: RootState }
>('ecomm/banner/bannerDeleteThunk', async (id) => {
  const response = await Api.delete(`/ecomm/internal/banner/delete/${id}`);
  return response;
});

export const updateBannerArrangementThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateBannerArrangementResponse>,
  UpdateBannerArrangementRequest,
  { state: RootState }
>('gallery/updateBannerArrangementThunk', async (data) => {
  const response = await Api.patch('/ecomm/internal/banner/update', data);

  return response;
});

//Update
export const updateBannerThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateBannerResponse>,
  UpdateBannerRequest,
  { state: RootState }
>('gallery/updateBannerThunk', async (data) => {
  const response = await Api.patch(
    `/ecomm/internal/banner/update/${data.id}`,
    data
  );

  return response;
});
