import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  TextField
} from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { FC, useState } from 'react';
import { LoadingButton, SelectImageButton } from 'src/components';
import { ErrorDialog } from 'src/components/error/ErrorDialog';
import { useSnackBar } from 'src/hooks';
import { useErrDialog } from 'src/hooks/use-error-dialog';
import { useAppDispatch } from 'src/redux';
import { createGameThunk } from 'src/redux/slices/games/thunks';
import { CustomKeyboardEvent } from 'src/types';
import { convertImageFileToBase64 } from 'src/utils';

interface Props {
  open: boolean;
  getGames: (keyword?: string) => void;
  handleClose: () => void;
}

export const AddGameDialog: FC<Props> = ({ open, handleClose, getGames }) => {
  const snackBar = useSnackBar();
  const dispatch = useAppDispatch();
  const {
    errs,
    setErrs,
    showErr,
    setShowErr,
    handleErrorFromResponse
  } = useErrDialog();

  const [name, setName] = useState<string>('');
  const [errName, setErrName] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [addGameLoading, setAddGameLoading] = useState<boolean>(false);

  const onAddPress = async () => {
    setAddGameLoading(true);
    const res = unwrapResult(
      await dispatch(createGameThunk({ name, icon: logoUrl }))
    );
    if (res.success) {
      snackBar.show({
        severity: 'success',
        useSound: true,
        message: 'Game created'
      });
      setErrs([]);
      resetState();
      getGames();
    } else if (res.errors) {
      handleErrorFromResponse(res);
      setShowErr(true);
      snackBar.show({
        severity: 'error',
        useSound: true,
        message: 'Failed to create game, Re check input or try again later'
      });
    } else {
      snackBar.show({
        severity: 'error',
        useSound: true,
        message: 'Failed to create game, Re check input or try again later'
      });
    }
    setAddGameLoading(false);
  };

  const onImageSelected = async (img: File[]) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);
      setLogoUrl(imgInBase64);
    }
  };

  const resetState = () => {
    setName('');
    setLogoUrl('');
    setErrName('');
  };

  const onAddBtnPress = () => {
    if (addGameLoading) {
      return;
    }
    if (!name) {
      setErrName('Invalid Value');
      return;
    }
    if (!logoUrl) {
      snackBar.show({
        severity: 'error',
        message: `Game Image Logo is Required`
      });
      return;
    }
    onAddPress();
  };

  const onCancelBtnPress = () => {
    resetState();
    handleClose();
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onAddBtnPress();
    }
  };
  return (
    <Dialog open={open}>
      <ErrorDialog
        open={showErr}
        errs={errs}
        closeDialog={() => setShowErr(false)}
      />
      <DialogTitle>Create Game</DialogTitle>
      <DialogContent>
        <DialogContentText>Input Game</DialogContentText>
        <TextField
          error={errName === '' ? false : true}
          helperText={errName}
          autoFocus
          margin="dense"
          id="name"
          label={'Game Title'}
          fullWidth
          required
          variant="outlined"
          value={name}
          onKeyPress={onKeyPress}
          onChange={(e) => {
            setErrName('');
            setName(e.target.value);
          }}
        />
        <Paper
          elevation={1}
          style={{
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'row',
            padding: '1rem'
          }}
        >
          {logoUrl && (
            <img
              alt=""
              style={{
                width: undefined,
                height: 70,
                marginRight: '1rem'
              }}
              src={logoUrl}
            />
          )}
          <SelectImageButton
            containerStyle={{ width: '100%' }}
            title="Select/Update Game Logo"
            helperText="Size Up to 3MB and only jpeg, jpg and png is allowed"
            multiple={false}
            onImageSelected={onImageSelected}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <>
          <LoadingButton
            title="Add"
            loading={addGameLoading}
            onClick={onAddBtnPress}
            color="primary"
          />
          <Button onClick={onCancelBtnPress}>Cancel</Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default AddGameDialog;
