import React, { FC, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { FixMeLater } from 'src/types';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Game } from 'src/redux/slices/games/types';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  swiperButton: {
    minWidth: 'unset', // bypass strict mui button minWidth for customization
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    border: '1px solid grey',
    '&::after': { fontSize: '20px' }
  }
}));

interface Props {
  gamesSelected: Game[];
  removeGame: (name: string) => void;
}

const GameSwiper: FC<Props> = ({ gamesSelected, removeGame }) => {
  const classes = useStyles();
  const swiperRef = useRef<FixMeLater>(null);

  return (
    <Grid container style={{ height: '100%', padding: '0px 1em' }}>
      <Grid item xs={1} className={classes.buttonContainer}>
        <Button
          className={classes.swiperButton}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <ArrowBackIcon style={{ color: 'grey' }} />
        </Button>
      </Grid>
      <Grid item xs={10}>
        <Swiper
          spaceBetween={10}
          slidesPerView={5}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          style={{
            height: '100%',
            padding: '0px 1em'
          }}
        >
          {gamesSelected[0] &&
            gamesSelected.map((i, ind) => (
              <SwiperSlide key={ind}>
                <Grid
                  container
                  style={{
                    height: '100%'
                  }}
                >
                  <Grid
                    id="game-logo-skeleton"
                    item
                    xs={12}
                    style={{
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'end',
                      height: '70%'
                    }}
                  >
                    <img
                      src={i.icon}
                      alt={i.name}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    <Button
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        minWidth: 'unset',
                        borderRadius: '50%',
                        border: '1px solid grey',
                        background: 'white',
                        width: '1.5em',
                        height: '1.5em'
                      }}
                      onClick={() => removeGame(i.name || '')}
                    >
                      <ClearIcon
                        color="secondary"
                        style={{ fontSize: '1em' }}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography>{i.name}</Typography>
                    <Typography>{`${i.score || '0'}/100`}</Typography>
                  </Grid>
                </Grid>
              </SwiperSlide>
            ))}
        </Swiper>
      </Grid>
      <Grid className={classes.buttonContainer} item xs={1}>
        <Button
          className={classes.swiperButton}
          onClick={() => swiperRef.current?.slideNext()}
        >
          <ArrowForwardIcon style={{ color: 'grey' }} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default GameSwiper;
