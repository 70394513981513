import {
  Card,
  CardContent,
  Typography,
  Checkbox,
  TextField,
  Box
} from '@material-ui/core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import AddIconAdornment from './AddIconAdornment';
import HighlightSwiper from './HighlightSwiper';
import { BundleDetailsV2 } from 'src/redux/slices/pc-bundles/types';
import { Highlight } from 'src/redux/slices/pc-bundles/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';

interface Props {
  bundle?: BundleDetailsV2;
}

const {
  selectors: pcBundleSelectors,
  actions: pcBundleActions
} = slices.pcBundles;

const Highlights: FC<Props> = ({ bundle }) => {
  const dispatch = useAppDispatch();

  const highlights = useAppSelector(pcBundleSelectors.selectHighlights);

  const [highlightVids, setHighlightVids] = useState<Highlight[]>([]);
  const [ytUrl, setYtUrl] = useState<string>('');
  const [errHighlight, setErrYt] = useState<string>('');
  const [isShort, setIsShort] = useState<boolean>(true);

  const removeHighlight = useCallback(
    (url: string) => {
      const filtered = highlightVids.filter((i: Highlight) => i.video !== url);
      setHighlightVids(filtered);
      dispatch(pcBundleActions.setHighlight(filtered));
    },
    [dispatch, highlightVids]
  );

  const linkConverter = useCallback((val: Highlight | string) => {
    if (typeof val === 'string') {
      const highlight = {
        video: val,
        isShort: true
      };
      if (!/shorts/.test(val)) {
        highlight.isShort = false;
      }
      return highlight;
    }
    return val;
  }, []);

  const uploadURL = useCallback(() => {
    if (highlightVids.length >= 10) {
      setErrYt('Cant Upload more than 10 videos');
    }

    const youtubeRegex = /^(https?:\/\/)?(www\.youtube\.com|youtu\.be)\/(watch\?v=|shorts\/)?\S*$/;
    let trimStr = '';
    let err = '';

    trimStr = ytUrl.trim();
    // Link checking
    if (youtubeRegex.test(trimStr)) {
      if (highlightVids.find((i) => i.video === trimStr)) {
        err = 'Link already on list';
      } else if (isShort && !trimStr.includes('shorts')) {
        err = 'Invalid link: Expected a YouTube Shorts link';
      } else if (!isShort && trimStr.includes('shorts')) {
        err = 'Invalid link: YouTube Shorts link not allowed';
      } else {
        err = '';
      }
    } else {
      err = 'Invalid YouTube link';
    }

    if (err) {
      setErrYt(err);
      return;
    }

    const updatedHighlights = [...highlightVids, { video: trimStr, isShort }];
    setHighlightVids(updatedHighlights);
    dispatch(pcBundleActions.setHighlight(updatedHighlights));
    setYtUrl('');
    setErrYt('');
    return;
  }, [highlightVids, ytUrl, isShort, dispatch]);

  useEffect(() => {
    const highlights = bundle?.highlights?.map((i) => linkConverter(i));

    dispatch(pcBundleActions.setHighlight(highlights || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundle]);

  useEffect(() => {
    setHighlightVids(highlights || []);
  }, [highlights]);

  return (
    <Card style={{ marginTop: '1em' }}>
      <CardContent>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography>Enter YT Url for Highlights</Typography>
          <Checkbox
            checked={isShort}
            onClick={() => setIsShort(!isShort)}
            color="primary"
          />
          <Typography>Youtube Short</Typography>
        </Box>
        <TextField
          fullWidth
          placeholder="Enter Youtube Link"
          error={!!errHighlight}
          helperText={errHighlight}
          variant="outlined"
          value={ytUrl}
          onChange={(e) => setYtUrl(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && uploadURL()}
          InputProps={{
            endAdornment: <AddIconAdornment onClick={uploadURL} />
          }}
        />
        {highlightVids[0] && (
          <HighlightSwiper
            highlights={highlightVids}
            removeHighlight={removeHighlight}
          />
        )}
      </CardContent>
    </Card>
  );
};
export default Highlights;
