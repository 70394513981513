import {
  FixMeLater,
  ManufacturerOption,
  SaleAmount,
  SaleItem,
  SalePayment
} from 'src/types';
import { v4 as uuidv4 } from 'uuid';

const formatStringCurrency = (value: any) => {
  if (value !== undefined && value !== null) {
    return `₱${parseFloat(value).toFixed(2)}`;
  }
  return '₱0.00';
};

export const parse = (data: FixMeLater) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    console.log(`Oops! Some problems parsing this ${typeof data}.`);
  }

  return null;
};

export const cleanSN = (sn: string) => {
  return sn.trim().toUpperCase();
};

export const isValidCurrency = (input: any, allowZero: boolean = false) => {
  if (!isNaN(input)) {
    if (allowZero && +input === 0) {
      return true;
    }
    if (+input > 0) {
      return true;
    }
  }
  return false;
};

export const isEmptyOrSpaces = (str: string | undefined) => {
  return str === undefined || str === null || str?.match(/^ *$/) !== null;
};

export const manufacturerIDToName = (
  id: number | undefined,
  options: ManufacturerOption[] | undefined
) => {
  if (!id || !options) {
    return null;
  }
  return options.find((i) => i.id === id)?.label;
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const isValidHttpUrl = (str: string) => {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const initializedWordsOtherThanFirst = (str: string) => {
  let first = '';
  let initials = '';
  const words = str?.split(/\s/);

  if (words && words?.length > 0) {
    first = words[0];
    const initialsArr = words?.map((x) => x?.substring(0, 1)?.toUpperCase());
    initialsArr?.shift();
    initials = initialsArr?.join('');
  }

  return `${first} ${initials}`;
};

export const normalizeFieldText = (txt: string) => {
  if (typeof txt !== 'string') {
    return '';
  }

  return txt.replace(/\//g, '');
};

export const salesDataToString = (arr?: any[]) =>
  arr?.reduce((string, itemsObj) => {
    const itemDetails = Object.keys(itemsObj).map(
      (key) => `${key}: ${itemsObj[key]}`
    );
    return string.concat(`${itemDetails.join(', ')}\r\n`);
  }, '');

export const salesAmountsToString = (amount?: SaleAmount) => {
  // Create the formatted string
  const amountDetails = `
  Total Retail Price: ${formatStringCurrency(amount?.total_retail_price)}
  TDP: ${formatStringCurrency(amount?.total_dealer_price)}
  Amount Paid: ${formatStringCurrency(amount?.amount_paid)}
  Balance: ${formatStringCurrency(amount?.balance)}
  Gross: ${formatStringCurrency(amount?.gross)}
  `;

  return amountDetails;
};

export const salesPaymentToString = (arr?: SalePayment[]) =>
  arr?.reduce((string, itemsObj) => {
    // Extract necessary details
    const { amount, date_posted, payment_type } = itemsObj;

    // Create the formatted string
    const itemDetails = `${formatStringCurrency(
      amount
    )}(${payment_type})\n${date_posted}\n___________________________________`;

    return string.concat(`${itemDetails}\r\n`);
  }, '');

export const salesItemToString = (arr?: SaleItem[]) =>
  arr?.reduce((string, itemsObj) => {
    // Extract necessary details
    const {
      product_name,
      serial_no,
      quantity,
      dealers_price,
      retail_price
    } = itemsObj;

    // Create the formatted string
    const itemDetails = `${product_name}\n${serial_no}\nQTY: ${quantity} | DP: ${formatStringCurrency(
      dealers_price
    )} | RP: ${formatStringCurrency(
      retail_price
    )}\n___________________________________`;
    return string.concat(`${itemDetails}\r\n`);
  }, '');

//09 format
export const phPhoneNumberChecker = (phone?: string) => {
  if (phone) {
    if (phone.length === 11) {
      // Require "09" followed by 9 digits
      return /^09\d{9}$/.test(phone);
    } else if (phone.length === 8) {
      // Allow 8 digits for telephone
      return /^\d{8}$/.test(phone);
    }
  }
  return false;
};

export const containsAlphabetsWithSpacesAndDashes = (txt: string): boolean => {
  if (typeof txt !== 'string') {
    return false;
  }

  return /[A-Za-z]/.test(txt) && /^[A-Za-z\s-]+$/.test(txt);
};

export const secureText = (input: string) => {
  return input
    .split(' ')
    .map((word) => {
      if (word.length <= 1) return word; // For single letter words, no transformation needed
      // For the last word in the name
      if (word === input.split(' ').slice(-1)[0]) {
        return `${word[0]}${'*'.repeat(word.length - 2)}${word.slice(-1)}`;
      }
      // For other words in the name
      return `${word[0]}${'*'.repeat(word.length - 1)}`;
    })
    .join(' ');
};

export const allowStringOnlyInput = (value: string): boolean => {
  // Regex: allows letters and spaces but no leading/trailing spaces
  const regex = /^[A-Za-z]+(\s[A-Za-z]*)*$/;
  return regex.test(value) || value === '';
};
export const getRandomUUIDv4Short = () => uuidv4()?.substring(0, 8);
