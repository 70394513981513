import { cloneDeep } from 'lodash';
import { TransactionPrintPageData } from 'src/types';

// cleans transaction data that is about to be printed
export const transactionDataCleaner = (data: TransactionPrintPageData[]) => {
  const clonedData = cloneDeep(data);

  // checks index above current stack, If product_name exist.
  // set amount, product_name and quantity to undefined.
  clonedData?.map((x, i) => {
    if (i > 0) {
      const previousIndex = i - 1;
      const previousDataItems = clonedData[previousIndex]?.page?.items || [];
      const previousDataItemsLastIndex = previousDataItems?.length - 1;
      x.page.items || [];
      // @ts-ignore: Object is possibly 'null'. Intended.
      let firstCurrentItem = x.page.items[0];
      if (
        firstCurrentItem?.product_name ===
        previousDataItems[previousDataItemsLastIndex]?.product_name
      ) {
        firstCurrentItem.amount = undefined;
        firstCurrentItem.product_name = undefined;
        firstCurrentItem.quantity = undefined;
      }
      return {
        ...x,
        page: {
          ...x.page,
          items: x.page.items
        }
      };
    }
    return x;
  });

  return clonedData;
};
