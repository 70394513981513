import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import React, { FC } from 'react';
import { Banner } from 'src/types/ecomm/banner';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

interface Props {
  item: Banner;
  onClick: (i: Banner) => void;
  onDelete: () => void;
}

export const BannerItem: FC<Props> = ({ item, onClick, onDelete }) => {
  return (
    <Card style={{ width: '100%' }}>
      <CardContent>
        <Grid container style={{ columnGap: '1em' }}>
          <Grid
            item
            xs={1}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <DragIndicatorIcon color="primary" style={{ fontSize: '2.7em' }} />
          </Grid>
          <Grid item xs>
            <img
              src={item.img_url}
              height={106}
              width={360}
              style={{ objectFit: 'cover' }}
            />
          </Grid>
          <Grid
            item
            xs
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1em'
            }}
          >
            <TextField
              value={item?.description || ''}
              size="small"
              variant="outlined"
              fullWidth
              label="Label"
            />
            <TextField
              value={item?.link || ''}
              size="small"
              variant="outlined"
              fullWidth
              label="href/link"
            />
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {item.published ? (
              <Typography style={{ color: '#219653' }}>Published</Typography>
            ) : (
              <Typography color="error">Unpublished</Typography>
            )}
            <Box style={{ display: 'flex', columnGap: '1em' }}>
              <CreateOutlinedIcon
                style={{ cursor: 'pointer' }}
                onClick={() => onClick(item)}
              />
              <DeleteIcon
                onClick={onDelete}
                style={{ cursor: 'pointer' }}
                color="secondary"
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
