import { Button, ButtonGroup, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { FC, memo, useEffect, useState } from 'react';

interface Props {
  title?: string;
  options?: any[];
  initial_val?: string;
  disableButton?: boolean;
  onChange?: (option: string) => void;
  onApplyClick?: (option: string) => void;
}

const SplitButton: FC<Props> = ({
  title = 'Split Button',
  options = [],
  initial_val,
  disableButton = false,
  onApplyClick,
  onChange
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleClick = () => {
    const selectedOption = options[selectedIndex];
    if (onApplyClick) {
      onApplyClick(selectedOption);
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    if (onChange) {
      onChange(options[index]);
    }
  };

  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (initial_val) {
      const index = options?.findIndex((prev) => prev === initial_val);
      setSelectedIndex(index || 0);
    }
  }, [initial_val, options, onChange]);

  return (
    <>
      <ButtonGroup variant="contained" color="primary">
        <Button
          disabled={disableButton}
          onClick={handleClick}
        >{`${title}: ${options[selectedIndex]}`}</Button>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          aria-controls="split-button-menu"
          aria-expanded={anchorEl ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggleMenu}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        id="split-button-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const SplitButtons = memo(SplitButton);
